import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router';

import adminsRepository from 'repositories/Admins';
import { useAdminAuth } from 'contexts/adminAuth';

import { emailRegex } from 'utils/regex';
import api from 'services/api';

import logo from 'assets/img/work/worki_logo.png';

import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';

export const AdminLogin = () => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isSubmitDisabled = !email || !password || isSubmitting;

  const [eye, setEye] = useState('fa fa-eye-slash');

  const [showPassword, setShowPassword] = useState(false);

  const { setAdminUser } = useAdminAuth();
  const { setDocumentTitle } = useThemeContext();

  const handleShowPassword = (e) => {
    e.preventDefault();

    if (eye === 'fa fa-eye') {
      setEye('fa fa-eye-slash');
      setShowPassword(false);
    } else {
      setEye('fa fa-eye');
      setShowPassword(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) return;

    if (!isEmail(email)) {
      return toastr.warning(
        'E-mail Inválido',
        'O e-mail informado é inválido. Insira um e-mail válido e tente novamente'
      );
    }

    setIsSubmitting(true);
    try {
      const { user, token } = await adminsRepository.authenticate(
        email,
        password
      );

      setAdminUser(user);
      localStorage.setItem('jwt', token);
      api.defaults.headers.common['x-access-token'] = token;

      history.push('/admin/home');
    } catch (err) {
      const status = err.response.status;
      console.log(err);

      switch (status) {
        case 401:
          toastr.error(
            'E-mail e/ou senha inválidos',
            'Verifique os dados informados e tente novamente. Em caso de dúvidas, informe o problema ao administrador do sistema de sua empresa.'
          );
          break;
        default:
          toastr.error(
            'Falha ao logar no sistema',
            'Houve um problema ao tentar logar no sistema e já estamos verificando o que ocorreu. Tente novamente mais tarde.'
          );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeEmail = (value) => {
    const parsedEmail = value.trim().replace(emailRegex, '');
    setEmail(parsedEmail);
  };

  const handleChangePassword = (value) => {
    const parsedPassword = value.trim();
    setPassword(parsedPassword);
  };

  useEffect(() => {
    setDocumentTitle('Login | Painel Administrador', false);
  }, [setDocumentTitle]);

  return (
    <div className="admin-login__container">
      <div className="admin-login__card ">
        <img src={logo} />

        <form className="admin-login__form" method="POST">
          <div>
            <label htmlFor="">E-mail:</label>
            <input
              id="email"
              type="text"
              maxLength="50"
              placeholder="seu@email.com"
              className="form-control foco-input"
              style={{ margin: 0 }}
              value={email}
              name="email"
              onChange={(e) => handleChangeEmail(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="password">Senha:</label>
            <input
              id="password"
              placeholder="Informe a sua senha"
              type={showPassword ? 'text' : 'password'}
              maxLength="18"
              className="form-control foco-input passwordMargin"
              value={password}
              onChange={(e) => handleChangePassword(e.target.value)}
              onKeyDown={(e) => (e.key === 'Enter' ? handleSubmit(e) : null)}
            />
            <button
              type="button"
              title={`${showPassword ? 'Ocultar' : 'Exibir'} Senha`}
              className={`fa fa-fw fa-eye ${eye} admin-login__password-toggle`}
              onClick={handleShowPassword}
            />
          </div>

          <button
            className="button button-green"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            type="submit"
          >
            {isSubmitting && (
              <>
                <span className="fa fa-spinner fa-pulse"></span> &nbsp;
              </>
            )}
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import {
  ContainerCampaignsScreen,
  CardWrapper,
  CampaignsRow,
  CampaignFieldset,
} from './Campaigns.styles';
import ReactTable from 'react-table';
import { CRMCampaignRepository } from 'v2/repositories/CRMCampaignRepository';
import { addDays, format } from 'date-fns';
import Button from 'client/components/CustomButton/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import useDebounce from 'hooks/useDebounce';
import SearchInput from 'components/SearchInput';
import { CampaignForm } from '../CampaignForm';
import useCRMStore from '../../store/crm-store';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

export function Campaigns() {
  const { companyId } = useAuth();
  const { isLoading, fetchLoading } = useLoading();
  const [campaignData, setCampaignData] = useState([]);

  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [query, setQuery] = useState('');
  const [type, setType] = useState('');

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [campaignId, setCampaignId] = useState(null);

  const { openNewCampaignForm, setOpenNewCampaignForm } = useCRMStore();

  const handleReload = () => {
    setOpenNewCampaignForm(false);
    handleLoadData();
  };

  function getBackgroundColor(value) {
    const colors = {
      Aniversário: '#428BCA',
      'Retorno/Revisão': '#5CB85C',
      Personalizado: '#F0AD4E',
      0: '#D9534F',
      1: '#5CB85C',
    };

    return colors[value];
  }

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const handleLoadData = async () => {
    await fetchLoading(loadCampaignData);
  };

  async function loadCampaignData() {
    try {
      const response = await CRMCampaignRepository.getAllCampaigns({
        page: currentPage + 1,
        limit: pageLimit,
        query: queryDebounced,
        type,
        companyId,
        initialDate,
        finalDate,
      });

      setCampaignData(response.data);
      setTotalPages(Math.ceil(response.count[0].numberRows / pageLimit));
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as campanhas. Por favor, tente novamente.'
      );
    }
  }

  const validations = () => {
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const handleOpenCampaignReview = (id) => {
    setCampaignId(id);
    setOpenNewCampaignForm(true);
  };

  useEffect(() => {
    if (!!validations()) {
      handleLoadData();
    }
  }, [currentPage, queryDebounced, pageLimit, type, initialDate, finalDate]);

  return (
    <ContainerCampaignsScreen>
      <CardWrapper>
        {!openNewCampaignForm && (
          <>
            <CampaignsRow>
              <div>
                <button
                  className="btn btn-sucesso button-h35 fontSize-12"
                  onClick={() => setOpenNewCampaignForm(true)}
                >
                  + Nova Campanha
                </button>
              </div>
              <SearchInput
                containerStyle={{ width: 370 }}
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                style={{ fontSize: '12px' }}
              />
              <CampaignFieldset>
                <span className="typography__text-2">Data Inicial:</span>
                <input
                  className="form-control foco-input fontSize-12"
                  type="date"
                  name="dataInicial"
                  value={initialDate}
                  max={'9999-12-31'}
                  onChange={(e) => {
                    setInitialDate(e.target.value);
                  }}
                />
              </CampaignFieldset>
              <CampaignFieldset>
                <span className="typography__text-2">Data Final:</span>
                <input
                  className="form-control foco-input fontSize-12"
                  type="date"
                  name="dataFinal"
                  value={finalDate}
                  min={
                    initialDate && format(new Date(initialDate), 'yyyy-MM-dd')
                  }
                  max={'9999-12-31'}
                  onChange={(e) => {
                    setFinalDate(e.target.value);
                  }}
                />
              </CampaignFieldset>
              <CampaignFieldset>
                <span className="typography__text-2">Tipo:</span>
                <select
                  className="form-control foco-input fontSize-12"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="">Todos</option>
                  <option value="4">Aniversário</option>
                  <option value="5">Retorno/Revisão</option>
                  <option value="6">Personalizado</option>
                </select>
              </CampaignFieldset>
            </CampaignsRow>
            <ReactTable
              data={campaignData}
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '14px',
              }}
              columns={[
                {
                  Header: 'Dt. Criação',
                  accessor: 'createdAt',
                  width: 150,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 150,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        height: '30px',
                        width: '100px',
                        backgroundColor: getBackgroundColor(props.value),
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                },
                {
                  Header: 'Imagem',
                  accessor: 'havePhoto',
                  width: 150,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        height: '30px',
                        width: '100px',
                        backgroundColor: getBackgroundColor(props.value),
                      }}
                    >
                      {props.value === 1 ? 'Sim' : 'Não'}
                    </Button>
                  ),
                },
                {
                  Header: 'Qtd. Clientes',
                  accessor: 'customerQuantity',
                  width: 150,
                },
                {
                  Header: '',
                  accessor: 'id',
                  width: 50,
                  Cell: (props) => (
                    <>
                      <FontAwesomeIcon
                        title={`Acessar a Campanha`}
                        cursor="pointer"
                        icon={faEye}
                        onClick={() => handleOpenCampaignReview(props.value)}
                      />
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              pageSize={pageLimit}
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              manual
              loading={isLoading}
              previousText="Anterior"
              nextText="Próximo"
              noDataText="Nenhum dado encontrado"
              loadingText="Carregando..."
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </>
        )}
        {openNewCampaignForm && (
          <CampaignForm
            closeForm={() => handleReload()}
            campaignId={campaignId}
          />
        )}
      </CardWrapper>
    </ContainerCampaignsScreen>
  );
}

import { useEffect, useRef, useState } from 'react';
import config from 'config';

const useWebSocket = () => {
  const webSocketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!webSocketRef.current) {
      const wsApi = new WebSocket(config.webSocketEndpoint);

      wsApi.onopen = () => {
        setIsConnected(true);
      };

      wsApi.onmessage = (event) => {
        const response = event.data;
        console.log('Received message:', response);
        // Handle incoming messages here
      };

      wsApi.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsApi.onclose = () => {
        setIsConnected(false);
      };

      webSocketRef.current = wsApi;
    }

    // Clean up WebSocket connection on component unmount
    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, []);

  return { webSocket: webSocketRef.current, isConnected };
};

export default useWebSocket;

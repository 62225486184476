import styled from 'styled-components';

export const CampaignWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FlexEndWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.red400};
  font-size: 12px;
`;

export const InlineWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: row;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 5px;
  padding: 0 5px;
  align-items: center;
`;

export const RichTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray180};
  border-radius: 4px;
`;

export const Button = styled.button`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, active }) =>
    active ? theme.colors.gray500 : theme.colors.gray300};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const TotalCharacters = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blue300};
`;

export const ButtonLink = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.blue300};
  text-decoration: underline;
  font-size: 12px;
`;

import React from 'react';
import { InputTextStyled, InputTextWrapper } from './styles';

export const InputText = React.forwardRef(
  ({ width, height = 40, isLoading = false, isDisabled, ...props }, ref) => {
    return (
      <InputTextWrapper width={width} height={height}>
        <InputTextStyled
          {...props}
          ref={ref}
          isLoading={isLoading}
          disabled={isLoading || isDisabled}
        />
      </InputTextWrapper>
    );
  }
);

InputText.displayName = 'InputText';

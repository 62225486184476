import React, { useEffect, useState, useCallback } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import costCenterRepository from '../../../repositories/CostCenter';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';

import Button from '../../components/CustomButton/Button.jsx';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { format } from 'date-fns';
import { encrypt } from '../../components/ToNormalize/ToNormalize';

const HomeCostCenter = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [costCenters, setCostCenters] = useState([]);

  const { statusFilter, queryFilter } = useFilters();

  const { status, setStatus, filterByStatus } = statusFilter;
  const { query, setQuery, filterByQuery } = queryFilter;

  const history = useHistory();

  useEffect(() => {
    if (companyId) {
      loadCostCenters();
    }
  }, []);

  const loadCostCenters = async () => {
    setLoading(true);
    try {
      const costCenters = await costCenterRepository.getAllByCompany({
        companyId,
      });
      setCostCenters(costCenters);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os Centros de Custos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (costCenters) => {
    const querySearch = [costCenters['description']];
    return (
      filterByQuery(querySearch) && filterByStatus('isActive', costCenters)
    );
  };

  const handleEdit = useCallback((id) => {
    const costCenterIdHash = encrypt(id, '@OS-dig:costCenterId');
    history.push(
      `/client/${constants.ROUTES.EDIT_COST_CENTER}?id=${costCenterIdHash}`
    );
  }, []);

  const filteredCostCenters = costCenters.filter(handleFilters);

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() => history.push(constants.ROUTES.COST_CENTER)}
              >
                + Novo Centro de Custo
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-5 col-lg-5"
              id="review-search"
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px' }}
                className="form-control foco-input"
                placeholder="Pesquisar por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Ambos</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <DownloadXlsButton
                archiveName={`Centro de Custo - ${format(
                  new Date(),
                  'dd/MM/yyyy'
                )}`}
                data={filteredCostCenters}
                disabled={!!!filteredCostCenters.length}
                className="btn btn-export"
                columns={[
                  {
                    name: 'Descrição',
                    acessor: 'description',
                  },
                  {
                    name: 'Status',
                    acessor: 'isActiveDescription',
                  },
                ]}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '700px',
              }}
              data={costCenters.filter(handleFilters)}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                  width: 500,
                  Cell: (props) => (
                    <a>
                      {props.value.length >= 100
                        ? props.value.substring(0, 100) + '...'
                        : props.value.substring(0, 100)}
                    </a>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value == 1 ? 'green' : 'red',
                      }}
                    >
                      {props.value == 1 ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 100,
                  filterable: false,
                  Cell: (props) => (
                    <FontAwesomeIcon
                      title="Editar"
                      cursor="pointer"
                      style={{
                        height: '1.5em',
                        width: '1.5em',
                        color: 'black',
                      }}
                      icon={faEdit}
                      onClick={() => handleEdit(props.value)}
                    />
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'text',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default HomeCostCenter;

import React from 'react';
import { HeaderContainer, Title, HeaderWrapper } from './Header.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { OptionSelector } from '../OptionSelector';

export function Header() {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Title>
          <FontAwesomeIcon
            icon={faCommentsDollar}
            style={{ color: '#1D7391', height: '20px', marginRight: '5px' }}
          />
          CRM
        </Title>
      </HeaderContainer>
      <OptionSelector />
    </HeaderWrapper>
  );
}

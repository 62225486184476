import Modal from 'react-bootstrap/lib/Modal';
import styled from 'styled-components';

export const SaleFilesAddWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const InlineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const Text = styled.span`
  color: ${(props) => props.theme.colors[props.color || 'gray500']};
  font-size: ${(props) => props.fontSize || '12px'};
  display: flex;
  width: 100%;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const AdaptiveModal = styled(Modal)`
  .modal-adaptive-height {
    @media (max-height: 768px) {
      height: 90vh;
      display: flex;
      flex-direction: column;
      .modal-body {
        flex: 1;
        overflow: hidden;
      }
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import ListRefusedItemsReportRepository from 'v2/repositories/ItemsRefusedRepository';
import ListRefusedItemsDocument from '../components/ListRefusedItemsDocument';

const ListRefusedItemsReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId');
      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const typeOfDate = searchParams.get('typeOfDate');
      const saleStatus = searchParams.get('saleStatus');
      const searchBy = searchParams.get('searchBy');

      return loadReport({
        companyId,
        initialDate,
        finalDate,
        typeOfDate,
        saleStatus,
        searchBy,
      });
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (params) => {
    setLoading(true);
    try {
      const data = await ListRefusedItemsReportRepository.getItemsRefusedList(
        params
      );

      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <ListRefusedItemsDocument responseData={data} />
        </PDFViewer>
      </main>
    </div>
  );
};

export default ListRefusedItemsReport;

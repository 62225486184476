import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useState } from 'react';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const CostCenterDocument = ({ responseData }) => {
  const [headerData] = useState(responseData.header);
  const [totalsData] = useState(responseData?.totals);
  const [reportData] = useState(responseData.report);

  return (
    <Document
      title={`listagem-de-centro-de-custo-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="listagem-de-centro-de-custo"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
            justifyContent: 'flex-start',
          }}
        >
          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            <Text style={styles.title}>Centro de Custo</Text>
          </View>
          <Header headerData={headerData} />
        </View>

        {totalsData ? (
          <>
            <View style={styles.line} />
            <Totalizators
              totalsData={totalsData}
              totalQuantityBills={responseData.report.length}
            />
          </>
        ) : (
          <View />
        )}

        {responseData.header.showAccountPlan === 'Exibir' ? (
          <>
            <View style={styles.line} />
            <ReportListing reportData={reportData} />
          </>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

function FormatPercentage(number) {
  return (number * 100).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
}

const Header = ({ headerData }) => {
  return (
    <>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <View
          style={{
            width: '43%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Tipo de Data: </Text>
            <Text>{headerData.typeOfDate}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Data Início: </Text>
            <Text>
              {format(
                getDateOnlyFromDate(new Date(headerData.initialDate)),
                'dd/MM/yyyy'
              )}
            </Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Data Fim: </Text>
            <Text>
              {format(
                getDateOnlyFromDate(new Date(headerData.finalDate)),
                'dd/MM/yyyy'
              )}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '30.7%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Exibir Plano de Contas: </Text>
            <Text>{headerData.showAccountPlan}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const TextComponent = ({ quantityCostCenters, quantityBills }) => {
  const text =
    quantityCostCenters === 1
      ? `${quantityCostCenters} centro de custo foi apontado em`
      : `${quantityCostCenters} centros de custos foram apontados em`;

  const billsText =
    quantityBills === 1
      ? `${quantityBills} título`
      : `${quantityBills} títulos`;

  return (
    <Text style={styles.textContainer}>
      {text} {billsText} do contas a pagar
    </Text>
  );
};

const Totalizators = ({ totalsData, totalQuantityBills }) => {
  return (
    <>
      <TotalizatorsHeader title={totalsData.title} />
      <TotalizatorsItems items={totalsData.items} />
      <TotalizatorsFinal
        title={totalsData.totalTitle}
        value={totalsData.totalValue}
        quantityCostCenters={totalsData.totalQuantityCostCenters}
        quantityBills={totalQuantityBills}
      />
    </>
  );
};
const TotalizatorsHeader = () => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Totalizadores
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '1px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Text style={[styles.boldText, { width: '15%', textAlign: 'left' }]}>
          Centro de Custo
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          Total
        </Text>
      </View>
      <View style={styles.line} />
    </>
  );
};
const TotalizatorsItems = ({ items }) => {
  return (
    items &&
    items.map((item, key) => (
      <>
        <View
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'left',
            padding: '1px',
          }}
        >
          <View style={{ width: '15%', textAlign: 'left' }}>
            <Text style={styles.text}>{item.description}</Text>
          </View>
          <View style={{ width: '10%', textAlign: 'left' }}>
            <Text style={styles.text}>{currency(item.total)}</Text>
          </View>
        </View>
      </>
    ))
  );
};
const TotalizatorsFinal = ({
  title,
  value,
  quantityCostCenters,
  quantityBills,
}) => {
  return (
    <>
      <View style={[styles.line]} />
      <View
        style={{
          width: '100%',
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={[styles.boldText, { width: '15%', textAlign: 'left' }]}>
          {title}
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          {currency(value)}
        </Text>
      </View>
      <View
        style={{
          width: '100%',
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          margin: '10px 0px',
        }}
      >
        <TextComponent
          quantityCostCenters={quantityCostCenters}
          quantityBills={quantityBills}
        />
      </View>
    </>
  );
};

const ReportListing = ({ reportData }) => {
  return (
    <>
      <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
        Detalhes e Contas
      </Text>
      <View style={[styles.line, { width: '100%' }]} />
      {reportData &&
        reportData.map((costCenter, key) => (
          <>
            <View style={{ margin: '10px 0' }}>
              <ListingHeader costCenter={costCenter} key={key} />
              <View style={{ marginTop: '5px' }} />
              <ListingItems items={costCenter.items} />
              <View style={[styles.line, { width: '55%' }]} />
              <View
                style={{
                  flexDirection: 'row',
                  width: '55%',
                  justifyContent: 'flex-end',
                  paddingRight: '125px',
                }}
              >
                <View>
                  <Text style={styles.strong}>
                    {currency(costCenter.total)}
                  </Text>
                </View>
              </View>
              <View style={[styles.line, { width: '55%' }]} />
            </View>
          </>
        ))}
    </>
  );
};

const ListingHeader = ({ costCenter, key }) => {
  return (
    <>
      <View style={{ width: '100%', flexDirection: 'column' }} key={key}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Centro de Custo: </Text>
            </View>
            <View>
              <Text>{costCenter.description}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
const ListingItems = ({ items, key }) => {
  return (
    <>
      <ListingItemsTitle />
      {items &&
        items.map((item) => <ListingItemsContent item={item} key={key} />)}
    </>
  );
};
const ListingItemsTitle = () => {
  return (
    <>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '20%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>
            Plano de Conta
          </Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Categoria</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Saldo</Text>
        </View>
      </View>
      <View style={[styles.line, { width: '100%' }]} />
    </>
  );
};
const ListingItemsContent = ({ item, key }) => {
  return (
    <>
      <View
        key={key}
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '20%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.accountPlanDescription}
          </Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.accountPlanCategory}
          </Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.accountPlanBalance)}
          </Text>
        </View>
      </View>
    </>
  );
};

export default CostCenterDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  boldTextOfType: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});

import { toastr } from 'react-redux-toastr';

import salesRepository from '../../../../../../repositories/Sales';
import salesRepositoryV2 from 'v2/repositories/SaleRepository';
import customersRepository from '../../../../../../repositories/Customers';
import conditionsOfPaymentRepository from '../../../../../../repositories/PaymentConditions';
import formsOfPaymentRepository from '../../../../../../repositories/PaymentForms';
import cashierBankRepository from '../../../../../../repositories/CashierBank';
import companyConfigRepository from '../../../../../../repositories/CompanyConfig';
import s3Repository from '../../../../../../repositories/S3';
import constants from '../../../../../../utils/constants';
import { getProductsUnderStock } from 'client/components/MinStockAlertButton/redux/actions';
import emailRepository from 'repositories/Emails';
import bankSlipRepository from '../../../../../../repositories/BankSlip';
import MaintenanceRepository from 'repositories/Maintenance';

export const init =
  (saleId, companyId, userId, company) => async (dispatch) => {
    await dispatch({
      type: 'INIT',
      payload: {
        companyId,
        userId,
        defaultCashierBankId: company.hasCashierControl
          ? company.defaultCashierBankId
          : null,
      },
    });

    if (!!saleId) {
      dispatch(loadSale(saleId));
    }

    dispatch([
      loadPaymentData(),
      // loadAllClients(),
      loadCompanyConfigurations(companyId),
    ]);
  };

const loadCompanyConfigurations = async (companyId) => {
  const config = await companyConfigRepository.show(companyId);

  if (config) {
    return { type: 'LOAD_COMPANY_CONFIGURATIONS_SUCCESS', payload: config };
  } else {
    return { type: 'LOAD_COMPANY_CONFIGURATIONS_FAILURE' };
  }
};

const loadSale = (saleId) => async (dispatch) => {
  dispatch({ type: 'LOAD_SALE_STARTED' });
  try {
    const sale = await salesRepository.getById(saleId);
    dispatch(loadSaleSuccess(sale));
  } catch (err) {
    toastr.warning(
      'Ocorreu um erro ao buscar a venda. Por favor, tente novamente'
    );
    dispatch({ type: 'LOAD_SALE_FAILURE' });
  }
};

const loadSaleSuccess = (sale) => async (dispatch) => {
  const {
    id,
    Kilometers,
    Comments,
    Defects,
    Malfunctions,
    Customer,
    SalesStatus_id,
    SalesType_id,
    salesSubtypeId,
    SalesSubtype,
    Code,
    Discount_Value_Percentage,
    Discount_Value,
    Discount_Type,
    SubTotal,
    Final_Value,
    Payments,
    Vehicle_id,
    previousKilometers,
    SalesItems,
    SalesItemsCanceled,

    Company,
    employeeId,
    userIdWhoClosedSale,
    returnedValue,
    showOnPanel,
    forecastReturn,
    prismaBoxId,
    sellerId,
    tradeRepresentativeId,
    attendanceTypeId,
    freightValue,
    SalesEmployees,
    BoletoFlexTransaction,
    KoinTransaction,
    redeemedBonus,
    usedCRMBonus,
    bonusId,
    orderId,
    sat_id,
    nfe_id,
    nfce_id,
    nfse_id,
    customerRequest,
  } = sale;

  const hash = `Vend=v723dha5tHSSpS83711${id}5422hsGsdAQLk8423oPL31`;
  const link = `${window.location.origin}/share-sheets/sale/${hash}`;

  const serializedSale = {
    saleId: id,
    BoletoFlexTransaction,
    KoinTransaction,
    currentKilometers: Kilometers || 0,
    previousKilometers: previousKilometers || 0,
    customerRequest,
    prismaBoxId,
    sellerId,
    redeemedBonus,
    usedCRMBonus,
    bonusId,
    orderId,
    tradeRepresentativeId,
    attendanceTypeId,
    observations: Comments,
    defects: Defects,
    malfunctions: Malfunctions,
    selectedClient: Customer,
    subTotal: SubTotal,
    total: Final_Value,
    discountType: Discount_Type,
    saleStatusId: SalesStatus_id,
    saleTypeId: SalesType_id,
    salesSubtypeId,
    generateTitleAfterCloseSale: SalesSubtype?.generateTitleAfterCloseSale,
    generalDiscountPercentage: Discount_Value_Percentage,
    generalDiscountCash: Discount_Value,
    saleCode: Code,
    link: link,
    showOnPanel,
    forecastReturn,
    employeeId,
    userIdWhoClosedSale,
    hasGroupedTitles: sale.BillsToReceiveSales?.some(
      (billToReceiveSale) =>
        billToReceiveSale?.BillsToReceive.isGrouped &&
        billToReceiveSale?.BillsToReceive?.billStatusId === 2
    ),

    returnedValue,
    freightValue,
    sat_id,
    nfe_id,
    nfce_id,
    nfse_id,
  };

  if (SalesEmployees?.length > 0) {
    const salesEmployeesIds = SalesEmployees.map(
      (saleEmployee) => saleEmployee.employeeId
    );

    serializedSale.salesEmployeesIds = salesEmployeesIds;
  }

  if (Customer.Vehicle.length) {
    serializedSale.clientVehicles = Customer.Vehicle.map((vehicle, i) => ({
      ...vehicle,
      index: i,
    }));
  }

  if (
    sale?.Payments?.CondPayment?.isFeeActive &&
    sale?.Payments?.CondPayment?.feePercent
  ) {
    dispatch(
      handleChange(sale?.Payments?.CondPayment?.feePercent, 'installmentFees')
    );
  }

  if (Vehicle_id !== null && serializedSale.clientVehicles) {
    serializedSale.selectedVehicle = serializedSale.clientVehicles.find(
      (vehicle) => String(vehicle.id) === String(Vehicle_id)
    );

    if (SalesStatus_id === 5) {
      if (serializedSale?.selectedVehicle) {
        serializedSale.selectedVehicle.Kilometers = previousKilometers;
      }
    }
  }

  const salesItems = SalesItems.map((saleItem) => ({
    SalesItems_id: saleItem.id,
    id: saleItem.Type === 'Produto' ? saleItem.Product_id : saleItem.Service_id,
    Code:
      saleItem.Type === 'Produto'
        ? saleItem.Products?.Code
        : saleItem.Services?.Code,
    Description: saleItem.Description,
    Manufacturer_Ref:
      saleItem.Product_id !== null ? saleItem.Products.Manufacturer_Ref : '---',
    Brand:
      saleItem.Product_id !== null
        ? saleItem.Products?.Brands !== null
          ? saleItem.Products?.Brands.Description
          : 'Não informado'
        : '---',
    WarrantyDescription:
      saleItem.Product_id === null
        ? saleItem.Services?.Warranty?.Description
        : saleItem.Products?.Warranty?.Description,
    edit: false,
    ...saleItem,
    Commission_Rate: saleItem.commission,
    updateDescriptionOnSale: saleItem?.Products?.updateDescriptionOnSale,
  })).sort((a, b) => a?.lineNumber - b?.lineNumber);

  const salesItemsCanceled = SalesItemsCanceled.map((saleItem) => ({
    id: saleItem.Type === 'Produto' ? saleItem.Product_id : saleItem.Service_id,
    Code:
      saleItem.Type === 'Produto'
        ? saleItem.Products?.Code
        : saleItem.Services?.Code,
    Description: saleItem.Description,
    Manufacturer_Ref:
      saleItem.Product_id !== null ? saleItem.Products.Manufacturer_Ref : '---',
    Brand:
      saleItem.Product_id !== null
        ? saleItem.Products?.Brands !== null
          ? saleItem.Products?.Brands.Description
          : 'Não informado'
        : '---',
    WarrantyDescription:
      saleItem.Product_id === null
        ? saleItem.Services?.Warranty?.Description
        : saleItem.Products?.Warranty?.Description,
    edit: false,
    ...saleItem,
    Commission_Rate: saleItem.commission,
    updateDescriptionOnSale: saleItem.updateDescriptionOnSale,
    isCancelled: true,
  })).sort((a, b) => a?.lineNumber - b?.lineNumber);

  serializedSale.items = [...salesItems, ...salesItemsCanceled];

  if (Payments !== null) {
    serializedSale.selectedCondOfPayment = Payments.CondPayment;
    let totalReceived = 0;
    serializedSale.installments = Payments.Parcels.map((item) => {
      totalReceived += item.Amount;
      return {
        id: item.id,
        date: new Date(item.Due_Date).toISOString().split('T')[0],
        value: item.Amount,
        formOfPayment: item.FormOfPayment,
        amountReceived: item.amountReceived,
        authorizationCode: item.PaymentsAuthorizations?.code,
        cashierBank: { id: item.cashierBankId },
      };
    });
    serializedSale.totalReceived = totalReceived;

    if (totalReceived > Final_Value) {
      serializedSale.change = totalReceived - Final_Value;
      serializedSale.opened = 0;
    } else if (totalReceived < Final_Value) {
      serializedSale.change = 0;
      serializedSale.opened = Final_Value - totalReceived;
    } else {
      serializedSale.change = 0;
      serializedSale.opened = 0;
    }
  }

  // let feePercent = serializedSale.selectedCondOfPayment.feePercent

  await dispatch(calculate(serializedSale.items));
  dispatch({ type: 'LOAD_SALE_SUCCESS', payload: serializedSale });
};

export const loadAllClients = () => async (dispatch, getState) => {
  await dispatch({ type: 'LOAD_ALL_CLIENTS_STARTED' });
  const { companyId } = getState().saleReducer;

  try {
    const clients = await customersRepository.getAllActiveByCompany(companyId);

    dispatch(loadAllClientsSuccess(clients));
  } catch (err) {
    dispatch(loadAllClientsError(err));
  }
};

export function loadAllClientsError(err) {
  console.log(err);
  toastr.error('Ocorreu um erro ao carregar os clientes');

  return { type: 'LOAD_ALL_CLIENTS_FAILURE' };
}

export function loadAllClientsSuccess(clients) {
  clients.forEach((client) => {
    let Plate = '';

    client.Vehicle.forEach((vehicle) => {
      if (vehicle.License_Plate) {
        Plate +=
          vehicle.License_Plate.replace(/(\d{0})(\d)/, '$1-$2').toString() +
          ' ';
      }

      if (vehicle.Maintenance_Object) {
        Plate += vehicle.Maintenance_Object + ' ';
      }

      if (vehicle.serialIdentification) {
        Plate += vehicle.serialIdentification + ' ';
      }
    });

    client.Vehicles = Plate;
  });

  return { type: 'LOAD_ALL_CLIENTS_SUCCESS', payload: clients };
}

export const setClientById = (id) => async (dispatch, getState) => {
  await dispatch(loadAllClients());

  const { clients } = getState().saleReducer;

  const client = clients.find((c) => c.id === id);
  if (client) {
    dispatch(setClient(client));
  }
};

export function setClient(client) {
  return { type: 'SET_CURRENT_CLIENT', payload: client };
}

export const setClientVehicle = (vehicle) => {
  return { type: 'SET_CLIENT_VEHICLE', payload: vehicle };
};

export function clearClientVehicle() {
  return { type: 'CLEAR_SELECTED_VEHICLE' };
}

export const addItems = (items, allItems) => (dispatch) => {
  let currentItems = allItems;

  items.forEach((item) => {
    let exist = false;

    currentItems.forEach((c) => {
      c.edit = false;

      if (
        (c.id === item.id ||
          (item.Type === 'Produto' && c.Product_id === item.id) ||
          (item.Type === 'Serviço' && c.Service_id === item.id)) &&
        c.Discount_Value === item.Discount_Value
      ) {
        c.Quantity = parseInt(parseInt(c.Quantity) + parseInt(item.Quantity));
        c.Amount =
          c.Discount_Value === 0
            ? (c.Unit_Value * c.Quantity).toFixed(2)
            : (
                c.Quantity *
                (c.Unit_Value - (c.Unit_Value * c.Discount_Value) / 100)
              ).toFixed(2);

        exist = true;
      }
    });

    if (!exist) {
      let obj = item;
      obj.Amount =
        obj.Discount_Value === 0
          ? (obj.Unit_Value * obj.Quantity).toFixed(2)
          : (
              obj.Quantity *
              (obj.Unit_Value - (obj.Unit_Value * obj.Discount_Value) / 100)
            ).toFixed(2);
      obj.Product_id = item.Type === 'Produto' ? item.id : null;
      obj.Service_id = item.Type === 'Serviço' ? item.id : null;
      currentItems.push(obj);
    }
  });

  dispatch([handleInstallmentFees(0, 'installmentFees')]);

  dispatch([
    { type: 'HANDLE_ITEMS', payload: currentItems },
    { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
    {
      type: 'HANDLE_CHANGE',
      payloadType: 'selectedCondOfPayment',
      payload: {},
    },
    calculate(currentItems),
  ]);
};

export const recalculateTotals = (items) => (dispatch) => {
  dispatch([
    { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
    {
      type: 'HANDLE_CHANGE',
      payloadType: 'selectedCondOfPayment',
      payload: {},
    },
    calculate(items),
  ]);
};

export function confirmEraseItem(item) {
  return { type: 'SET_ITEM_TO_REMOVE', payload: item };
}

export const removeItem = (item) => (dispatch, getState) => {
  const { items } = getState().saleReducer;

  let newItems = items.filter((c) => c !== item);

  dispatch([
    { type: 'CONFIRM_REMOVE_ITEM', payload: newItems },
    { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
    {
      type: 'HANDLE_CHANGE',
      payloadType: 'selectedCondOfPayment',
      payload: {},
    },
    calculate(newItems),
  ]);
};

export function editItem(index, items) {
  let obj = items;

  obj.filter((c, i) => i !== index).forEach((c) => (c.edit = false));

  obj[index].edit = !obj[index].edit;

  if (obj[index].edit) {
    return {
      type: 'START_EDIT_ITEM',
      payload: obj[index],
    };
  } else {
    return {
      type: 'HANDLE_ITEMS',
      payload: obj,
    };
  }
}

export function handleChangeItemEditable(value, type, item) {
  let obj = { ...item };
  obj[type] = value;
  return { type: 'HANDLE_CHANGE', payload: obj, payloadType: 'itemToEdit' };
}

export const handleConfirmItemWithDiscountGreater =
  (itemToEditIndex, itemToEdit, items, isAllowedDiscountGreater) =>
  (dispatch) => {
    dispatch(
      handleConfirmEditItem(
        itemToEditIndex,
        itemToEdit,
        items,
        isAllowedDiscountGreater,
        () => null,
        true
      )
    );
  };

export const handleConfirmEditItem =
  (
    index,
    item,
    allItems,
    isAllowedDiscountGreater,
    setIsModalDiscountReleaseWithPasswordOpen,
    bypassAuthentication = false
  ) =>
  (dispatch) => {
    let obj = { ...item };
    let items = allItems;

    if (obj.Amount === items[index].Amount) {
      obj.Amount =
        obj.Discount_Value === 0
          ? Number((obj.Unit_Value * obj.Quantity).toFixed(2))
          : Number(
              obj.Quantity *
                (
                  obj.Unit_Value -
                  (obj.Unit_Value * obj.Discount_Value) / 100
                ).toFixed(2)
            );
    } else {
      obj.Discount_Value = Number(
        (100 - (obj.Amount / (obj.Quantity * obj.Unit_Value)) * 100).toFixed(2)
      );
    }

    if (!bypassAuthentication) {
      if (
        !isAllowedDiscountGreater &&
        (obj.Discount_Value ? obj.Discount_Value : obj.Discount_Rate) >
          Number(allItems[index].discountLimit)
      ) {
        setIsModalDiscountReleaseWithPasswordOpen(true);
        return;
      }
    }

    obj.edit = false;

    items[index] = obj;

    dispatch([
      { type: 'FINISH_EDIT_ITEM', payload: items },
      { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
      {
        type: 'HANDLE_CHANGE',
        payloadType: 'selectedCondOfPayment',
        payload: {},
      },
      calculate(items),
      handleChange(false, 'openItemsModal'),
    ]);
  };

export const handleDiscounts = (value, type) => async (dispatch, getState) => {
  dispatch({
    type: 'HANDLE_GENERAL_DISCOUNTS',
    payload: value,
    payloadType: type,
  });

  const { items, installments } = getState().saleReducer;

  dispatch([calculate(items), createInstallments(installments.length)]);
};

export const handleFreight = (value, type) => async (dispatch, getState) => {
  dispatch({
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type,
  });

  const { items, installments } = getState().saleReducer;
  dispatch([calculate(items), createInstallments(installments.length)]);
};

export const handleInstallmentFees =
  (value, type) => async (dispatch, getState) => {
    dispatch({
      type: 'HANDLE_CHANGE',
      payload: value,
      payloadType: type,
    });

    const { items, installments } = getState().saleReducer;
    dispatch([calculate(items), createInstallments(installments.length)]);
  };

export function handleDiscountType(value, items) {
  return async function (dispatch) {
    await dispatch({ type: 'CHANGE_DISCOUNT_TYPE', payload: value });

    dispatch(calculate(items));
  };
}

// export function handleFeePercent(feePercent, items) {
//   return async function (dispatch) {
//     await dispatch({ type: 'CHANGE_FEE_PERCENT', payload: feePercent })

//     dispatch(calculate(items, feePercent))
//   }
// }

export function handleChange(value, type) {
  return {
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type,
  };
}

export const loadPaymentData = () => async (dispatch, getState) => {
  const { companyId } = getState().saleReducer;

  dispatch({ type: 'LOAD_COND_OF_PAYMENTS_STARTED' });
  // const conditionsOfPayment = await conditionsOfPaymentRepository.getAllByCompanySortedByCondition(companyId)
  const conditionsOfPayment =
    await conditionsOfPaymentRepository.getAllByCompanyActiveSortedByCondition(
      companyId
    );

  dispatch({
    type: 'LOAD_COND_OF_PAYMENTS_SUCCESS',
    payload: conditionsOfPayment,
  });

  dispatch({ type: 'LOAD_FORM_OF_PAYMENTS_STARTED' });
  // const formsOfPayment = await formsOfPaymentRepository.getAllByCompanySortedByDescription(companyId)
  const formsOfPayment =
    await formsOfPaymentRepository.getAllByCompanyActiveSortedByDescription(
      companyId
    );

  dispatch({
    type: 'LOAD_FORM_OF_PAYMENTS_SUCCESS',
    payload: formsOfPayment,
  });

  dispatch({ type: 'LOAD_CASHIER_BANKS_STARTED' });
  const cashierBanks =
    await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
      companyId
    );

  dispatch({
    type: 'LOAD_CASHIER_BANKS_SUCCESS',
    payload: cashierBanks,
  });
};

export const handleCondOfPayments = (id, condOfPayments) => (dispatch) => {
  let condOfPayment = '';
  let installments = 0;
  if (id !== '') {
    condOfPayment = condOfPayments.find(
      (c) => c.id.toString() === id.toString()
    );

    if (
      condOfPayment.Condition === 'À vista' ||
      condOfPayment.Condition === '0'
    ) {
      installments = 1;
    } else {
      if (condOfPayment.hasEntry) {
        installments = parseInt(condOfPayment.Condition.split('x')[0]) + 1;
      } else {
        installments = parseInt(condOfPayment.Condition.split('x')[0]);
      }
    }
  }

  if (condOfPayment.isFeeActive) {
    dispatch([
      handleInstallmentFees(condOfPayment.feePercent, 'installmentFees'),
    ]);
  } else {
    dispatch([handleInstallmentFees(0, 'installmentFees')]);
  }

  dispatch([
    {
      type: 'HANDLE_COND_OF_PAYMENT',
      payload: condOfPayment,
    },
    createInstallments(installments),
  ]);
};

export function handleInstallmentValue(value, index) {
  return {
    type: 'HANDLE_FIRST_INSTALLMENT_VALUE',
    payload: { value, index },
  };
}

export function handleInstallmentAuthorizationCode(value, index) {
  return {
    type: 'HANDLE_AUTHORIZATION_CODE',
    payload: { value, index },
  };
}

export function handleInstallmentDate(value, index) {
  return async function (dispatch) {
    await dispatch({
      type: 'HANDLE_FIRST_INSTALLMENT_DATE',
      payload: { value, index },
    });

    dispatch({ type: 'HANDLE_INSTALLMENT_DATES', payload: { value, index } });
  };
}
export function handleInstallmentFormOfPayment(value, index) {
  return {
    type: 'HANDLE_INSTALLMENT_FORM_OF_PAYMENT',
    payload: { value, index },
  };
}

export function handleInstallmentAmountReceived(value, index) {
  return {
    type: 'HANDLE_INSTALLMENT_CHANGE_AMOUNT_RECEIVED',
    payload: { value, index },
  };
}

export function replicateFirstFormOfPayment() {
  return {
    type: 'REPLICATE_FIRST_FORM_OF_PAYMENT',
  };
}

export function handleSubmit(sale, isPlanPrime, company) {
  const {
    currentKilometers,
    selectedVehicle,
    installments,
    selectedClient,
    items,
    total,
    forecastReturn,
  } = sale;

  if (forecastReturn != null && new Date(forecastReturn) < new Date()) {
    toastr.warning(
      'OSDigital',
      'Insira uma data futura válida para retorno do cliente.'
    );

    return { type: 'VALIDATIONS_FAILED' };
  }

  if (!selectedClient.Company_Name) {
    toastr.warning('Cliente não selecionado');

    return { type: 'VALIDATIONS_FAILED' };
  }

  if (currentKilometers < selectedVehicle.Kilometers) {
    toastr.warning('KM atual deve ser maior ou igual o KM anterior');

    return { type: 'VALIDATIONS_FAILED' };
  }

  if (items.length === 0) {
    toastr.warning('Insira ao menos um item para salvar');

    return { type: 'VALIDATIONS_FAILED' };
  }

  if (installments.length > 0) {
    /// SE O USUÁRIO INSERIU AS INFORMAÇÕES DO PAGAMENTO
    const noFormOfPayment = installments.filter(
      (p) => p.formOfPayment.id === ''
    );

    const noValue = installments.filter((p) => p.value === 0);

    if (noFormOfPayment.length > 0) {
      toastr.warning('Insira a forma de pagamento de todas as parcelas');

      return { type: 'VALIDATIONS_FAILED' };
    }

    if (noValue.length > 0) {
      toastr.warning('Existem parcelas sem valor inserido');

      return { type: 'VALIDATIONS_FAILED' };
    }

    if (isPlanPrime) {
      if (
        !isTotalCreditStoreValid(
          installments,
          selectedClient,
          company.companyConfig
        )
      ) {
        return { type: 'VALIDATIONS_FAILED' };
      }
    }
  }

  return { type: 'VALIDATIONS_SUCCESS' };
}

const isTotalCreditStoreValid = (
  installments,
  selectedClient,
  companyConfig
) => {
  let creditParcelsCount = 0;

  const totalCreditStoreValue = installments.reduce((acc, installment) => {
    if (installment.formOfPayment.TypeOfPayment === 'Crédito Loja') {
      creditParcelsCount++;
      return acc + installment.value;
    }
    return acc;
  }, 0);

  if (
    selectedClient.availableCredit < totalCreditStoreValue &&
    creditParcelsCount > 0 &&
    companyConfig?.manageInternalCredit
  ) {
    toastr.warning(
      'Saldo Insuficiente de crédito',
      'O total de crédito disponível é insuficiente para os valores das parcelas selecionados. Verifique e tente novamente.'
    );

    return false;
  }

  return true;
};

export function finishStonePayment() {
  return {
    type: 'STONE_PAYMENT_SUCCESS',
  };
}

export async function handleSaleStatusAndType(
  sale,
  type,
  history,
  company,
  userIdWhoClosedSale,
  isPlanFree,
  isPlanStart,
  isPlanBasic,
  isPaidWorkmotor
) {
  const {
    items,
    selectedVehicle,
    installments,
    saleId,
    total,
    totalReceived,
    companyConfig,
  } = sale;
  // validação do tipo de venda   | validação do status da venda //
  //                              | 1 - Agendado(a)
  //                              | 2 - Cancelado(a)
  // 1 - Orçamento                | 3 - Em Aberto(a)
  // 2 - Venda                    | 4 - Em Andamento(a)
  // 3 - O.S                      | 5 - Finalizado(a)

  if (companyConfig.allowOrderOfServiceWithoutVehicle === false) {
    if (
      items.filter((c) => c.Type === 'Serviço').length > 0 &&
      !selectedVehicle.id
    ) {
      toastr.warning('Por favor, informe o veículo para a ordem de serviço.');

      return { type: 'VALIDATIONS_FAILED' };
    }
  }

  if (
    type !== 'orcament' &&
    type !== 'os-aberta' &&
    type !== 'os/venda-aberta' &&
    type !== 'venda-aberta' &&
    total > totalReceived &&
    +total.toFixed(2) > +totalReceived.toFixed(2) &&
    sale.generateTitleAfterCloseSale &&
    sale.saleStatusId !== constants.SALE_STATUS_ID.FINISHED
  ) {
    toastr.warning('Ainda há valor Em Aberto');

    return { type: 'VALIDATIONS_FAILED' };
  }

  let saleTypeId;
  let saleStatusId;

  switch (type) {
    case 'orcament':
      saleTypeId = 1;
      saleStatusId = 3;
      break;

    case 'os-aberta':
      saleTypeId = 3;
      saleStatusId = 3;
      break;

    case 'venda-aberta':
      saleTypeId = 2;
      saleStatusId = 3;
      break;

    case 'os/venda-aberta':
      saleTypeId =
        items.filter((c) => c.Type === 'Serviço').length === 0 ? 2 : 3;
      saleStatusId = 3;
      break;

    case 'os/venda':
      if (sale.generateTitleAfterCloseSale && installments.length === 0) {
        toastr.warning('Insira a condição e forma de pagamento');

        return { type: 'VALIDATIONS_FAILED' };
      }

      saleTypeId =
        items.filter((c) => c.Type === 'Serviço').length === 0 ? 2 : 3;
      saleStatusId = 5;
      break;

    default:
      return { type: 'VALIDATIONS_FAILED' };
  }

  return async function (dispatch) {
    await dispatch({
      type: 'HANDLE_SALE_TYPE_AND_STATUS',
      payload: {
        saleTypeId,
        saleStatusId,
      },
    });

    if (saleId) {
      if (saleStatusId == 5) {
        if (saleTypeId == 3) {
          const maintenance =
            await MaintenanceRepository.getMaintenanceBySaleId(
              company.id,
              saleId
            );

          if (maintenance) {
            await MaintenanceRepository.updateMaintenance(maintenance?.id, {
              status: 'Finalizado',
            });

            await salesRepository.updateShowOnPanelStatus(
              maintenance.id,
              false
            );
          }
        }

        dispatch(handleChange(userIdWhoClosedSale, 'userIdWhoClosedSale'));
      }

      dispatch(
        updateSale(
          history,
          company,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } else {
      if (saleStatusId == 5) {
        dispatch(handleChange(userIdWhoClosedSale, 'userIdWhoClosedSale'));
      }

      dispatch(
        createSale(
          history,
          company,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    }
  };
}

export async function handleSaleStatusAndTypeWithoutInstallments(
  sale,
  type,
  history,
  company,
  userIdWhoClosedSale,
  isPlanFree,
  isPlanStart,
  isPlanBasic,
  isPaidWorkmotor
) {
  const {
    items,
    selectedVehicle,
    saleId,
    total,
    totalReceived,
    companyConfig,
  } = sale;
  // validação do tipo de venda   | validação do status da venda //
  //                              | 1 - Agendado(a)
  //                              | 2 - Cancelado(a)
  // 1 - Orçamento                | 3 - Em Aberto(a)
  // 2 - Venda                    | 4 - Em Andamento(a)
  // 3 - O.S                      | 5 - Finalizado(a)

  if (companyConfig.allowOrderOfServiceWithoutVehicle === false) {
    if (
      items.filter((c) => c.Type === 'Serviço').length > 0 &&
      !selectedVehicle.id
    ) {
      toastr.warning('Por favor, informe o veículo para a ordem de serviço.');

      return { type: 'VALIDATIONS_FAILED' };
    }
  }

  let saleTypeId;
  let saleStatusId;

  switch (type) {
    case 'orcament':
      saleTypeId = 1;
      saleStatusId = 3;
      break;

    case 'os-aberta':
      saleTypeId = 3;
      saleStatusId = 3;
      break;

    case 'venda-aberta':
      saleTypeId = 2;
      saleStatusId = 3;
      break;

    case 'os/venda-aberta':
      saleTypeId =
        items.filter((c) => c.Type === 'Serviço').length === 0 ? 2 : 3;
      saleStatusId = 3;
      break;

    case 'os/venda':
      saleTypeId =
        items.filter((c) => c.Type === 'Serviço').length === 0 ? 2 : 3;
      saleStatusId = 5;
      break;

    default:
      return { type: 'VALIDATIONS_FAILED' };
  }

  return async function (dispatch) {
    await dispatch({
      type: 'HANDLE_SALE_TYPE_AND_STATUS',
      payload: {
        saleTypeId,
        saleStatusId,
      },
    });

    if (saleId) {
      if (saleStatusId == 5) {
        if (saleTypeId == 3) {
          const maintenance =
            await MaintenanceRepository.getMaintenanceBySaleId(
              company.id,
              saleId
            );

          if (maintenance) {
            await MaintenanceRepository.updateMaintenance(maintenance?.id, {
              status: 'Finalizado',
            });

            await salesRepository.updateShowOnPanelStatus(
              maintenance.id,
              false
            );
          }
        }

        dispatch(handleChange(userIdWhoClosedSale, 'userIdWhoClosedSale'));
      }

      dispatch(
        updateSale(
          history,
          company,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } else {
      if (saleStatusId == 5) {
        dispatch(handleChange(userIdWhoClosedSale, 'userIdWhoClosedSale'));
      }

      dispatch(
        createSale(
          history,
          company,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    }
  };
}

function processErrorMessages(errorMessage) {
  const errorProblems = errorMessage.split(',');
  const filteredProblems = errorProblems.filter(
    (problem) => problem.trim() !== ''
  );

  return filteredProblems;
}

function simplifyValidationErrorTranslations(problem) {
  return problem.includes('Customer email is required')
    ? 'e-mail'
    : problem.includes('Customer cpf/cnpj is required')
    ? 'cpf/cnpj'
    : problem.includes('Customer address is required')
    ? 'endereço principal'
    : problem.includes('Customer phone is required')
    ? 'celular'
    : problem.includes('Customer phone number is required')
    ? 'número de celular'
    : problem.includes('Customer zipcode is required')
    ? 'cep'
    : problem.includes('Customer state is required')
    ? 'estado'
    : problem.includes('Customer city is required')
    ? 'cidade'
    : problem.includes('Customer neighborhood is required')
    ? 'bairro'
    : problem.includes('Customer address number is required')
    ? 'número do endereço'
    : problem.includes('Customer company name is required')
    ? 'nome da empresa'
    : problem.includes('Trading name is required')
    ? 'nome fantasia'
    : problem.includes('Ops, Número do título não foi encontrado.')
    ? 'número do título'
    : problem;
}

function constructErrorMessage(filteredProblems) {
  if (filteredProblems.length > 1) {
    return `Não foram localizadas as seguintes informações do cliente: ${filteredProblems.join(
      ', '
    )}. Por favor, atualize os dados.`;
  } else if (filteredProblems.length === 1) {
    return `Não foi localizada a seguinte informação: ${filteredProblems[0]}. Por favor, atualize os dados.`;
  } else {
    return 'Não foram localizados todos os dados necessários para a emissão do boleto do cliente. Por favor, tente novamente.';
  }
}

const handleEmitBankSlipError = (errorMessage) => {
  const processedProblems = processErrorMessages(errorMessage);
  const translatedProblems = processedProblems.map(
    simplifyValidationErrorTranslations
  );
  const specificMessage = constructErrorMessage(translatedProblems);

  toastr.error('Emissão de Boleto', specificMessage);
};

const updateSale =
  (history, company, isPlanFree, isPlanStart, isPlanBasic, isPaidWorkmotor) =>
  async (dispatch, getState) => {
    const {
      isNewSale,
      isAppointmentSave,
      saleId,
      installments,
      selectedCondOfPayment,
      total,
      saleStatusId,
      saleTypeId,
      companyId,
      selectedClient,
      selectedVehicle,
      currentKilometers,
      customerRequest,
      observations,
      malfunctions,
      defects,
      forecastReturn,
      showOnPanel,
      productsData,
      totalReceived,
      change,
      servicesData,
      generalDiscountCash,
      generalDiscountPercentage,
      discountType,
      subTotal,
      items,
      saleCode,
      photos,
      videos,
      photosToDelete,
      employeeId,
      emitBankSlipNow,
      userIdWhoClosedSale,
      sellerId,
      tradeRepresentativeId,
      attendanceTypeId,
      prismaBoxId,
      salesEmployeesIds,
      freightValue,
      bonusId,
      orderId,
      usedCRMBonus,
      redeemedBonus,
      shouldReplaceInstallments,
      originalFormOfPayments,
      salesSubtypeId,
      generateTitleAfterCloseSale,
      neededToCreateCustomerCredit,
      isClosingAgain,
      companyConfig,
      videosUploaded,
      photosUploaded,
    } = getState().saleReducer;

    if (!saleId) {
      dispatch({ type: 'COMPONENT_UNMOUNT' });
      return;
    }

    dispatch({ type: 'UPDATE_SALE_STARTED' });

    const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

    try {
      let saleInstallments = generateTitleAfterCloseSale ? installments : null;
      if (generateTitleAfterCloseSale) {
        if (shouldReplaceInstallments && installments.length) {
          saleInstallments = installments?.map((item, index) => {
            return {
              ...item,
              formOfPayment: originalFormOfPayments[index],
            };
          });
        }
      }

      await salesRepositoryV2.update(saleId, {
        sale: {
          SalesType_id: saleTypeId,
          salesSubtypeId,
          generateTitleAfterCloseSale,
          SalesStatus_id: saleStatusId,
          License_Plate: selectedVehicle.License_Plate,
          previousKilometers: selectedVehicle.Kilometers,
          Kilometers: currentKilometers,
          customerRequest,
          Comments: observations,
          Malfunctions: malfunctions,
          Defects: defects,
          forecastReturn,
          showOnPanel: saleTypeId !== 3 ? false : showOnPanel,
          Product_Value: productsData.total,
          Discount_Products: productsData.discount.value,
          Service_Value: servicesData.total,
          Discount_Services: servicesData.discount.value,
          Discount_Value: generalDiscountCash,
          Discount_Value_Percentage: generalDiscountPercentage,
          SubTotal: subTotal,
          Discount_Type: discountType,
          Final_Value: total,
          Vehicle_id: selectedVehicle.id,
          Discount_Products_Percentage: productsData.discount.percent,
          Discount_Services_Percentage: servicesData.discount.percent,
          employeeId,
          userIdWhoClosedSale,
          sellerId,
          tradeRepresentativeId,
          attendanceTypeId,
          prismaBoxId,
          salesEmployeesIds,
          freightValue,
          bonusId,
          orderId,
          usedCRMBonus,
          redeemedBonus,
          neededToCreateCustomerCredit,
        },
        isClosingAgain,
        payment: {
          installments: saleInstallments,
          Amount: total,
          Amount_Received: totalReceived,
          change,
          date: new Date().getTime(),
          CondPayment_id: selectedCondOfPayment.id,
        },
        emitBankSlipNow,
        isPlanPrime,
        isPaidWorkmotor,
        items: items.filter((item) => !item.isCancelled),
        canceledItems: items.filter((item) => item.isCancelled),
        photos: photosUploaded.map((photo) => ({
          link: photo.location,
          name: photo.originalname,
          description: photo.description,
          size: photo.size,
        })),
        videos: videosUploaded.map((video) => ({
          link: video.location,
          name: video.originalname,
          description: video.description,
          size: video.size,
        })),
        photosToDelete,
      });

      if (
        saleStatusId === 5 &&
        saleTypeId === 3 &&
        !isPlanFree &&
        !isPlanStart
      ) {
        emailRepository.sendCompletedServiceEmail({
          companyId: companyId,
          customerId: selectedClient.id,
          vehicleId: selectedVehicle.id,
          saleCode,
        });
      }

      if (photosToDelete.length > 0) {
        await s3Repository.deleteImages(photosToDelete);
      }

      // impresso de venda automatico
      if (saleTypeId !== 4 && isNewSale && !isAppointmentSave) {
        if (companyConfig.printSaleWhenCreate === true) {
          if (
            (saleTypeId === 1 && saleStatusId === 3) ||
            (saleTypeId === 2 && saleStatusId === 5) ||
            (saleTypeId === 3 && (saleStatusId === 5 || saleStatusId === 3))
          ) {
          }
          const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31`;
          const link = `${window.location.origin}/sheets/${hash}`;

          window.open(link, '_blank');
        }
      }

      if (saleStatusId === constants.SALE_STATUS_ID.FINISHED) {
        // dispatch(getProductsUnderStock(companyId))

        if (emitBankSlipNow) {
          try {
            const installmentWithBankSlip = installments.find(
              (installment) => installment.formOfPayment.isBankSlipIntegration
            );

            await bankSlipRepository.emit(
              saleId,
              companyId,
              installmentWithBankSlip.formOfPayment.bankBilletAccountId
            );
            toastr.success(
              'Boletos gerados e enviados para o email do cliente'
            );
          } catch (err) {
            console.log(err);

            handleEmitBankSlipError(err.response.data.message);
          }
        }

        let hasStoneIntegratedPayment = false;
        if (generateTitleAfterCloseSale) {
          hasStoneIntegratedPayment = installments.some(
            (installment) => installment.formOfPayment.isStoneIntegration
          );
        }
        if (hasStoneIntegratedPayment) {
          dispatch({ type: 'STONE_PAYMENT_STARTED', payload: saleId });
        } else {
          dispatch(updateSaleSuccess());
          dispatch(handleChange(false, 'keepOnScreenAfterSave'));
        }
      } else {
        dispatch(updateSaleSuccess());
        dispatch(handleChange(false, 'keepOnScreenAfterSave'));
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Atualizar',
        'Ocorreu um erro ao atualizar a venda: ' + err?.response?.data?.message
      );

      if (photosUploaded.data?.length > 0) {
        await s3Repository.deleteImages(
          photosUploaded.data.map((photo) => ({ ...photo, name: photo.key }))
        );
      }

      return dispatch({ type: 'UPDATE_SALE_FAILURE' });
    }
  };

export function updateSaleSuccess(saleId) {
  return { type: 'UPDATE_SALE_SUCCESS' };
}

export const cancelSale =
  (saleId, companyId, saleType, items, userId, loadSale, lossReasonId) =>
  async (dispatch) => {
    dispatch({ type: 'CANCEL_SALE_STARTED' });
    try {
      if (saleType === 'PDV') {
        let onlyProducts = items.SalesItems.filter((p) => p.Type === 'Produto');

        onlyProducts = onlyProducts.map((item) => {
          if (!item.QuantityItemsReturned) {
            return {
              ...item,
              newQuantityToReturn: item.Quantity,
            };
          } else if (
            item.QuantityItemsReturned &&
            item.Quantity !== item.QuantityItemsReturned
          ) {
            return {
              ...item,
              newQuantityToReturn: item.Quantity - item.QuantityItemsReturned,
            };
          }
        });

        await salesRepository.devolution(saleId, onlyProducts, userId);
      }

      if (saleType === 'Ordem de Serviço') {
        const maintenance = await MaintenanceRepository.getMaintenanceBySaleId(
          companyId,
          saleId
        );

        if (maintenance) {
          await MaintenanceRepository.updateMaintenance(maintenance?.id, {
            status: 'Finalizado',
          });

          await salesRepository.updateShowOnPanelStatus(maintenance.id, false);
        }
      }

      await salesRepository.cancel(saleId, lossReasonId);

      toastr.success('Cancelamento realizado com sucesso!');

      dispatch({ type: 'CANCEL_SALE_SUCCESS' });
    } catch (err) {
      console.log(err);
      toastr.error('Não foi possivel realizar o cancelamento!');
      return dispatch({ type: 'CANCEL_SALE_FAILURE' });
    } finally {
      await loadSale();
    }
  };

const createSale =
  (history, company, isPlanFree, isPlanStart, isPlanBasic, isPaidWorkmotor) =>
  async (dispatch, getState) => {
    const {
      installments,
      selectedCondOfPayment,
      total,
      companyId,
      userId,
      saleStatusId,
      saleTypeId,
      selectedClient,
      selectedVehicle,
      currentKilometers,
      customerRequest,
      forecastReturn,
      observations,
      malfunctions,
      showOnPanel,
      defects,
      productsData,
      totalReceived,
      change,
      servicesData,
      generalDiscountCash,
      generalDiscountPercentage,
      discountType,
      subTotal,
      items,
      saleCode,
      photos,
      videos,
      quickQuoteId,
      userIdWhoClosedSale,
      employeeId,
      emitBankSlipNow,
      prismaBoxId,
      sellerId,
      tradeRepresentativeId,
      attendanceTypeId,
      salesEmployeesIds,
      freightValue,
      companyConfig,
      bonusId,
      orderId,
      usedCRMBonus,
      redeemedBonus,
      originalFormOfPayments,
      shouldReplaceInstallments,
      salesSubtypeId,
      generateTitleAfterCloseSale,
      neededToCreateCustomerCredit,
      isAppointmentSave,
      videosUploaded,
      photosUploaded,
    } = getState().saleReducer;
    const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

    dispatch({ type: 'CREATE_SALE_STARTED' });

    try {
      let saleInstallments = generateTitleAfterCloseSale ? installments : null;
      if (generateTitleAfterCloseSale) {
        if (shouldReplaceInstallments && installments?.length) {
          saleInstallments = installments.map((item, index) => {
            return { ...item, formOfPayment: originalFormOfPayments[index] };
          });
        }
      }

      const sale = await salesRepositoryV2.create({
        sale: {
          SalesType_id: saleTypeId,
          salesSubtypeId,
          generateTitleAfterCloseSale,
          SalesStatus_id: saleStatusId,
          Code: saleCode,
          Date: new Date(),
          Company_id: companyId,
          User_id: userId,
          Customer_id: selectedClient.id,
          Vehicle_id: selectedVehicle.id,
          License_Plate: selectedVehicle.License_Plate,
          previousKilometers: selectedVehicle.Kilometers,
          Kilometers: currentKilometers,
          customerRequest,
          Comments: observations,
          forecastReturn,
          Malfunctions: malfunctions,
          Defects: defects,
          showOnPanel: saleTypeId !== 3 ? false : showOnPanel,
          Product_Value: productsData.total,
          Discount_Products: productsData.discount.value,
          Service_Value: servicesData.total,
          Discount_Services: servicesData.discount.value,
          Discount_Value: generalDiscountCash,
          Discount_Value_Percentage: generalDiscountPercentage,
          SubTotal: subTotal,
          Discount_Type: discountType,
          Final_Value: total,
          Discount_Products_Percentage: productsData.discount.percent,
          Discount_Services_Percentage: servicesData.discount.percent,
          userIdWhoClosedSale: userIdWhoClosedSale,
          employeeId: employeeId,
          prismaBoxId,
          sellerId,
          tradeRepresentativeId,
          attendanceTypeId,
          salesEmployeesIds,
          freightValue,

          bonusId,
          orderId,
          usedCRMBonus,
          redeemedBonus,
          isCreditManagementEnabled: companyConfig?.manageInternalCredit,
          neededToCreateCustomerCredit,
        },
        payment: {
          installments: saleInstallments,
          Amount: total,
          Amount_Received: totalReceived,
          change,
          date: new Date().getTime(),
          CondPayment_id: selectedCondOfPayment.id,
        },
        emitBankSlipNow,
        items: items.filter((item) => !item.isCancelled),
        canceledItems: items.filter((item) => item.isCancelled),
        photos: photosUploaded.map((photo) => ({
          link: photo.location,
          name: photo.originalname,
          description: photo.description,
          size: photo.size,
        })),
        videos: videosUploaded.map((video) => ({
          link: video.location,
          name: video.originalname,
          description: video.description,
          size: video.size,
        })),
        quickQuoteId,
        isPlanPrime,
        isPaidWorkmotor,
      });

      if (
        saleStatusId === 5 &&
        saleTypeId === 3 &&
        !isPlanFree &&
        !isPlanStart
      ) {
        emailRepository.sendCompletedServiceEmail({
          companyId: companyId,
          customerId: selectedClient.id,
          vehicleId: selectedVehicle.id,
          saleCode: sale.Code,
        });
      }

      // impresso de venda automatico
      if (saleTypeId !== 4 && !isAppointmentSave) {
        if (companyConfig.printSaleWhenCreate === true) {
          if (
            (saleTypeId === 1 && saleStatusId === 3) ||
            (saleTypeId === 2 && saleStatusId === 5) ||
            (saleTypeId === 3 && (saleStatusId === 5 || saleStatusId === 3))
          ) {
          }
          const hash = `Vend=v723dha5tHSSpS83711${sale.id}5422hsGsdAQLk8423oPL31`;
          const link = `${window.location.origin}/sheets/${hash}`;

          window.open(link, '_blank');
        }
      }

      if (saleStatusId === constants.SALE_STATUS_ID.FINISHED) {
        // dispatch(getProductsUnderStock(companyId))

        let hasStoneIntegratedPayment = false;
        if (generateTitleAfterCloseSale) {
          hasStoneIntegratedPayment = installments.some(
            (installment) => installment.formOfPayment.isStoneIntegration
          );
        }

        if (emitBankSlipNow) {
          try {
            const installmentWithBankSlip = installments.find(
              (installment) => installment.formOfPayment.isBankSlipIntegration
            );
            await bankSlipRepository.emit(
              sale.id,
              companyId,
              installmentWithBankSlip.formOfPayment.bankBilletAccountId
            );
            toastr.success(
              'Boletos gerados e enviados para o email do cliente'
            );
          } catch (err) {
            console.log(err);

            handleEmitBankSlipError(err.response.data.message);
          }
        }

        if (hasStoneIntegratedPayment) {
          dispatch({ type: 'STONE_PAYMENT_STARTED', payload: sale.id });
        } else {
          dispatch(createSaleSuccess(sale.id));
          dispatch(handleChange(false, 'keepOnScreenAfterSave'));
          dispatch(handleChange(false, 'isAppointmentSave'));
          dispatch(handleChange(sale.Code, 'saleCode'));
        }
      } else {
        dispatch(createSaleSuccess(sale.id));
        dispatch(handleChange(sale.Code, 'saleCode'));
        dispatch(handleChange(false, 'keepOnScreenAfterSave'));
        dispatch(handleChange(false, 'isAppointmentSave'));
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Salvar',
        'Ocorreu um erro ao salvar a venda: ' + err?.response?.data?.message
      );
      dispatch({ type: 'CREATE_SALE_FAILURE' });
      if (photosUploaded.data?.length > 0) {
        await s3Repository.deleteImages(
          photosUploaded.data.map((photo) => ({ ...photo, name: photo.key }))
        );
      }
    }
  };

export function createSaleSuccess(saleId) {
  return { type: 'CREATE_SALE_SUCCESS', payload: saleId };
}

export const createInstallments =
  (installmentsLength) => (dispatch, getState) => {
    const { defaultCashierBankId } = getState().saleReducer;
    const installments = Array(installmentsLength)
      .fill()
      .map(() => ({
        date: '',
        value: 0,
        formOfPayment: '',
        amountReceived: false,
        cashierBank: {
          id: defaultCashierBankId,
        },
        authorizationCode: '',
      }));

    dispatch({
      type: 'HANDLE_INSTALLMENTS',
      payload: installments,
    });
  };

export function calculate(data) {
  let productsData = getAllValuesFromItems(data, 'Produto', false);
  let servicesData = getAllValuesFromItems(data, 'Serviço', false);

  let dataItens = data.filter((item) => !item.isCancelled);

  let subTotal = dataItens
    .filter((item) => !item.isCancelled)
    .map((c) => parseFloat(c.Amount));

  if (subTotal.length > 0) {
    subTotal = subTotal.reduce((atual, acumulado) => atual + acumulado);
  } else subTotal = 0;

  // if (feePercent && feePercent > 0) {
  //   subTotal = subTotal + (subTotal * feePercent) / 100
  // }

  return {
    type: 'FINISH_CALCULATE_VALUES',
    payload: {
      subTotal,
      productsData,
      servicesData,
    },
  };
}

function getAllValuesFromItems(items, type, allowCancelled) {
  let total = 0;
  let totalWithoutDiscount = 0;
  let discount = {
    value: 0,
    percent: 0,
  };

  let filtered = items
    .filter((c) => c.Type === type && (allowCancelled ? true : !c.isCancelled))
    .map((c) => parseFloat(c.Amount));

  if (filtered.length > 0) {
    total = Number(
      filtered.reduce((atual, acumulado) => atual + acumulado)
    ).toFixed(2);

    totalWithoutDiscount = Number(
      items
        .filter(
          (c) => c.Type === type && (allowCancelled ? true : !c.isCancelled)
        )

        .map(
          (c) => c.Unit_Value * (c.Quantity - (c.QuantityItemsReturned || 0))
        )
        .reduce((atual, acumulado) => atual + acumulado)
        .toFixed(2)
    );

    discount.value = Number((totalWithoutDiscount - total).toFixed(2));
    discount.percent =
      totalWithoutDiscount === 0
        ? 0
        : Number(((discount.value / totalWithoutDiscount) * 100).toFixed(2));
  }

  return {
    total,
    totalWithoutDiscount,
    discount,
  };
}

export const handleConfirmChangesInCashierBankModal = (installments) => {
  return {
    type: 'HANDLE_CONFIRM_CHANGES_IN_CASHIER_BANK_MODAL',
    payload: {
      installments,
    },
  };
};

import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import ListRefusedItemsMain from './components/ListRefusedItemsMain';

export default function ListRefusedItems() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Listagem de Itens Recusados (Orçamento Digital)',
            ]}
            path={['home', null, null, null]}
          />
          <ListRefusedItemsMain />
        </Row>
      </Grid>
    </div>
  );
}

import api from '../services/api';
import config from '../config';

const uploadImage = async ({ file }, folder) => {
  const form = new FormData();
  form.append('image', file);

  const response = await api.post(
    config.endpoint + `s3/upload-image?folder=${folder}`,
    form
  );
  return response.data.imageURL;
};

const uploadImages = async (files, folder) => {
  const form = new FormData();
  files.map(({ file }) => form.append(`images`, file));

  return api.post(config.endpoint + `s3/upload-images?folder=${folder}`, form);
};

const uploadVideos = async (files, folder) => {
  const form = new FormData();
  files.map(({ file }) => form.append(`videos`, file));

  return api.post(config.endpoint + `s3/upload-videos?folder=${folder}`, form);
};

const deleteImages = async (images) => {
  await api.post(config.endpoint + 's3/delete-img', { images });
};

const getDownloadPDVDeskUrl = () =>
  api.get(config.endpoint + 's3/download-PDV-desk');

const s3Repository = {
  uploadImage,
  deleteImages,
  uploadImages,
  uploadVideos,
  getDownloadPDVDeskUrl,
};

export default s3Repository;

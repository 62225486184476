import React from 'react';
import { ProgressBarContainer, ProgressBarFill } from './ProgressBar.styles';

export function ProgressBar({
  progress,
  progressLabel,
  showProgressPercentage = true,
}) {
  return (
    <div className="flex column center gap-050">
      <ProgressBarContainer>
        <ProgressBarFill progress={progress} />
      </ProgressBarContainer>
      {showProgressPercentage && <span>{progressLabel}</span>}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from './redux/actions';
import { SalesPhotosList } from './SalePhotosList';
import { SalePhotosAdd } from './SalePhotosAdd';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { Button } from 'v2/components/Button/DefaultButton';
import s3Repository from 'repositories/S3';
import { useAuth } from 'contexts/auth';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { AdaptiveModal } from './SalePhotosButton.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const SalePhotosButton = () => {
  const [isConfirmCloseAddFileModalOpen, setIsConfirmCloseAddFileModalOpen] =
    useState(false);
  const { company } = useAuth();
  const { fetchLoading, isLoading, LoadingComponent } = useLoading();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const dispatch = useDispatch();
  const {
    saleReducer: {
      isPhotosModalOpen,
      isAddPhotosModalOpen,
      photos,
      videos,
      videoToImport = [],
      photoToImport = [],
      saleStatusId,
      photosUploaded = [],
      videosUploaded = [],
      fileDescription,
    },
  } = useSelector((state) => state);

  const handleAddFile = async () => {
    if (!fileDescription) {
      return toastr.warning(
        'Importação não realizada',
        'Verifique a descrição e tente novamente.'
      );
    }
    if (
      !photoToImport.description &&
      !photoToImport.name &&
      !videoToImport.name &&
      !videoToImport.description
    )
      return toastr.warning(
        'Importação não realizada',
        'Informe uma imagem/vídeo e tente novamente.'
      );
    fetchLoading(async () => {
      if (videoToImport.name) {
        const { data } = await s3Repository.uploadVideos(
          [videoToImport],
          company.Cpf_Cnpj + '/sales'
        );
        dispatch([
          handleChange(
            [...videosUploaded, { ...data[0], description: fileDescription }],
            'videosUploaded'
          ),
          handleChange({}, 'videoToImport'),
          handleChange(false, 'isAddPhotosModalOpen'),
          handleChange('', 'fileDescription'),
        ]);
      }

      if (photoToImport.name) {
        const { data } = await s3Repository.uploadImages(
          [photoToImport],
          company.Cpf_Cnpj + '/sales'
        );
        dispatch([
          handleChange(
            [...photosUploaded, { ...data[0], description: fileDescription }],
            'photosUploaded'
          ),
          handleChange({}, 'photoToImport'),
          handleChange(false, 'isAddPhotosModalOpen'),
          handleChange('', 'fileDescription'),
        ]);
      }
    });
  };

  const handleCloseAddPhotoModal = () => {
    if (
      !!photoToImport.name ||
      !!photoToImport.description ||
      !!videoToImport.description ||
      !!videoToImport.name
    )
      return setIsConfirmCloseAddFileModalOpen(true);
    closeAddPhotoModal();
  };

  const handleConfirmCloseAddPhotoModal = () => {
    setIsConfirmCloseAddFileModalOpen(false);
    closeAddPhotoModal();
  };

  const closeAddPhotoModal = () => {
    dispatch([
      handleChange(!isAddPhotosModalOpen, 'isAddPhotosModalOpen'),
      handleChange({}, 'photoToImport'),
      handleChange({}, 'videoToImport'),
    ]);
  };

  return (
    <>
      <Button
        color="buttonEmitNFSe"
        width="120px"
        height="35px"
        fontSize="14px"
        fontWeight="regular"
        onClick={() => dispatch(handleChange(true, 'isPhotosModalOpen'))}
        style={{
          marginRight: 10,
          color: '#FFFFFF',
        }}
        disabled={
          isPlanFree ||
          isPlanStart ||
          (saleStatusId === 5 && photos.length === 0) ||
          (saleStatusId === 2 && photos.length === 0)
        }
      >
        Fotos/Vídeos
      </Button>

      <AdaptiveModal
        show={isPhotosModalOpen}
        dialogClassName="modal-60w modal-adaptive-height"
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Fotos/Vídeos da OS</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesPhotosList />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              gap: '10px',
              width: '100%',
            }}
          >
            <Button
              onClick={() =>
                dispatch(handleChange(!isPhotosModalOpen, 'isPhotosModalOpen'))
              }
              bsStyle="danger"
              width="100px"
              height="35px"
              color="red400"
              fill
              fontWeight="regular"
            >
              Voltar
            </Button>
          </div>
        </Modal.Footer>
      </AdaptiveModal>

      <Modal show={isAddPhotosModalOpen} dialogClassName="modal-80w">
        <Modal.Header style={{ padding: '10px' }}>
          <Modal.Title>
            <strong>Adicionar Foto/Vídeo</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalePhotosAdd />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              gap: '10px',
              width: '100%',
            }}
          >
            <Button
              onClick={handleCloseAddPhotoModal}
              disabled={isLoading}
              color="red400"
              width="100px"
              height="35px"
              fontWeight="regular"
            >
              Voltar
            </Button>
            <Button
              onClick={handleAddFile}
              disabled={isLoading}
              color="green400"
              width="100px"
              height="35px"
              fontWeight="regular"
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="12px" />
              ) : (
                'Importar'
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmCloseAddFileModalOpen}
        message="Deseja cancelar a importação da foto/vídeo? A foto/vídeo poderá ser importada posteriormente."
        onCancel={() => setIsConfirmCloseAddFileModalOpen(false)}
        onSubmit={handleConfirmCloseAddPhotoModal}
        onHide={() => setIsConfirmCloseAddFileModalOpen(false)}
      />
    </>
  );
};

import React from 'react';

import ConfigurationsContext from './configurationsContext';
import { ThemeContextProvider, useThemeContext } from './themeContext';

const ContextProvidersV2 = ({ children }) => {
  useThemeContext();
  return (
    <ConfigurationsContext>
      <ThemeContextProvider>{children}</ThemeContextProvider>
    </ConfigurationsContext>
  );
};

export default ContextProvidersV2;

import React from 'react';

import '../index.css';
import { useBranding } from 'hooks/useBranding';

export const HeaderExternalWorkshop = () => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  return (
    <div className="external-workshop-panel__header bg-primary bg-primary-border-color"></div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

export function useLoading() {
  const [isLoading, setIsLoading] = useState(false);

  const LoadingComponent = ({ children }) => (
    <>{isLoading ? <LoadingSpinnerFullHeight /> : children}</>
  );

  const fetchLoading = useCallback(async (fetch) => {
    setIsLoading(true);
    try {
      await fetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    LoadingComponent,
    fetchLoading,
    setIsLoading,
    isLoading,
  };
}

import React from 'react';

import '../index.css';
import { useBranding } from 'hooks/useBranding';
import { useThemeContext } from 'v2/contexts/themeContext';

export const FooterExternalWorkshop = () => {
  const { getBrandingLogo } = useThemeContext();

  return (
    <div className={`external-workshop-panel__footer`}>
      <img
        className="external-workshop-panel__footer__os-dig-logo"
        src={getBrandingLogo()}
        alt={getBrandingLogo('alt')}
      />
    </div>
  );
};

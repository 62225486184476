import api from 'v2/services/api';

const getCostCenterList = async (params) => {
  try {
    const response = await api.get(`report/cost-center/generate-report`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const CostCenterRepository = {
  getCostCenterList,
};

export default CostCenterRepository;

import styled from 'styled-components';

export const CRMContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f7f6f6;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

import React, { useState } from 'react';
import { WhatsAppModal } from './components/WhatsappModal';
import { useEffect } from 'react';

export function WhatsApp() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenWhatsAppModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseWhatsAppModal = () => {
    setIsModalOpen(false);
  };

  const WhatsAppComponent = ({ onSuccess }) => {
    return (
      isModalOpen && (
        <WhatsAppModal
          isOpen={isModalOpen}
          handleOpen={handleOpenWhatsAppModal}
          handleClose={handleCloseWhatsAppModal}
          onSuccess={onSuccess}
        />
      )
    );
  };

  return {
    WhatsAppComponent,
    handleOpenWhatsAppModal,
    handleCloseWhatsAppModal,
  };
}

import { useAuth } from 'contexts/auth';
import React, { useCallback, useEffect, useState } from 'react';

import advertisementsRepository from '../../../repositories/Advertisements';
import { UseBannerContext } from '../../../contexts/bannerContext';
import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';

const Advertisements = () => {
  const [advertisements, setAdvertisements] = useState({});
  const { isAdvertisementOpen, setIsAdvertisementOpen } = UseBannerContext();

  const { companyId, userId } = useAuth();
  const { brandingId } = useThemeContext();

  useEffect(() => {
    if (companyId) loadAdvertisements();
  }, [companyId]);

  const loadAdvertisements = async () => {
    try {
      let exclusiveAd =
        await advertisementsRepository.getExclusiveAdByCompanyId(companyId);

      let globalAd = await advertisementsRepository.getActiveGlobalAd(
        companyId,
        brandingId
      );

      if (
        (exclusiveAd || globalAd) &&
        (exclusiveAd !== '' || globalAd !== '')
      ) {
        setIsAdvertisementOpen(true);
      }

      exclusiveAd = exclusiveAd === '' ? null : exclusiveAd;
      globalAd = globalAd === '' ? null : globalAd;

      setAdvertisements({ exclusiveAd, globalAd });
    } catch (err) {
      console.error(err);
    }
  };

  const openAdInNewTab = useCallback((ad) => {
    if (!ad.redirectUrl) return;
    window.open(ad.redirectUrl, 'blank');
    advertisementsRepository.click({ userId, advertisementId: ad.id });
  });

  return (
    <div className="advertisements-container">
      {isAdvertisementOpen && (
        <>
          <a
            className="close-advertisement"
            onClick={() => setIsAdvertisementOpen(false)}
            style={{ fontSize: '12px' }}
          >
            Fechar
          </a>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: '20px',
            }}
          >
            {advertisements.globalAd && (
              <img
                src={advertisements?.globalAd?.imageUrl}
                alt={advertisements?.globalAd?.description}
                onClick={() => openAdInNewTab(advertisements?.globalAd)}
                style={
                  !advertisements?.globalAd?.redirectUrl
                    ? {
                        width: '50%',
                        minHeight: '142px',
                        cursor: 'default',
                        border: 'none',
                      }
                    : {
                        width: '50%',
                        minHeight: '142px',
                        cursor: 'pointer',
                        border: 'none',
                      }
                }
              />
            )}

            {advertisements?.exclusiveAd && (
              <img
                src={advertisements?.exclusiveAd?.imageUrl}
                alt={advertisements?.exclusiveAd?.description}
                onClick={() => openAdInNewTab(advertisements?.exclusiveAd)}
                style={
                  !advertisements?.exclusiveAd?.redirectUrl
                    ? { width: '50%', minHeight: '142px', cursor: 'default' }
                    : { width: '50%', minHeight: '142px', cursor: 'pointer' }
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Advertisements;

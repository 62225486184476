import React from 'react';
import {
  Label,
  Option,
  OptionSelectorContainer,
  OptionsWrapper,
} from './OptionSelector.styles';

import useCRMStore from '../../store/crm-store';

export function OptionSelector() {
  const {
    selectedOption,
    setSelectedOption,
    setSelectedCard,
    setOpenNewCampaignForm,
  } = useCRMStore();

  return (
    <OptionSelectorContainer>
      <OptionsWrapper>
        <Option
          selected={selectedOption === 'Página Principal'}
          direction="left"
          onClick={() => {
            setSelectedOption('Página Principal');
            setOpenNewCampaignForm(false);
            setSelectedCard(null);
          }}
        >
          Página Principal
        </Option>
        <Option
          selected={selectedOption === 'Campanhas'}
          direction="right"
          onClick={() => setSelectedOption('Campanhas')}
        >
          Campanhas
        </Option>
      </OptionsWrapper>
    </OptionSelectorContainer>
  );
}

import api from 'v2/services/api';

const getItemsRefusedList = async (params) => {
  try {
    const response = await api.get(
      `report/sales-items-refused/generate-report`,
      {
        params,
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const ListRefusedItemsReportRepository = {
  getItemsRefusedList,
};

export default ListRefusedItemsReportRepository;

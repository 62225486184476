import React, { useState } from 'react';
import { Field } from 'redux-form';

import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';

import { yearMask } from 'utils/masks';

import '../styles.css';
import { BikeManufacturerModal } from '../BikeManufacturerModal';
import { BikeModelModal } from '../BikeModelModal';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';

const BikeForm = ({
  manufacturers,
  loadBicycleManufacturers,
  getBicycleModelsByBrand,
  RenderField,
  bicycleRearDerailleurId,
  bicycleBrakeTypes,
  bicycleSuspensionTypes,
  bicycleFrontGears,
  bicycleRearGears,
  bicycleRimSizes,
  bicycleFrameSizes,
  bicycleModalities,
  bicycleRearDerailleurs,
  bicycleFrameMaterials,
  bicycleModels,
  dispatch,
  change,
  isViewingData,
}) => {
  const [isBikeManufacturerModalOpen, setIsBikeManufacturerModalOpen] =
    useState(false);
  const [isBikeModelModalOpen, setIsBikeModelModalOpen] = useState(false);

  return (
    <>
      <div
        className="form-new-vehicle__simple-row"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <span>
          Série/Identificação: <span style={{ color: 'red' }}>*</span>
        </span>
        <Field
          disabled={isViewingData}
          name="serialIdentification"
          component={RenderField}
          maxLength="30"
          normalize={onlyNumbersLetters}
          style={{ width: '270px',  height: '35px' }}

        />
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              Marca: <span style={{ color: 'red' }}>*</span>{' '}
            </span>
            {!isViewingData && (
              <span>
                <a
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: '----',
                    textDecoration: 'none',
                  }}
                  onClick={() => setIsBikeManufacturerModalOpen(true)}
                >
                  <u>Nova Marca</u>
                </a>
              </span>
            )}
          </div>

          <Field
            disabled={isViewingData}
            onChange={() => dispatch(change('vehicle', 'bicycleModelId', null))}
            name="Brand"
            required
            as="select"
            component={RenderField}
          >
            <option value="1">Selecione</option>
            {manufacturers?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.Description}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              Modelo: <span style={{ color: 'red' }}>*</span>{' '}
            </span>
            {!isViewingData && (
              <span>
                <a
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: '----',
                    textDecoration: 'none',
                  }}
                  onClick={() => setIsBikeModelModalOpen(true)}
                >
                  <u>Novo Modelo</u>
                </a>
              </span>
            )}
          </div>
          <Field
            disabled={isViewingData}
            name="bicycleModelId"
            as="select"
            component={RenderField}
            required
          >
            <option value="">
              {bicycleModels?.length > 0
                ? 'Selecione'
                : 'Nenhum modelo encontrado'}
            </option>
            {bicycleModels?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <span>Modalidade:</span>
          <Field
            name="bicycleModalityId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleModalities?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: '80px' }}>
          <span>Ano:</span>
          <Field
            disabled={isViewingData}
            name="Year_Manufacture"
            {...yearMask}
            component={RenderField}
            style={{width: '80px'}}
          />
        </div>
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div style={{ width: 250 }}>
          <span>Material do Quadro:</span>
          <Field
            disabled={isViewingData}
            name="bicycleFrameMaterialId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleFrameMaterials?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 200 }}>
          <span>Tamanho do Quadro:</span>
          <Field
            name="bicycleFrameSizeId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleFrameSizes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 220 }}>
          <span>Tamanho da Roda (aro):</span>
          <Field
            name="bicycleRimSizeId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleRimSizes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <span>Tipo de Suspensão:</span>
          <Field
            disabled={isViewingData}
            name="bicycleSuspensionTypeId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleSuspensionTypes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 220 }}>
          <span>Tipo de Freio:</span>
          <Field
            name="bicycleBrakeTypeId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleBrakeTypes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div style={{ width: 250 }}>
          <span>Câmbio Traseiro:</span>
          <Field
            disabled={isViewingData}
            name="bicycleRearDerailleurId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleRearDerailleurs?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        {bicycleRearDerailleurId === '71' && (
          <div style={{ width: 250 }}>
            <span>Qual?</span>
            <Field
              disabled={isViewingData}
              name="otherRearDerailleurDescription"
              as="text"
              component={RenderField}
            />
          </div>
        )}

        <div>
          <span>Nº marchas dianteiras:</span>
          <Field
            name="bicycleFrontGearId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleFrontGears?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.quantity}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <span>Nº marchas traseiras:</span>
          <Field
            name="bicycleRearGearId"
            as="select"
            component={RenderField}
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            {bicycleRearGears?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.quantity}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 150 }}>
          <span>Cor:</span>
          <Field
            disabled={isViewingData}
            name="Color"
            as="text"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>
      </div>

      {isBikeManufacturerModalOpen && (
        <BikeManufacturerModal
          loadBicycleManufacturers={loadBicycleManufacturers}
          type={'bicycleManufacturer'}
          onCancel={() => setIsBikeManufacturerModalOpen(false)}
        />
      )}

      {isBikeModelModalOpen && (
        <BikeModelModal
          getBicycleModelsByBrand={getBicycleModelsByBrand}
          manufacturers={manufacturers}
          onCancel={() => setIsBikeModelModalOpen(false)}
        />
      )}
    </>
  );
};

export default BikeForm;

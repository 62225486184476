import { useState } from 'react';
import { toastr as toast } from 'react-redux-toastr';
import BankConciliationRepository from '../../../../../repositories/BankConciliationRepository';

export const useBankConciliation = () => {
  const [bankOptions, setBankOptions] = useState([]);
  const [selectedBankOption, setSelectedBankOption] = useState('');
  const [isFileTypeOFX, setIsFileTypeOFX] = useState(undefined);
  const [data, setData] = useState({
    isFileValid: undefined,
    message: undefined,
    ofx: undefined,
  });

  const processFiles = async (acceptedFiles) => {
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'ofx') {
          setIsFileTypeOFX(true);

          const response = await handleFileUpload(file);
          if (response) {
            setData(response);
            setIsFileTypeOFX(response.isFileValid);
            toast.success('Arquivo importado com sucesso', response.message);
          } else {
            setIsFileTypeOFX(false);
            toast.error('Erro ao importar o arquivo');
          }
        } else {
          setIsFileTypeOFX(false);
          toast.warning(
            'O arquivo selecionado não é da extensão .ofx. Verifique e tente novamente'
          );
        }
      }
    }
  };

  const handleValidateBankCode = () => {
    if (selectedBankOption && data) {
      if (
        Number(data.ofx.bankInformation?.bankId) ===
        Number(selectedBankOption.code)
      )
        return;

      setSelectedBankOption('');

      toast.error(
        'Banco inválido.',
        `Selecione uma conta/banco do banco ${data.ofx.bankInformation.bankName} que o ofx se refere.`
      );
    }
  };

  const getMessage = () => {
    const { message, isValid } = data;

    if (isValid === undefined) {
      return 'Clique aqui ou arraste para importar o arquivo .OFX';
    }

    if (!isValid) {
      return message || 'Arquivo inválido. Tente novamente.';
    }

    if (isValid) {
      return 'Arquivo importado com sucesso!';
    }

    return 'Arquivo não suportado';
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (file) => {
    try {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!file || fileExtension !== 'ofx') {
        throw new Error('Arquivo inválido. Por favor, envie um arquivo OFX.');
      }

      const base64File = await convertFileToBase64(file);
      const cleanBase64File = base64File.split(',')[1];

      const response =
        await BankConciliationRepository.sendOFXForReconciliation(
          cleanBase64File
        );

      return response;
    } catch (error) {
      console.error('Erro ao carregar o arquivo:', error);
      toast.error(`Erro ao carregar o arquivo: ${error.message}`);
    }
  };

  return {
    bankOptions,
    setBankOptions,
    selectedBankOption,
    setSelectedBankOption,
    isFileTypeOFX,
    setIsFileTypeOFX,
    data,
    processFiles,
    getMessage,
    handleValidateBankCode,
  };
};

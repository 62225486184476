import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

const DashboardCard = ({
  link,
  title,
  value,
  icon,
  color,
  fontColor,
  pendingAmount,
  onClick,
  averageTicket,
  showMessage,
  Message,
  isLoading,
  isAvailable,
}) => {
  const handleIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return toastr.error(
      'Acesso negado',
      'Você não possui permissão para visualizar a informação.'
    );
  };

  return (
    <Link to={link} id="card-dashboard">
      <Button onClick={onClick}>
        <main>
          <p>{title}</p>
          {isAvailable ? (
            <h1>
              <strong style={{ color: fontColor }}>{value}</strong>
            </h1>
          ) : (
            <h1
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <strong>•••••</strong>
              <FontAwesomeIcon
                title="Visualizar"
                color="#444444"
                cursor="pointer"
                icon={faEyeSlash}
                style={{ width: '20px', height: '16px' }}
                onClick={handleIconClick}
              />
            </h1>
          )}
          <div
            style={{
              backgroundColor: color,
              borderRadius: '800px',
              height: '40px',
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              className="iconedashboard"
              aria-hidden="false"
            />
          </div>
          {showMessage && isAvailable && !isLoading && <Message />}
          {pendingAmount && isAvailable ? (
            <span style={{ fontSize: '10px' }}>
              Existe o valor de{' '}
              <strong style={{ color: fontColor }}> {pendingAmount} </strong>{' '}
              pendente de baixa
            </span>
          ) : (
            <div />
          )}
          {averageTicket && isAvailable ? (
            <span style={{ fontSize: '9px', color: '#d9534f' }}>
              {averageTicket}
            </span>
          ) : (
            <div />
          )}
        </main>
      </Button>
    </Link>
  );
};

export default DashboardCard;

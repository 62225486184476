import React from 'react';
import { withRouter } from 'react-router-dom';
import '../style.css';
import { useThemeContext } from 'v2/contexts/themeContext';

const HeaderPdv = () => {
  const { getBrandingLogo } = useThemeContext();

  return (
    <div className="header-pdv bg-primary">
      <img
        src={getBrandingLogo('horizontal-white')}
        alt={getBrandingLogo('alt')}
      />
    </div>
  );
};

export default withRouter(HeaderPdv);

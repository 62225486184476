import styled from 'styled-components';

export const OptionSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  height: 30px;
`;

export const Option = styled.button`
  font-size: 16px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.branding1000 : theme.colors.gray300};
  transition: color 0.3s ease;
  position: relative;
  overflow: hidden;
  font-weight: ${({ selected }) => (selected ? `bold` : `regular`)};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.branding1000};
    transform: translateX(
      ${(props) =>
        props.selected ? '0' : props.direction === 'right' ? '-100%' : '100%'}
    );
    transition: transform 0.3s ease;
  }
`;

import styled from 'styled-components';

export const ContainerMainPageScreen = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 80px;
`;

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  background: white;
  border-radius: 8px;
`;

export const FlexEndWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.red400};
  font-size: 12px;
`;

export const ClickableWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
`;

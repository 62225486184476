import React from 'react';

import {
  SidebarSubMenuContainer,
  SidebarSubMenuItem,
  SidebarSubMenuItemsContainer,
  SidebarSubMenuTitle,
} from './SidebarSubMenu.styles';

import { SidebarSubMenuDegree } from '../SidebarSubMenuDegree/SidebarSubMenuDegree';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

export const SidebarSubMenu = ({ title, items }) => {
  const { handleMenuItemClick, validateFeatureAvailability } = useSidebar();
  const currentDate = format(getDateOnlyFromDate(new Date()), 'dd/MM/yyyy');
  const targetDate = format(
    getDateOnlyFromDate(new Date('2024-10-11')),
    'dd/MM/yyyy'
  );
  const isTargetDate = currentDate === targetDate;

  return (
    <SidebarSubMenuContainer key={title} title={title}>
      <SidebarSubMenuTitle>{title}</SidebarSubMenuTitle>
      <SidebarSubMenuItemsContainer>
        {items.map((item) => {
          const hasSubMenuDegree = !!item.subMenuDegree?.length;
          const isAvailable = validateFeatureAvailability(item);

          return (
            <SidebarSubMenuItem
              title={item.title}
              onClick={() =>
                item.path ? handleMenuItemClick(item.path, item) : null
              }
              disabledStyle={!isAvailable}
            >
              {item.title}
              {item.showNewBadge && !isTargetDate && (
                <span
                  style={{
                    width: '46px',
                    height: '18px',
                    borderRadius: '20px',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#5dbc60',
                    marginLeft: '5px',
                  }}
                >
                  Novo
                </span>
              )}
              {hasSubMenuDegree && (
                <SidebarSubMenuDegree
                  subMenuTitle={title}
                  subMenuDegreeTitle={item.title}
                  items={item.subMenuDegree}
                />
              )}
            </SidebarSubMenuItem>
          );
        })}
      </SidebarSubMenuItemsContainer>
    </SidebarSubMenuContainer>
  );
};

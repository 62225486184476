import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

import './styles.css';

import videosLinksRepository from '../../../repositories/VideosLinks';
import { useBranding } from 'hooks/useBranding';
import { useThemeContext } from 'v2/contexts/themeContext';

export const HelpVideoButton = ({ style }) => {
  const { brandingId } = useThemeContext();
  const [videos, setVideos] = useState([]);
  const [videoLink, setVideoLink] = useState({});

  const history = useHistory();

  useEffect(() => {
    checkRoute();
  }, [history.location, videos]);

  useEffect(() => {
    loadVideosLinks();
  }, []);

  const checkRoute = () => {
    if (videos === undefined) return;

    if (!!videos.length) {
      const video = videos.find(
        (v) => v.route === history.location.pathname.split('#')[0]
      );
      setVideoLink(video);
    }
  };

  const { isWorkmotor, isPO360 } = useBranding();

  const loadVideosLinks = async () => {
    try {
      const data = await videosLinksRepository.getAll(brandingId);

      setVideos(data);
    } catch (err) {
      toastr.error('Ocorreu algum problema ao carregar os vídeos de ajuda.');
    }
  };

  const handleOpenVideo = () => {
    if (!videoLink || !videoLink?.link)
      return toastr.warning(
        'Vídeo indisponível',
        'Ops! Infelizmente a funcionalidade ainda não possui vídeo. Em breve será disponibilizado.'
      );

    /* Os links dos vídeos devem estar completos (com o protocolo HTTP - "http://") */
    window.open(videoLink.link, 'popup', 'width=600,height=600');
  };

  return (
    <>
      <div
        className="help-video-button"
        onClick={handleOpenVideo}
        style={style}
      >
        <FontAwesomeIcon color="white" icon={faYoutube} />
        <span>Veja como usar</span>
      </div>
    </>
  );
};

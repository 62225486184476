import styled from 'styled-components';

export const ClientsMain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 10px 20px;
`;
export const ClientsMenu = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;
  background-color: #fff;
`;
export const BirthdayRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
`;
export const BirthdayRowFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

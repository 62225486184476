import api from 'v2/services/api';

export const getTypes = async () => {
  const res = await api.get(`/crm-campaign/types/`);
  return res.data;
};

export const getCnpjCases = async () => {
  const res = await api.get(`/crm-campaign/cnpjCase/`);
  return res.data;
};

export const getHeaders = async () => {
  const res = await api.get(`/crm-campaign/header/`);
  return res.data;
};

export const getSuggestions = async () => {
  const res = await api.get(`/crm-campaign/suggestion/`);
  return res.data;
};

export const getAllCampaigns = async (params) => {
  const { data } = await api.get(`/crm-campaign`, { params });
  return data;
};
export const getCustomers = async (params) => {
  const { data } = await api.get(`/crm-campaign/customers`, { params });
  return data;
};
export const getCustomerHeader = async (params) => {
  const { data } = await api.get(`/crm-campaign/customers-header`, { params });
  return data;
};
export const getAllCorrectCustomers = async (params) => {
  const { data } = await api.get(`/crm-campaign/customers/all`, { params });
  return data;
};
export const createAndSendCampaign = async (body) => {
  const { data } = await api.post(`/crm-campaign`, body);
  return data;
};

export const getCampaignById = async (id, { companyId }) => {
  const { data } = await api.get(`/crm-campaign/${id}`, {
    params: { companyId },
  });
  return data;
};

export const CRMCampaignRepository = {
  getTypes,
  getCnpjCases,
  getHeaders,
  getSuggestions,
  getAllCampaigns,
  getCustomers,
  getCustomerHeader,
  getAllCorrectCustomers,
  createAndSendCampaign,
  getCampaignById,
};

import { toastr } from 'react-redux-toastr';
import plansRepository from 'v2/repositories/Plans';

import faviconOSD from '../assets/favicon/osd.ico';
import faviconWM from '../assets/favicon/wm.ico';
import faviconTecnomotor from '../assets/favicon/tecnomotor.ico';
import faviconBarros from '../assets/favicon/barros.ico';
import faviconPO360 from '../assets/favicon/po360.ico';

import logo from 'assets/img/osdigital.png';
import logoWhite from 'assets/img/os-digital-letra-branca.png';

import logoWM from 'assets/img/workmotor/logo.png';
import logoTecnomotor from 'assets/img/tecnomotor/logo.png';
import logoBarros from 'assets/img/barros/logo.png';
import logoPO360 from 'assets/img/po360/logo.png';

import logoPO360White from 'assets/img/po360/logo-white.png';

import icon from 'assets/img/logo-osdigital.png';
import iconWM from 'assets/img/workmotor/logo-no-text-white.png';
import iconTecnomotor from 'assets/img/tecnomotor/logo-no-text-white.png';
import iconPO360 from 'assets/img/po360/logo-no-text-white.png';

export const useBranding = () => {
  const branding = process.env.REACT_APP_BRANDING || 'OSD';

  const getBrandingAcronymById = (id) => {
    switch (id) {
      case 1:
        return 'OSD';
      case 2:
        return 'WM';
      case 3:
        return 'Tecnomotor';
      case 4:
        return 'Barros';
      case 5:
        return 'PO360';
      default:
        return '';
    }
  };

  const loadPlansByBranding = async (brandingId) => {
    try {
      const plans = await plansRepository.getAll(brandingId);
      return plans;
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao Carregar os Planos', 'Tente novamente');
    }
  };

  return {
    isBarros: branding === 'BARROS',
    isTecnomotor: branding === 'TECNOMOTOR',
    isWorkmotor: branding === 'WM',
    isPO360: branding === 'PO360',
    getBrandingAcronymById,
    loadPlansByBranding,
  };
};

import api from 'v2/services/api';

export const bulkCreateBySaleId = async (data) =>
  await api.post('sales-items-refused', data);

export const listSalesWithRefusedItems = async (query) => {
  const { data } = await api.get('sales-items-refused', {
    params: query,
  });
  return data;
};
export const listSalesRefusedItemBySaleId = async (saleId) => {
  const { data } = await api.get(`sales-items-refused/${saleId}`);
  return data;
};

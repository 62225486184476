import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import crypto from 'crypto';
import ImageUploadDropzone from '../../../../components/ImagesUploadDropzone';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from './redux/actions';
import { format } from 'date-fns';
import { InputContainer } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import VideoUploadDropzone from 'client/components/VideosUploadDropzone';
import {
  InlineWrapper,
  SaleFilesAddWrapper,
  Text,
} from './SalePhotosButton.styles';
import { useModulesContext } from 'contexts/modules';
import { useBlockModals } from 'v2/hooks/useBlockModals';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth';

export const SalePhotosAdd = () => {
  const dispatch = useDispatch();
  const { company } = useAuth();
  const {
    saleReducer: { photoToImport, fileDescription, videos, videoToImport },
  } = useSelector((state) => state);

  const { isVideoModuleActive, videoModule } = useModulesContext();

  const { setIsVideoModalOpen, setCurrentInactiveVideoModule } =
    useBlockModals();

  const handleUploadPhoto = async (files) => {
    let validateFiles = await handleValidatePhoto(files, 1);

    if (!validateFiles) return;

    const currentFile = files[0];
    const id = crypto.randomBytes(16).toString('hex');
    const file = {
      ...photoToImport,
      file: new File([currentFile], `${company.Cpf_Cnpj}${currentFile.name}`),
      id: `${company.Cpf_Cnpj}${currentFile.name}`,
      name: `${company.Cpf_Cnpj}${currentFile.name}`,
      preview: URL.createObjectURL(currentFile),
      size: currentFile.size,
      uploaded: false,
      createdAt: format(new Date(), 'yyyy-MM-dd'),
    };

    dispatch(handleChange(file, 'photoToImport'));
  };

  function getVideoDuration(file) {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.src = URL.createObjectURL(file);
    });
  }

  const handleUploadVideo = async (files) => {
    let validateFiles = await handleValidateVideo(files, 1);

    if (!validateFiles) return;

    const currentFile = files[0];
    const id = crypto.randomBytes(16).toString('hex');
    const file = {
      ...videoToImport,
      file: new File([currentFile], `${company.Cpf_Cnpj}${currentFile.name}`),
      id: `${company.Cpf_Cnpj}${currentFile.name}`,
      name: `${company.Cpf_Cnpj}${currentFile.name}`,
      preview: URL.createObjectURL(currentFile),
      size: currentFile.size,
      uploaded: false,
      createdAt: format(new Date(), 'yyyy-MM-dd'),
    };
    dispatch(handleChange(file, 'videoToImport'));
  };

  const handleValidatePhoto = async (files, quantity) => {
    if (files.length > quantity) {
      toastr.warning(
        `Selecione somente ${quantity} ${quantity === 1 ? 'foto' : 'fotos'}`
      );
      return false;
    }

    for (let i = 0; i < quantity; i++) {
      if (files[i].size > 5000000) {
        toastr.warning(
          'O tamanho máximo da foto/vídeo é 5 MB. Por favor, verifique e tente novamente.'
        );

        return false;
      }

      URL.revokeObjectURL(files[i]);

      return true;
    }
  };

  const handleValidateVideo = async (files, quantity) => {
    if (files.length > quantity) {
      toastr.warning(
        `Selecione somente ${quantity} ${quantity === 1 ? 'vídeo' : 'videos'}`
      );
      return false;
    }

    const videoDuration = await getVideoDuration(files[0]);

    if (videoDuration > 90) {
      toastr.warning(
        'Importação não realizada',
        'O vídeo não pode ter mais que 1 minuto e 30 segundos de duração. Por favor, verifique e tente novamente.'
      );
      return false;
    }

    if (videos && videos.length === 5) {
      toastr.warning(
        'Importação não realizada',
        'Você atingiu o limite de vídeos permitidos.'
      );
      return false;
    }

    return true;
  };

  const isPhotoImported = Object.keys(photoToImport).length > 0;
  const isVideoImported = Object.keys(videoToImport).length > 0;

  useEffect(() => {
    if (isPhotoImported) {
      toastr.success(
        'Importação realizada com sucesso',
        'A imagem foi importada'
      );
    } else if (isVideoImported) {
      toastr.success(
        'Importação realizada com sucesso',
        'O vídeo foi importado'
      );
    }
  }, [photoToImport, videoToImport]);

  return (
    <SaleFilesAddWrapper>
      <InlineWrapper align="space-between">
        <InputContainer style={{ width: '1200px' }}>
          <InlineWrapper>
            <Text fontSize="14px">
              Descrição:
              <Text color="red400" fontSize="14px">
                *
              </Text>
            </Text>

            <Text color="red400" fontSize="12px" justify="flex-end">
              Deverá ser importada um arquivo por vez.
            </Text>
          </InlineWrapper>
          <InputText
            height={'35px'}
            value={fileDescription}
            onChange={(e) =>
              dispatch(handleChange(e.target.value, 'fileDescription'))
            }
          />
        </InputContainer>

        <Text
          color="red400"
          fontSize="12px"
          align="flex-start"
          justify="flex-end"
          style={{
            position: 'relative',
            top: '-15px',
          }}
        >
          *Campos Obrigatórios
        </Text>
      </InlineWrapper>

      <InlineWrapper
        style={{
          gap: '10px',
        }}
      >
        <div
          className="import-photo-sale"
          style={{
            width: '100%',
            display: 'flex',
            height: '325px',
            flexDirection: 'column',
          }}
        >
          <Text fontSize="12px" align="flex-end" justify="flex-end">
            <strong>Tamanho máximo: 5 MB</strong>
          </Text>
          <ImageUploadDropzone
            className={isVideoImported ? 'dropzone-disabled' : ''}
            image={photoToImport}
            onClick={() => {
              if (
                !(
                  Object.keys(videoToImport).length === 0 &&
                  videoToImport.constructor === Object
                )
              ) {
                toastr.warning(
                  'Importação não permitida',
                  'Deverá ser importado apenas um arquivo por vez'
                );
                return false;
              }
              return true;
            }}
            onRemove={() => dispatch(handleChange({}, 'photoToImport'))}
            onUpload={handleUploadPhoto}
            label="Adicionar foto"
            disabled={isVideoImported}
          />
        </div>
        <div
          className="import-photo-sale"
          style={{
            width: '100%',
            display: 'flex',
            height: '325px',
            flexDirection: 'column',
          }}
        >
          <InlineWrapper align="space-between" justify="space-between">
            <Text fontSize="12px" justify="flex-start">
              <strong>Limite de tempo:</strong> 1 minuto e 30 segundos
            </Text>
            <Text fontSize="12px" justify="flex-end">
              <strong>Vídeos disponíveis: </strong>
              {videos && videos.length > 0 ? videos.length : '0'}
              /5
            </Text>
          </InlineWrapper>
          <VideoUploadDropzone
            className={isPhotoImported ? 'dropzone-disabled' : ''}
            video={videoToImport}
            onClick={() => {
              if (
                !(
                  Object.keys(photoToImport).length === 0 &&
                  photoToImport.constructor === Object
                )
              ) {
                toastr.warning(
                  'Importação não permitida',
                  'Deverá ser importado apenas um arquivo por vez'
                );
                return false;
              }

              if (!isVideoModuleActive) {
                setCurrentInactiveVideoModule(videoModule);
                setIsVideoModalOpen(true);
                return false;
              }
              if (videos && videos.length === 5) {
                toastr.warning(
                  'Importação não realizada',
                  'Você atingiu o limite de vídeos permitidos.'
                );
                return false;
              }
              return true;
            }}
            onRemove={() => dispatch(handleChange({}, 'videoToImport'))}
            onUpload={handleUploadVideo}
            label="Adicionar vídeo"
            disabled={isPhotoImported}
          />
        </div>
      </InlineWrapper>
    </SaleFilesAddWrapper>
  );
};

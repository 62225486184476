import config from '../config';
import api from '../services/api';

const index = () => api.get(config.endpoint + 'cost-center');

const store = (values) => api.post(config.endpoint + 'cost-center', values);

const getAllByCompany = async (params) => {
  try {
    const { data } = await api.get('cost-center', { params });
    return data;
  } catch (err) {
    throw err;
  }
};

const getById = (costCenterId) =>
  api.get(config.endpoint + `cost-center/${costCenterId}`);

const update = async (values) => {
  try {
    const response = await api.put(config.endpoint + 'cost-center', values);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default { index, store, getAllByCompany, getById, update };

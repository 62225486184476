import api from 'v2/services/api';

export const getBirthDayIndicators = async (companyId) => {
  const res = await api.get(`/crm/indicators-birthday/${companyId}`);
  return res.data;
};

export const getReturnIndicators = async (companyId) => {
  const res = await api.get(`/crm/indicators-return/${companyId}`);
  return res.data;
};

export const CRMRepository = {
  getBirthDayIndicators,
  getReturnIndicators,
};

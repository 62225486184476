import React from 'react';
import { Modal } from 'react-bootstrap';

const CampaignCloseModal = ({ show, onSubmit, OnCancel }) => {
  return (
    <Modal show={show} onHide={OnCancel}>
      <Modal.Header>
        <Modal.Title>Alterar o Tipo da Campanha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Você deseja alterar o tipo? Ao alterar, a listagem de clientes será
          recarregada.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            gap: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <button className="button button-red button-h35" onClick={OnCancel}>
            Não
          </button>
          <button className="button button-green button-h35" onClick={onSubmit}>
            Sim
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignCloseModal;

import React, { useState, useEffect } from 'react';
import '../style.css';
import api from 'services/api';
import config from 'config';
import { toastr } from 'react-redux-toastr';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useBranding } from 'hooks/useBranding';

import logoOSD from 'assets/img/osdigital.jpg';
import logoWM from 'assets/img/workmotor/logo.png';
import { useThemeContext } from 'v2/contexts/themeContext';

function ForgotPassword({ history }) {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const { getBrandingLogo, brandingId } = useThemeContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get('email');

    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);

  useEffect(() => {
    if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setDisabled(false);
    } else if (!disabled) setDisabled(true);
  }, [email]);

  const submit = async (e) => {
    setLoading(true);
    setDisabled(true);

    e.preventDefault();

    let response;
    try {
      response = await api.get(
        config.endpoint + `users/search/byEmail/${email}`
      );
    } catch (err) {
      console.log(err);
      setDisabled(false);
      setLoading(false);
      return toastr.error('Erro', err.response?.data?.message);
    }

    await sendMail(response.data.user);
    setLoading(false);
  };

  async function sendMail(user) {
    try {
      await api.post(config.endpoint + `email/restorePassword`, {
        Email: email,
        User_id: user.id,
        Name: user.Name.split(' ')[0],
        Location: window.location.origin,
        brandingId,
      });

      setEmailSent(true);
    } catch (err) {
      console.log(err);
      return toastr.error('Erro', 'Ocorreu algum erro ao enviar e-mail.');
    }
  }

  return (
    <body
      className={`
        corFundoLogin bg-primary
      `}
    >
      <Col xs={12} sm={12} md={12} lg={12}>
        {!emailSent ? (
          <Col id="ExpGrtsContent">
            <div id="card-login">
              <div className="container-login">
                <Col>
                  <img
                    id="expGratisLogo"
                    src={getBrandingLogo()}
                    alt={getBrandingLogo('alt')}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Col>
                    <div
                      className="expGratisCardHeader"
                      style={{ color: 'black' }}
                    >
                      <h5>Redefinir minha senha</h5>
                      <p style={{ fontSize: 14 }}>
                        Preencha o email abaixo para redefinir sua senha
                      </p>
                    </div>
                  </Col>

                  <div className="expGratisCardBody" style={{ color: 'black' }}>
                    <p id="login-p">
                      E-mail<span style={{ color: 'red' }}>*</span>
                    </p>
                    <input
                      maxLength="50"
                      className="form-control foco-input"
                      placeholder="seu@gmail.com"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className="expGratisCardFooter"
                    >
                      <button
                        id="esqueciSenhaBtn"
                        className="btn vermelho"
                        disable={loading}
                        type="button"
                        onClick={() => history.push('/client-login')}
                      >
                        Voltar
                      </button>
                      <button
                        id="esqueciSenhaBtn"
                        className="btn btn-sucesso"
                        disabled={disabled}
                        type="button"
                        onClick={(e) => submit(e)}
                      >
                        <span
                          className={`${
                            loading && 'fa fa-spinner fa-pulse fa-1x'
                          }`}
                          style={{ marginRight: '5px' }}
                        ></span>
                        Redefinir
                      </button>
                    </div>
                  </Col>
                </Col>
              </div>
            </div>
          </Col>
        ) : (
          <Col id="ExpGrtsContent">
            <div id="card-login">
              <div className="container-login">
                <Col>
                  <img
                    id="expGratisLogo"
                    src={getBrandingLogo()}
                    alt={getBrandingLogo('alt')}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  {/* <Col>
                    <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 IconeSucessoSenha">
                      <span className="fa fa-check-circle fa-3x" style={{ width: '60%', color: '#00c100' }} ></span>
                    </div>
                  </Col> */}
                  <Col>
                    <div className="expGratisCardHeader">
                      <h5 className="txtEnviado">
                        E-mail enviado com sucesso.
                      </h5>
                    </div>
                  </Col>
                  <div className="expGratisCardBody">
                    <button
                      id="voltarLoginBtn"
                      className="btn btn-sucesso"
                      type="button"
                      onClick={() => history.push('/client-login')}
                    >
                      Voltar para Login
                    </button>
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        )}
      </Col>
    </body>
  );
}

export default withRouter(ForgotPassword);

import React, { createContext, useState, useContext } from 'react';

const ModulesContext = createContext({
  isNFeModuleActive: false,
  isNFCeModuleActive: false,
  isNFSeModuleActive: false,
  isBankSlipModuleActive: false,
  isWorkshopPanelModuleActive: false,
  isSaleItemApprovalCancellationActive: false,
});

export default function ModulesContextProvider({ children }) {
  const [nfeModule, setNfeModule] = useState({});
  const [nfceModule, setNfceModule] = useState({});
  const [nfseModule, setNfseModule] = useState({});
  const [bankSlipModule, setBankSlipModule] = useState({});
  const [workshopPanelModule, setWorkshopPanelModule] = useState({});
  const [boletoFlexModule, setBoletoFlexModule] = useState({});
  const [crmModule, setCrmModule] = useState({});
  const [videoModule, setVideoModule] = useState({});

  const [isNFeModuleActive, setIsNFeModuleActive] = useState(false);
  const [isNFCeModuleActive, setIsNFCeModuleActive] = useState(false);
  const [isNFSeModuleActive, setIsNFSeModuleActive] = useState(false);
  const [isBankSlipModuleActive, setIsBankSlipModuleActive] = useState(false);
  const [isCrmModuleActive, setIsCrmModuleActive] = useState(false);
  const [isVideoModuleActive, setIsVideoModuleActive] = useState(false);
  const [isWorkshopPanelModuleActive, setIsWorkshopPanelModuleActive] =
    useState({});
  const [isWorkshopPanelConfigActive, setIsWorkshopPanelConfigActive] =
    useState(false);
  const [isBoletoFlexModuleActive, setIsBoletoFlexModuleActive] =
    useState(false);
  const [
    isSaleItemApprovalCancellationActive,
    setIsSaleItemApprovalCancellationActive,
  ] = useState(false);

  const [nfseModuleStatus, setNfseModuleStatus] = useState();
  //o módulo NFSe tem 4 status:
  //0 - Não Verificado, 1 - Não Disponível, 2 - Inativo, 3 - Ativo

  const [isAnyFiscalModuleActive, setIsAnyFiscalModuleActive] = useState(false);

  const getModulesData = (company) => {
    const nfeModule = company?.Modules[0];
    const nfceModule = company?.Modules[1];
    const nfseModule = company?.Modules[2];
    const workshopPanelModule = company?.Modules[3];
    const bankSlipModule = company?.Modules[4];
    const crmModule = company?.Modules[6];
    const videoModule = company?.Modules[7];

    const nfeModuleStatus = nfeModule.CompanyModules.status;
    const nfceModuleStatus = nfceModule.CompanyModules.status;
    const nfseModuleStatus = nfseModule.CompanyModules.status;
    const workshopPanelModuleStatus =
      workshopPanelModule?.CompanyModules.status;
    const bankSlipModuleStatus = bankSlipModule?.CompanyModules.status;
    const crmModuleStatus = crmModule?.CompanyModules.status;
    const videoModuleStatus = videoModule?.CompanyModules.status;
    const isSaleItemApprovalCancellation =
      company?.companyConfig?.saleItemApprovalCancellation;
    const isWorkshopPanelConfig = company?.companyConfig?.activateWorkshopPanel;

    setCrmModule(crmModule);
    setVideoModule(videoModule);
    setNfeModule(nfeModule);
    setNfceModule(nfceModule);
    setNfseModule(nfseModule);
    setWorkshopPanelModule(workshopPanelModule);
    setBankSlipModule(bankSlipModule);

    setIsNFeModuleActive(nfeModuleStatus === 3);
    setIsNFCeModuleActive(nfceModuleStatus === 3);
    setIsNFSeModuleActive(nfseModuleStatus === 3);
    setIsWorkshopPanelModuleActive(workshopPanelModuleStatus === 3);
    setIsWorkshopPanelConfigActive(isWorkshopPanelConfig);
    setIsBankSlipModuleActive(bankSlipModuleStatus === 3);
    setIsCrmModuleActive(crmModuleStatus === 3);
    setIsVideoModuleActive(videoModuleStatus === 3);
    setIsSaleItemApprovalCancellationActive(isSaleItemApprovalCancellation);

    setNfseModuleStatus(nfseModuleStatus);
    setIsAnyFiscalModuleActive(
      nfeModuleStatus === 3 || nfceModuleStatus === 3 || nfseModuleStatus === 3
    );
  };

  return (
    <ModulesContext.Provider
      value={{
        nfeModule,
        nfceModule,
        nfseModule,
        crmModule,
        videoModule,
        bankSlipModule,
        workshopPanelModule,
        isNFeModuleActive,
        isNFCeModuleActive,
        isNFSeModuleActive,
        isBankSlipModuleActive,
        isWorkshopPanelModuleActive,
        isWorkshopPanelConfigActive,
        nfseModuleStatus,
        isSaleItemApprovalCancellationActive,
        isAnyFiscalModuleActive,
        isCrmModuleActive,
        isVideoModuleActive,

        getModulesData,
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
}

export function useModulesContext() {
  return useContext(ModulesContext);
}

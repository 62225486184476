const envConfig = {
  endpoint: 'https://release.api.osdig.com.br/',
  webSocketEndpoint: 'wss://release.api.osdig.com.br/ws/v2',
  queridocarroendpoint: 'https://staging.api.sobrerodas.club/',
  whatsappendpoint: 'https://wa.me/55',
  mysuiteendpoint: 'http://workg.mysuite.com.br/client/chatan.php?:',
  movideskendpoint: 'https://workgroup.movidesk.com',
  sobreRodasWebEndpoint: 'https://staging.sobrerodas.club',
};

export default envConfig;

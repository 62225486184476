import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from 'contexts/auth';
import { Portal } from '@radix-ui/react-dialog';
import newsUserRepository from 'repositories/NewsUserRepository';
import newsRepository from 'repositories/News';
import { DialogNewsContent, DialogNewsOverlay, DialogNewsRoot } from './styled';
import { useThemeContext } from 'v2/contexts/themeContext';

import './styles.css';

const NewsModal = () => {
  const { user } = useAuth();
  const { brandingId } = useThemeContext();

  const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
  const [news, setNews] = useState({});

  useEffect(() => {
    if (!user) return;

    verifyDashboard();
  }, [user]);

  const verifyDashboard = async () => {
    const url = window.location.pathname;
    if (url === '/canceled-client') {
      return;
    }
    const showNewsDashboard = await newsUserRepository.verifyNews(
      user.id,
      brandingId
    );

    if (showNewsDashboard === true) {
      let activeNews = await newsRepository.getActiveNews(brandingId);

      if (activeNews.length) {
        setNews(activeNews[0]);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!!news.id) {
      setIsNewsModalOpen(true);
    }
  }, [news]);

  return (
    <>
      {!!news?.id ? (
        <DialogNewsRoot open={isNewsModalOpen} back>
          <Portal />
          <DialogNewsOverlay />
          <DialogNewsContent
            onPointerDownOutside={() => setIsNewsModalOpen(false)}
          >
            <div className="flex end">
              <a
                style={{
                  color: 'blue',
                  textDecoration: 'underscore',
                  cursor: 'pointer',
                }}
                onClick={() => setIsNewsModalOpen(false)}
              >
                Fechar
              </a>
            </div>
            <a
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                window.open(news.url, '_blank');
                setIsNewsModalOpen(false);
              }}
            >
              <img src={news.imageUrl} alt={news.description} />
            </a>
          </DialogNewsContent>
        </DialogNewsRoot>
      ) : (
        ''
      )}
    </>
  );
};

export default NewsModal;

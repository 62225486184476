import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillTransfer,
  faCheckDouble,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  BankConciliationInfo,
  BankConciliationUploadContent,
  BankConciliationInfoSelect,
  BankConciliationButtonContainer,
} from './BankConciliationModal.styles';
import cashierBankRepository from '../../../../../repositories/CashierBank';
import { toastr } from 'react-redux-toastr';
import { useBankConciliation } from '../hooks/useBankConciliation';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { useAuth } from 'contexts/auth';
import { withRouter } from 'react-router';

const BankConciliationModal = ({ show, onCancel }) => {
  const { LoadingComponent, setIsLoading, isLoading } = useLoading();

  const {
    bankOptions,
    setBankOptions,
    selectedBankOption,
    setSelectedBankOption,
    isFileTypeOFX,
    data,
    processFiles,
    getMessage,
    handleValidateBankCode,
  } = useBankConciliation();

  const { company } = useAuth();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      await processFiles(acceptedFiles);
      setIsLoading(false);
    },
  });

  const getIcon = () => {
    if (data.isValid === undefined) {
      return { icon: faMoneyBillTransfer, color: '#1D7391' };
    } else if (data.isValid) {
      return { icon: faCheckDouble, color: '#5CB85C' };
    } else {
      return { icon: faCircleXmark, color: '#D9534F' };
    }
  };

  useEffect(() => {
    const getBanks = async () => {
      if (!company) return;

      try {
        const res = await cashierBankRepository.getCashierBanksByCompany(
          company.id
        );
        const banks = res.filter((bank) => bank.Bank !== null && bank.isActive);

        const banksSerialized = banks.map((bank) => ({
          id: bank?.id,
          code: bank?.Bank?.code,
          label: `${bank?.Bank?.code} - ${bank?.description}`,
        }));

        if (banks.length === 0) {
          toastr.error('Nenhum banco encontrado. Tente novamente!');
          return;
        }

        setBankOptions(banksSerialized);
      } catch (err) {
        toastr.error('Ocorreu um erro ao carregar os bancos. Tente novamente!');
      }
    };

    getBanks();
  }, [setBankOptions]);

  const iconInfo = getIcon();

  return (
    <Modal show={show} animation dialogClassName="modal-70w" onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>
          <strong>Conciliação Bancária - Importação de arquivo OFX</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BankConciliationInfo>
          <BankConciliationInfoSelect>
            <label htmlFor="type" style={{ width: '100%' }}>
              Selecione o Banco:
              <span style={{ color: 'red' }}>*</span>
            </label>
            <select
              id="bankId"
              name="bankId"
              className="form-control foco-input"
              value={selectedBankOption}
              onChange={(e) => {
                setSelectedBankOption(e.target.value);
                handleValidateBankCode(e.target.value);
              }}
            >
              <option value="">Selecione</option>
              {bankOptions.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </select>
            <small style={{ color: '#D9534F', fontSize: '12px' }}>
              Apenas bancos ativos e com cadastro completo serão exibidos
            </small>
          </BankConciliationInfoSelect>
          <small>*Campos obrigatórios</small>
        </BankConciliationInfo>

        <div
          {...getRootProps({ className: 'dropzone' })}
          style={{
            border: '2px dashed #cccccc',
            padding: '50px',
            textAlign: 'center',
          }}
        >
          <input {...getInputProps()} />
          <BankConciliationUploadContent>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <>
                <FontAwesomeIcon
                  icon={iconInfo.icon}
                  style={{ fontSize: '40px', color: iconInfo.color }}
                />
                <strong>{getMessage()}</strong>
              </>
            )}
          </BankConciliationUploadContent>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <BankConciliationButtonContainer>
          <button
            className="button button-h35 button-red flex center"
            style={{ marginRight: '10px', gap: '10px', alignItems: 'center' }}
            onClick={onCancel}
          >
            Voltar
          </button>
          <button
            className="button button-h35 button-green flex center"
            style={{ marginRight: '10px', gap: '10px', alignItems: 'center' }}
            disabled={isLoading || !isFileTypeOFX || !data.isFileValid}
          >
            Importar
          </button>
        </BankConciliationButtonContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(BankConciliationModal);

import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import CustomerRepository from 'v2/repositories/CustomerRepository';
import { InlineWrapper } from '../CampaignForm.styles';
import { Text } from 'v2/components/Text';
const PhoneUpdateModal = ({
  show,
  onHide,
  currentPhone,
  customerId,
  newPhone,
  setNewPhone,
  action,
  refreshCards,
  handleLoadCustomerData,
}) => {
  const { setValue, watch } = useFormContext();
  const customersIds = watch('Customer.customersIds');

  const onUpdate = async () => {
    if (!isValidPhoneNumber(newPhone)) {
      toastr.error(
        'Número inválido',
        'Por favor, insira um número de celular válido'
      );
      return;
    }

    try {
      await CustomerRepository.updatePhone(customerId, onlyNumbers(newPhone));
      const selecionadolista = customersIds;

      await refreshCards();

      setValue('Customer.customersIds', [...selecionadolista, customerId]);

      handleLoadCustomerData();

      toastr.success(
        'Alteração realizada',
        'O número do celular foi atualizado'
      );
      onHide();
    } catch (error) {
      toastr.error('Erro ao atualizar o celular');
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{2}\)\s9\s\d{4}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  return (
    <Modal show={show} dialogClassName={'modal-20w'}>
      <Modal.Header closeButton style={{ padding: '10px 15px' }}>
        <Modal.Title>
          <Text fontWeight="bold" size="2x">
            {action === 'add'
              ? 'Adicionar'
              : action === 'correct'
              ? 'Corrigir'
              : 'Atualizar'}{' '}
            Celular
          </Text>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InlineWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '10px',
          }}
        >
          Atual:{' '}
          <input
            type="text"
            className="form-control"
            value={phoneMask(currentPhone) || 'Não cadastrado'}
            disabled
          />
        </InlineWrapper>
        <InlineWrapper style={{ display: 'flex', alignItems: 'center' }}>
          Novo:{' '}
          <input
            type="text"
            className="form-control"
            value={phoneMask(newPhone)}
            onChange={(e) => setNewPhone(phoneMask(e.target.value))}
            placeholder="Novo celular"
          />
        </InlineWrapper>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            gap: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <button className="button button-red button-h35" onClick={onHide}>
            Voltar
          </button>
          <button className="button button-green button-h35" onClick={onUpdate}>
            Salvar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PhoneUpdateModal;

import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import api from 'services/api';
import config from 'config';
import './LoadNFCEStyles.css';
import RenderNFCE from './RenderNFCE';

import { currency } from '../../../client/components/ToNormalize/ToNormalize';
import NFCesRepository from '../../../repositories/NFCes';
import { useThemeContext } from 'v2/contexts/themeContext';
export default class ImpressoNFC extends React.Component {
  state = {
    loading: true,
    NFCE: [],
    NFCEItems: [],
    DateSale: '',
    CompanyPhone: [],
    text: 'Aguarde...',
    allData: [],
  };

  componentDidMount() {
    const res = this.props.location.pathname
      .split('NFCE=v723dha5tHSSpS83711')[1]
      .split('5422hsGsdAQLk8423oPL31')[0];
    if (!res) {
      this.setState({ text: 'Falha ao carregar dados', loading: false });
    } else {
      this.setState({ text: 'Carregando dados...' });
      this.loadNFCE(res);
    }
  }

  loadNFCE = async (nfceId) => {
    try {
      const nfce = await NFCesRepository.getByIdToPrint(nfceId, {
        ['x-force-auth']: true,
      });

      const { ibptMunicipal, ibptEstadual, ibptNacional, ibptImportado } = nfce;

      const ibptValues = `Valor aproximado dos tributos: Municipal ${currency(
        ibptMunicipal
      )}, Estadual ${currency(ibptEstadual)}, Federal ${currency(
        ibptNacional
      )} e Importado ${currency(ibptImportado)}`;
      nfce.ibptValues = ibptValues;

      await this.setState({ NFCE: nfce });
      await this.setState({ NFCEItems: nfce.NFCeItems });

      // pegar a condição de pagamento
      // const resCondPay = await api.get(config.endpoint + `condPayment/` + this.state.NFCE.Sale.Payments.CondPayment_id);

      const DataVenda = new Date(this.state.NFCE.Sale.Date)
        .toISOString()
        .substring(0, 10)
        .replace('-', '/')
        .replace('-', '/')
        .split('/')
        .reverse()
        .join('/');
      const DataEmissao = new Date(this.state.NFCE.issuedAt)
        .toISOString()
        .substring(0, 10)
        .replace('-', '/')
        .replace('-', '/')
        .split('/')
        .reverse()
        .join('/');

      const formatAcessKey =
        this.state.NFCE.accessKey.substring(0, 4) +
        ' ' +
        this.state.NFCE.accessKey.substring(4, 8) +
        ' ' +
        this.state.NFCE.accessKey.substring(8, 12) +
        ' ' +
        this.state.NFCE.accessKey.substring(12, 16) +
        ' ' +
        this.state.NFCE.accessKey.substring(16, 20) +
        ' ' +
        this.state.NFCE.accessKey.substring(20, 24) +
        ' ' +
        this.state.NFCE.accessKey.substring(24, 28) +
        ' ' +
        this.state.NFCE.accessKey.substring(28, 32) +
        ' ' +
        this.state.NFCE.accessKey.substring(32, 36) +
        ' ' +
        this.state.NFCE.accessKey.substring(36, 40) +
        ' ' +
        this.state.NFCE.accessKey.substring(40, 44);

      // faz um objeto das formas de pagamento
      let formOfPayment = [];
      let parcels = this.state.NFCE.Sale.Payments.Parcels;
      for (let i = 0; i < parcels.length; i++) {
        if (i === 0) {
          formOfPayment.push(parcels[0].FormOfPayment.TypeOfPayment);
        } else {
          if (parcels[i].FormOfPayment.TypeOfPayment != formOfPayment) {
            formOfPayment.push(parcels[i].FormOfPayment.TypeOfPayment);
          }
        }
      }
      await this.setState({
        allData: {
          DateSale: DataVenda,
          DateIssued: DataEmissao,
          NFCe: this.state.NFCE,
          NFCeItems: this.state.NFCEItems,
          Sale: this.state.NFCE.Sale,
          Company: this.state.NFCE.Company,
          Customer: this.state.NFCE.Customer,
          Payments: this.state.NFCE.Sale.Payments,
          Parcels: this.state.NFCE.Sale.Payments.Parcels,
          AcessKey: formatAcessKey,
          Code: this.state.NFCE.code,
          Serie: this.state.NFCE.serie,
          CondPayment: this.state.NFCE.Sale.Payments.CondPayment.Description,
          FormOfPayment: formOfPayment,
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({ text: 'Falha ao carregar dados' });
    }

    this.setState({ loading: false });
  };

  renderLoading = (props) => (
    <LoadingOverlay
      active={true}
      spinner
      text={props}
      classNamePrefix="MyLoader_"
      fadeSpeed={100}
    />
  );

  handlePrint() {
    const { setDocumentTitle } = useThemeContext();
    setDocumentTitle('NFC-e #' + this.state.NFCE.code);
    window.print();
  }

  renderContent = (props) => (
    <div id="content">
      <div className="impHeader">
        <button onClick={() => this.handlePrint()}>
          <span className="fa fa-print" />
          Imprimir
        </button>
      </div>
      <div id="divgray" style={{ display: 'flex', justifyContent: 'center' }}>
        <RenderNFCE {...props} />
      </div>
    </div>
  );

  render() {
    const { text, loading, allData } = this.state;
    const Content = !loading ? (
      allData.Sale ? (
        this.renderContent(allData)
      ) : (
        <div id="impLoad">{text}</div>
      )
    ) : (
      this.renderLoading(text)
    );
    return Content;
  }
}

import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Card } from 'components/Card/Card.jsx';
import { withRouter } from 'react-router-dom';
import { specialChar } from '../../../components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import api from 'services/api';
import config from 'config';
import { change } from 'redux-form';
import constants from '../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useBranding } from 'hooks/useBranding.js';
class FormMarca extends Component {
  state = {
    code: '',
    breadcrumbDescription: '',
    description: '',
    showAlertCompany: false,
    companyId: 0,
    newBrand: true,
    brandId: '',
    loading: true,
    loadingSpin: false,
    disabled: false,
    type: this.props.onlyManufacturers ? 'manufacturer' : 'product',
  };

  componentDidMount() {
    this.descriptionInput.focus();

    setTimeout(
      () => this.checkCompanyId(localStorage.getItem('ID_USUARIO')),
      500
    );

    this.setState({ loading: false });
  }

  checkCompanyId = async (User_id) => {
    var _this = this;
    const res = await api.get(config.endpoint + `companyuser/${User_id}`);
    if (res.data.status) {
      _this.setState({
        showAlertCompany: res.data.data.Company_id ? false : true,
        companyId: res.data.data.Company_id,
      });
    } else {
      _this.setState({ showAlertCompany: true });
    }

    if (!this.props.modal) {
      this.props.newBrand == true ? this.loadLastCode() : this.loadBrand();
    } else {
      this.loadLastCode();
    }
  };

  loadBrand = async () => {
    await api
      .get(config.endpoint + `brands/search/ById/${this.props.BrandId}`)
      .then(async (res) => {
        if (res.data.status) {
          const { Code, Description, Type, id } = res.data.data;
          await this.setState({
            code: Code,
            description: Description,
            newBrand: false,
            brandId: id,
            breadcrumbDescription: Description,
            type: Type,
          });
        } else window.location.href = '/client/brands';
      });
  };

  loadLastCode = async () => {
    var _this = this;
    await api
      .get(config.endpoint + `brands/LastOne/${this.state.companyId}`)
      .then((resp) => {
        _this.setState({ code: parseInt(resp.data) + 1, loading: false });
      });
  };

  handleChange(event) {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    var self = this.state;
    var _this = this;
    await _this.setState({
      disabled: !_this.state.disabled,
      loadingSpin: !_this.state.loadingSpin,
    });
    let stopMethod = 0;
    let validateType = self.type === 'product' ? 'produto' : 'montadora';

    if (this.state.description === '') {
      await _this.setState({
        disabled: !_this.state.disabled,
        loadingSpin: !_this.state.loadingSpin,
      });
      return toastr.warning('Por favor, informe a descrição da marca');
    }

    let invalidDescription = this.state.description.toUpperCase();
    if (
      invalidDescription == 'DIVERSOS' ||
      invalidDescription == 'DIVERSAS' ||
      invalidDescription == 'VARIOS' ||
      invalidDescription == 'VARIAS' ||
      invalidDescription == 'OUTROS' ||
      invalidDescription == 'OUTRAS' ||
      invalidDescription == 'TODOS' ||
      invalidDescription == 'TODAS'
    ) {
      await _this.setState({
        disabled: !_this.state.disabled,
        loadingSpin: !_this.state.loadingSpin,
      });
      return toastr.error(
        `Não é permitido cadastrar um ${validateType} com essa descrição`,
        'O nome informado se encontra em uma lista negra que irá prejudicar a organização do seu cadastro. Por favor, insira outro nome e tente novamente.'
      );
      this.descriptionInput.focus();
    }

    let description = this.state.description.trim();
    await api
      .get(
        config.endpoint +
          `brands/search/ByDesc/${
            this.state.companyId
          }/${description.toUpperCase()}`,
        { params: { Type: self.type } }
      )
      .then(async (resp) => {
        if (resp.data.status && resp.data.data.Type === self.type) {
          stopMethod = 1;
          await _this.setState({
            disabled: !_this.state.disabled,
            loadingSpin: !_this.state.loadingSpin,
          });

          if (self.type === 'product') {
            return toastr.warning('Marca já existente');
          } else {
            return toastr.warning('Montadora já existente');
          }
        }
      });

    if (stopMethod != 0) return;
    this.loadLastCode();

    if (this.state.newBrand) {
      /// SE FOR UMA NOVA MARCA
      await api
        .post(config.endpoint + `brands/${self.companyId}`, {
          Description: description.toUpperCase(),
          Code: self.code,
          Type: self.type,
        })
        .then(async (resp) => {
          if (resp.data.status) {
            if (!!this.props.createdManufacturer) {
              this.props.createdManufacturer({ ...resp.data.data });
            }
            toastr.success(
              'Sucesso',
              `Marca '${resp.data.data.Description}' cadastrada com sucesso`
            );
            if (!_this.props.modal) {
              _this.setState({ code: 0, description: '' });
              _this.loadLastCode();
            } else {
              _this.props.dispatch(
                change('formProduto', 'Brand', resp.data.data.id)
              );
              _this.props.handleModal('brands');
              return;
            }
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });
            return this.props.history.push(constants.ROUTES.BRANDS);
          } else {
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });
            if (
              resp.data.message ===
              'Marca não cadastrada, pois está na blacklist'
            ) {
              toastr.error(
                `Não é permitido cadastrar um ${validateType} com essa descrição`,
                'O nome informado se encontra em uma lista negra que irá prejudicar a organização do seu cadastro. Por favor, insira outro nome e tente novamente.'
              );
              this.descriptionInput.focus();
              this.descriptionInput.focus();
              return;
            }
            return toastr.error(
              'Ocorreu algum problema',
              'Por favor, tente novamente'
            );
          }
        })
        .catch(async (e) => {
          console.log(e);
          await _this.setState({
            disabled: !_this.state.disabled,
            loadingSpin: !_this.state.loadingSpin,
          });
          return toastr.error(
            'Ocorreu algum problema',
            'Por favor, tente novamente'
          );
        });
    } else {
      await api
        .put(
          config.endpoint +
            `brands/${this.state.brandId}/${this.state.companyId}`,
          {
            Description: description.toUpperCase(),
            Type: self.type,
          }
        )
        .then(async (response) => {
          if (
            response.data.message ===
            'Marca não cadastrada, pois está na blacklist'
          ) {
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });

            toastr.error(
              `Não é permitido cadastrar um ${validateType} com essa descrição`,
              'O nome informado se encontra em uma lista negra que irá prejudicar a organização do seu cadastro. Por favor, insira outro nome e tente novamente.'
            );
            this.descriptionInput.focus();
            return;
          }
          if (response.status) {
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });
            setTimeout(() => {
              window.location.href = '/client/brands';
            }, 500);
            return toastr.success('Sucesso', `Marca atualizada!`);
          } else
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });
          return toastr.error(
            'Erro',
            'Ocorreu um erro durante a atualização. Por favor, repita o procedimento'
          );
        });
    }
  };

  render() {
    return (
      <Card
        content={
          <form
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              marginBottom: '10px',
              marginLeft: '-10px',
              marginTop: '15px',
            }}
          >
            <div className="cardComponent row bg-primary-border-color">
              <div className="div-card">
                <Button
                  id="btn-components"
                  className="bg-primary"
                  onClick={(e) => e.preventDefault()}
                >
                  <a id="btn-a">Dados da Marca</a>
                </Button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body">
                <Col xs={12} sm={3} md={2} lg={2}>
                  <label>Código:</label>
                  <input
                    disabled={true}
                    onChange={(event) => event.preventDefault()}
                    style={{ textAlign: 'right' }}
                    className="form-control foco-input"
                    name="code"
                    value={this.state.code}
                  />
                </Col>
                <Col xs={12} sm={9} md={7} lg={7}>
                  <label>
                    Descrição:<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    ref={(input) => {
                      this.descriptionInput = input;
                    }}
                    className="form-control foco-input"
                    onChange={(event) => {
                      if (event.target.value.startsWith(' ')) {
                        event.preventDefault();
                        event.target.value = event.target.value.slice(1);
                      }
                      this.handleChange(event);
                    }}
                    onKeyDown={(event) =>
                      event.key === 'Enter'
                        ? this.handleSubmit(event)
                        : this.handleChange(event)
                    }
                    name="description"
                    value={this.state.description}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <label>
                    Tipo:<span style={{ color: 'red' }}>*</span>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#428bca"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        toastr.warning(
                          'O tipo Montadora é utilizado para cadastrar veículos que não estão na tabela FIPE de Carro, Motos e Caminhões. O tipo Produto é utilizado para o cadastro de produto.'
                        )
                      }
                    />
                  </label>
                  <select
                    ref={(input) => {
                      this.type = input;
                    }}
                    onChange={(event) => this.handleChange(event)}
                    className="form-control foco-input"
                    name="type"
                    value={this.state.type}
                    disabled={
                      this.props.onlyManufacturers || this.props.onlyProducts
                        ? true
                        : false
                    }
                  >
                    <option value="product">Produto</option>
                    <option value="manufacturer">Montadora</option>
                  </select>
                </Col>
              </div>
            </div>
            <Button
              disabled={this.state.disabled}
              onClick={(event) => this.handleSubmit(event)}
              type="button"
              bsStyle="info"
              pullRight
              fill
            >
              <span
                className={
                  this.state.loadingSpin && 'fa fa-spinner fa-pulse fa-1x'
                }
                style={{ marginRight: '5px' }}
              ></span>
              {this.state.newBrand ? 'Salvar' : 'Atualizar'}
            </Button>

            <Button
              style={{ marginRight: '5px' }}
              disabled={this.state.disabled}
              onClick={() =>
                this.props.modal
                  ? this.props.handleModal('brands')
                  : this.props.history.push(constants.ROUTES.BRANDS)
              }
              type="button"
              bsStyle="danger"
              pullRight
              fill
            >
              Cancelar
            </Button>
          </form>
        }
      />
    );
  }
}

export default withRouter(FormMarca);

import React from 'react';

import { useBranding } from 'hooks/useBranding';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

import '../styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';

const Footer = () => {
  const { isPO360 } = useBranding();
  const { getBrandingLogo } = useThemeContext();

  return (
    <>
      <div className="income-statement__footer-rectangle bg-primary">
        <center>
          <img
            style={{
              width: '150px',
              height: '45px',
            }}
            src={getBrandingLogo('horizontal-white')}
            alt={getBrandingLogo('alt')}
          />
        </center>
      </div>
    </>
  );
};

export default Footer;

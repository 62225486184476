import { formatToBRL } from 'brazilian-values';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

const CampaignDispatchModal = ({ show, onHide, onSubmit }) => {
  const { LoadingComponent, setIsLoading, fetchLoading, isLoading } = useLoading();
  const { watch } = useFormContext();
  const Customer = watch('Customer');
  const { customersIds, totalValueCustomers } = Customer;
  const type = watch('type');
  const description = watch('description');

  const handleDispatchCampaign = async () => {
    setIsLoading(true);
    await fetchLoading(onSubmit);
    onHide();
    setIsLoading(false);
    toastr.success(
      'Parabéns!! Campanha Disparada com sucesso',
      'Seus clientes receberam sua campanha'
    );
  };
  return (
    <Modal show={show} onHide={onHide}>
      {!isLoading ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Disparo de campanha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Descrição: {description}</p>
            <p>Tipo: {type.label}</p>
            <p>Clientes: {customersIds.length}({formatToBRL(totalValueCustomers)})</p>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                gap: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <button className="button button-red button-h35" onClick={onHide}>
                Cancelar
              </button>
              <button
                className="button button-green button-h35"
                onClick={handleDispatchCampaign}
              >
                Confirmar
              </button>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body>
          <p>Aguarde o disparo da campanha</p>
          <LoadingComponent />
          <p style={{ marginTop: '10px', fontSize: '12px', color: '#666' }}>
            A campanha está sendo disparada. Não feche ou atualize a página.
          </p>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default CampaignDispatchModal;

import styled from 'styled-components';

export const CampaignFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FlexEndWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.red400};
  font-size: 12px;
`;

export const InlineWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: row;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 30px;
  margin-top: 20px;
  width: fit-content;
`;

export const Option = styled.button`
  font-size: 16px;
  background: ${({ theme }) => theme.colors.white};
  width: 100px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ selected, theme }) =>
    selected ? theme.colors.branding1000 : theme.colors.gray300};
  border: 0px;
  border-bottom: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.branding1000 : `transparent`};
  font-weight: ${({ selected }) => (selected ? `bold` : `regular`)};

  &:hover {
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
`;

export const DefaultCustomerText = styled.div`
  color: ${({ theme }) => theme.colors.gray300};
  font-size: 16px;
  display: flex;
  /* align-items: center; */
  margin-top: 3rem;
  justify-content: center;
  height: 50vh;
  width: 100%;
  font-weight: bold;
`;

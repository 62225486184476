import React, { useState, useEffect } from 'react';
import { change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { faSearchPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTable from 'react-table';

import {
  onlyNumbers,
  onlyNumbersAndDot,
  currency,
  CFOPMask,
  description,
} from 'client/components/ToNormalize/ToNormalize';
import {
  INVOICE_FINALITY,
  PRODUCT_CSOSN,
  PRODUCT_CST,
} from '../../../../utils/constants';
import { percentMask, CFOPsMask, currencyMask } from 'utils/masks';
import useProductTax from './utils';

import Button from 'client/components/CustomButton/CustomButton.jsx';
import RenderField from 'components/RenderField';
import AlertModal from 'components/AlertModal/AlertModal';
import TaxesByProductModal from './TaxesByProductModal';
import ItemsModal from './ItemsModal';

const Items = ({ loading }) => {
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [productIndex, setProductIndex] = useState(null);
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);
  const [isTaxesByProductModalOpen, setIsTaxesByProductModalOpen] =
    useState(false);

  const {
    NFeItems,
    saleId,
    isEqualUF,
    taxesByState,
    isInvoiceIssued,
    isInvoiceDenied,
    isInvoiceIssuedInContingency,
    isInvoiceCanceled,
    customerUF,
    subTotal,
    CFOPs,
    discountValue,
    productsTotal,
    finality,
    isCompanyTaxRegimeSimpleNational,
    grossWeight,
    netWeight,
  } = useSelector((state) => state.form.NFe.values);

  const dispatch = useDispatch();
  const productTax = useProductTax();

  useEffect(() => {
    calculateTotalProducts();
  }, [NFeItems]);

  useEffect(() => {
    if (NFeItems.length > 0) {
      subTotal - discountValue <= 0
        ? dispatch(change('NFe', 'discountValue', 0))
        : dispatch(change('NFe', 'productsTotal', subTotal - discountValue));
    }
  }, [discountValue]);

  function calculateTotalProducts() {
    const subTotal = !NFeItems.length
      ? 0
      : NFeItems.length === 1
      ? NFeItems[0].quantity * NFeItems[0].unitValue
      : NFeItems.map((item) => item.quantity * item.unitValue).reduce(
          (prev, curr) => prev + curr
        );

    const quantity = !NFeItems.length
      ? 0
      : NFeItems.length === 1
      ? NFeItems[0].quantity
      : NFeItems.map((item) =>
          item.quantity ? item.quantity * 1 : 0 * 1
        ).reduce((prev, curr) => prev + curr);

    const Weight = !NFeItems.length
      ? 0
      : NFeItems.length === 1
      ? NFeItems[0].Weight
        ? NFeItems[0].quantity
          ? NFeItems[0].Weight * NFeItems[0].quantity
          : 0
        : 0
      : NFeItems.map((item) =>
          item.Weight ? (item.quantity ? item.Weight * item.quantity : 0) : 0
        ).reduce((prev, curr) => prev + curr);

    dispatch(
      change('NFe', 'netWeight', netWeight ? netWeight : Weight ? Weight : 0)
    );
    dispatch(
      change(
        'NFe',
        'grossWeight',
        grossWeight ? grossWeight : Weight ? Weight : 0
      )
    );
    dispatch(change('NFe', 'volume', quantity));
    dispatch(change('NFe', 'subTotal', subTotal));
    dispatch(change('NFe', 'productsTotal', subTotal - discountValue));
  }

  function handleAppendItems(items) {
    const newNFeItems = [...NFeItems];

    for (var item of items) {
      const index = newNFeItems.findIndex(
        (child) => child.productId === item.productId
      );

      if (index !== -1) {
        newNFeItems[index].quantity++;
        newNFeItems[index] = productTax.getTaxInfo(newNFeItems[index]);
      } else {
        const { CFOP_InState_Code, CFOP_OutState_Code, CST, CSOSN, quantity } =
          item;

        const CFOP = isEqualUF ? CFOP_InState_Code : CFOP_OutState_Code;
        const CSTCSOSN = isCompanyTaxRegimeSimpleNational ? CSOSN : CST;
        const findIndex = taxesByState.findIndex(
          (tax) =>
            tax.uf === customerUF &&
            onlyNumbers(tax.codeCfop) === onlyNumbers(CFOP) &&
            tax.csosn === CSTCSOSN
        );

        let ICMS,
          ICMSRed,
          MVA,
          ICMSInternal,
          FCP,
          STPISValue,
          STCOFINSValue = 0;

        if (findIndex !== -1) {
          ICMS = taxesByState[findIndex].icms;
          ICMSRed = taxesByState[findIndex].icmsReduced;
          MVA = taxesByState[findIndex].mva;
          ICMSInternal = taxesByState[findIndex].internalIcms;
          FCP = taxesByState[findIndex].fcp;
        }

        const newItem = {
          ...item,
          CSTCSOSN: CSTCSOSN,
          STPISValue,
          STCOFINSValue,
          pedNumber: null,
          pedItemNumber: null,
          ICMS,
          MVA,
          ICMSInternal,
          FCP,
          CFOP: String(CFOP),
          quantity: quantity || 1,
        };

        const serializedProduct = productTax.getTaxInfo(newItem);
        newNFeItems.push(serializedProduct);
      }
    }

    dispatch(change('NFe', 'NFeItems', newNFeItems));
    setIsItemsModalOpen(false);
  }

  function handleOpenTaxesByProductModal(index) {
    setProductIndex(index);
    setIsTaxesByProductModalOpen(true);
  }

  function handleOpenRemoveItemModal(index) {
    setProductIndex(index);
    setIsRemoveItemModalOpen(true);
  }

  function handleRemoveItem() {
    setIsRemoveItemModalOpen(false);
    const newNFeItems = [...NFeItems].filter((_, i) => i !== productIndex);

    dispatch(change('NFe', 'NFeItems', newNFeItems));
  }

  function handleChangeQuantity(value, index) {
    const newNFeItems = [...NFeItems];
    const item = newNFeItems[index];
    item.quantity = value;

    const serializedItem = productTax.getTaxInfo(item);

    newNFeItems[index] = serializedItem;
    dispatch(change('NFe', 'NFeItems', newNFeItems));

    setTimeout(() => {
      document.getElementById(`NFeItems[${index}].quantity`).focus();
    }, 5);
  }

  const isDevolution = finality === INVOICE_FINALITY.DEVOLUTION;

  return (
    <div id="NFe-items">
      <Button
        type="button"
        className="btn btn-sucesso"
        bsStyle="info"
        fill
        onClick={() =>
          !isInvoiceDenied &&
          !isInvoiceIssued &&
          !isInvoiceIssuedInContingency &&
          !isInvoiceCanceled
            ? setIsItemsModalOpen(true)
            : ''
        }
        disabled={
          !!saleId ||
          isInvoiceDenied ||
          isInvoiceCanceled ||
          isInvoiceIssuedInContingency ||
          isInvoiceIssued
        }
      >
        + Adicionar Item
      </Button>

      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}
        data={NFeItems}
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 70,
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            headerClassName: 'text-left',
            width: 320,
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].description`}
                name={`NFeItems[${props.index}].description`}
                component={RenderField}
                normalize={(value) => description(value).toUpperCase()}
                disabled={!isDevolution}
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFeItems[${props.index}].description`)
                        .focus(),
                    1
                  )
                }
              />
            ),
          },
          {
            Header: 'QTD',
            accessor: 'quantity',
            headerClassName: 'text-left',
            width: 70,
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].quantity`}
                name={`NFeItems[${props.index}].quantity`}
                component={RenderField}
                normalize={onlyNumbersAndDot}
                min={0}
                onChange={(e) =>
                  handleChangeQuantity(e.target.value, props.index)
                }
                // onChange={() => document.getElementById(`NFeItems[${props.index}].quantity`).focus()}
                disabled={
                  !!saleId ||
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssued ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceDenied
                }
              />
            ),
          },
          isDevolution
            ? {
                Header: 'QTD Devol.',
                accessor: 'quantityDevolution',
                headerClassName: 'text-left',
                width: 100,
                Cell: (props) => (
                  <Field
                    id={`NFeItems[${props.index}].quantityDevolution`}
                    name={`NFeItems[${props.index}].quantityDevolution`}
                    component={RenderField}
                    normalize={onlyNumbersAndDot}
                    min={0}
                    onChange={() =>
                      setTimeout(
                        () =>
                          document
                            .getElementById(
                              `NFeItems[${props.index}].quantityDevolution`
                            )
                            .focus(),
                        1
                      )
                    }
                    disabled={
                      !!saleId ||
                      isInvoiceDenied ||
                      isInvoiceCanceled ||
                      isInvoiceIssued ||
                      isInvoiceIssuedInContingency ||
                      isInvoiceDenied
                    }
                  />
                ),
              }
            : {
                width: 0,
              },
          {
            Header: 'Preço Unit',
            accessor: 'unitValue',
            headerClassName: 'text-left',
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].unitValue`}
                name={`NFeItems[${props.index}].unitValue`}
                component={RenderField}
                {...currencyMask}
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFeItems[${props.index}].unitValue`)
                        .focus(),
                    5
                  )
                }
                disabled={
                  saleId ||
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssued ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceDenied
                }
              />
            ),
          },
          {
            Header: isCompanyTaxRegimeSimpleNational ? 'CSOSN' : 'CST',
            accessor: 'CSTCSOSN',
            width: 130,
            headerClassName: 'text-left',
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].CSTCSOSN`}
                name={`NFeItems[${props.index}].CSTCSOSN`}
                component={RenderField}
                as="select"
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFeItems[${props.index}].CSTCSOSN`)
                        .focus(),
                    5
                  )
                }
                disabled={
                  isInvoiceCanceled ||
                  isInvoiceIssued ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceDenied
                }
              >
                <option value="">Selecione</option>
                {isCompanyTaxRegimeSimpleNational
                  ? PRODUCT_CSOSN.map((CSOSN) => (
                      <option key={CSOSN} value={CSOSN}>
                        {CSOSN}
                      </option>
                    ))
                  : PRODUCT_CST.map((CST) => (
                      <option key={CST} value={CST}>
                        {CST}
                      </option>
                    ))}
              </Field>
            ),
          },
          {
            Header: '% ICMS',
            accessor: 'ICMS',
            headerClassName: 'text-left',
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].ICMS`}
                name={`NFeItems[${props.index}].ICMS`}
                component={RenderField}
                {...percentMask}
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFeItems[${props.index}].ICMS`)
                        .focus(),
                    5
                  )
                }
                disabled={
                  isInvoiceCanceled ||
                  isInvoiceIssued ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceDenied
                }
              />
            ),
          },
          {
            Header: 'CFOP',
            accessor: 'CFOP',
            headerClassName: 'text-left',
            Cell: (props) => (
              <>
                <Field
                  id={`NFeItems[${props.index}].CFOP`}
                  name={`NFeItems[${props.index}].CFOP`}
                  component={RenderField}
                  {...CFOPsMask}
                  list="CFOPSuggestions"
                  onChange={() =>
                    setTimeout(
                      () =>
                        document
                          .getElementById(`NFeItems[${props.index}].CFOP`)
                          .focus(),
                      5
                    )
                  }
                  disabled={
                    isInvoiceCanceled ||
                    isInvoiceIssued ||
                    isInvoiceIssuedInContingency ||
                    isInvoiceDenied
                  }
                />
                <datalist id="CFOPSuggestions">
                  {CFOPs.map((CFOP) => (
                    <option key={CFOP.id} value={CFOPMask(CFOP.CFOP)} />
                  ))}
                </datalist>
              </>
            ),
          },
          {
            Header: '% FCP',
            accessor: 'FCP',
            headerClassName: 'text-left',
            Cell: (props) => (
              <Field
                id={`NFeItems[${props.index}].FCP`}
                name={`NFeItems[${props.index}].FCP`}
                component={RenderField}
                {...percentMask}
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFeItems[${props.index}].FCP`)
                        .focus(),
                    5
                  )
                }
                disabled={
                  isInvoiceCanceled ||
                  isInvoiceIssued ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceDenied
                }
              />
            ),
          },
          {
            Header: '',
            accessor: 'Actions',
            headerClassName: 'text-left',
            className: 'NFe-items-table-actions',
            filterable: false,
            width: 60,
            Cell: (props) => (
              <>
                <FontAwesomeIcon
                  title="Tributos do Produto"
                  onClick={() => {
                    !isInvoiceDenied &&
                      !isInvoiceCanceled &&
                      handleOpenTaxesByProductModal(props.index);
                  }}
                  icon={faSearchPlus}
                />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  color="red"
                  onClick={() =>
                    !saleId &&
                    !isInvoiceDenied &&
                    !isInvoiceIssued &&
                    !isInvoiceIssuedInContingency &&
                    !isInvoiceCanceled &&
                    handleOpenRemoveItemModal(props.index)
                  }
                />
              </>
            ),
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'createdAt',
            desc: true,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText='Clique em "+ Adicionar Item" para adicionar itens na NF-e'
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div className="NFe-items-footer">
        <div>
          <strong>Subtotal: </strong>
          <span>{currency(subTotal || 0)}</span>
        </div>
        <div>
          <strong>Desconto: </strong>
          <Field
            name="discountValue"
            component={RenderField}
            {...currencyMask}
            disabled={
              saleId ||
              isInvoiceIssued ||
              isInvoiceCanceled ||
              isInvoiceIssuedInContingency
            }
          />
        </div>
        <div>
          <strong>Valor Total dos Produtos: </strong>{' '}
          <span>{currency(productsTotal || 0)}</span>
        </div>
      </div>

      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        message="Deseja excluir o item da listagem ?"
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />

      {isItemsModalOpen && (
        <ItemsModal
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAppendItems}
        />
      )}
      {isTaxesByProductModalOpen && (
        <TaxesByProductModal
          index={productIndex}
          onCancel={() => setIsTaxesByProductModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Items;

import React, { useState } from 'react';
import { useEffect } from 'react';
import { isBefore, isAfter, isEqual, startOfDay } from 'date-fns';
import { useAuth } from 'contexts/auth';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import PollsAnswerRepository from 'repositories/PollsAnswer';
import PollsRepository from 'repositories/Polls';
import ModalNPS from './ModalNPS';
import ModalNVS from './ModalNVS';
import ModalMHS from './ModalMHS';
import './index.css';
import { useThemeContext } from 'v2/contexts/themeContext';

export default function PollsModalMain({}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pollId, setPollId] = useState(null);
  const [activePollType, setActivePollType] = useState(null);
  const { userId, companyId } = useAuth();
  const { brandingId } = useThemeContext();

  function validatePollPeriod(poll) {
    const initialDate = startOfDay(
      getDateOnlyFromDate(new Date(poll.initialDate))
    );
    const finalDate = startOfDay(getDateOnlyFromDate(new Date(poll.finalDate)));

    const today = startOfDay(new Date());

    if (
      (isBefore(today, finalDate) && isAfter(today, initialDate)) ||
      isEqual(today, initialDate) ||
      isEqual(today, finalDate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function getCurrentPoll() {
    try {
      const activePoll = await PollsRepository.getActivePoll(brandingId);

      setActivePollType(activePoll.typeId);

      if (validatePollPeriod(activePoll)) {
        return activePoll;
      }

      return null;
    } catch (err) {
      console.log('Falha ao carregar pesquisa');
    }
  }

  async function verifyIfUserHasNotAnsweredPoll(pollId) {
    try {
      const userPollResponse = await PollsAnswerRepository.getAll({
        companyId,
        pollId,
      });

      if (userPollResponse.length !== 0) return false;
      return true;
    } catch (err) {
      console.log('Falha ao buscar a resposta do usuário');
    }
  }

  async function handleValidateAndOpenPollModal() {
    setIsLoading(true);
    try {
      const currentPoll = await getCurrentPoll();

      if (currentPoll) {
        const userNotAnsweredPoll = await verifyIfUserHasNotAnsweredPoll(
          currentPoll.id
        );

        if (userNotAnsweredPoll) {
          setPollId(currentPoll.id);
          setIsModalOpen(true);
        }
      }
    } catch (err) {
      console.log('Falha ao abrir o modal de pesquisa.', err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleValidateAndOpenPollModal();
  }, [companyId]);

  return (
    <>
      {activePollType === 1 && (
        <ModalNPS
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          userId={userId}
          pollId={pollId}
          companyId={companyId}
        />
      )}

      {activePollType === 2 && (
        <ModalNVS
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          userId={userId}
          pollId={pollId}
          companyId={companyId}
        />
      )}

      {activePollType === 3 && (
        <ModalMHS
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          userId={userId}
          pollId={pollId}
          companyId={companyId}
        />
      )}
    </>
  );
}

import api from '../services/api';

const sendOFXForReconciliation  = async (base64) => {
  try {
    const response = await api.post(`bank-reconciliation/verify-ofx`, {
      base64
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const BankConciliationRepository = {
  sendOFXForReconciliation,
};

export default BankConciliationRepository;

import styled from 'styled-components';

const containerColors = {
  white: 'white',
  gray: '#F7F6F6',
  indigo: '#1D7391',
  red: '#cb4154',
  blue: '#3D8AF7',
};
const cardColors = {
  white: 'rgb(214, 214, 214, 0.18)',
  gray: '#F7F6F6',
  indigo: 'rgba(201, 241, 253, 0.18)',
  red: 'rgba(255, 218, 216, 0.18)',
  blue: 'rgba(118, 172, 248)',
};
const textColors = {
  indigo: '#1D7391',
  red: '#cb4154',
  gray: '#606060',
};
const totalTextColors = {
  indigo: '#015668',
  red: '#CB4154',
};
const totalBackgroundColors = {
  indigo: 'rgba(1, 86, 104, 0.18)',
  red: 'rgba(203, 65, 84, 0.18)',
};

export const Container = styled.div`
  width: ${({ width }) => width || '219px'};
  height: 199px ${({ type }) => (type === 'dual' ? '199px' : '144px')};
  border-radius: 18px;
  background: ${({ background }) => containerColors[background]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const CardContainer = styled.div`
  width: ${({ width }) => width || '219px'};
  height: ${({ height }) => height || '75px'};
  border-radius: 18px;
  background: ${({ background }) => cardColors[background]};
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: ${({ color }) => textColors[color] || 'white'};
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

export const IndicatorItem = styled.div`
  width: 100%;
  font-size: ${({ fontSize }) => fontSize || '28px'};
  font-weight: 600;
  color: ${({ color }) => textColors[color] || 'white'};
  padding: 0;
  margin: 0;
`;

export const IndicatorTotalBadge = styled.span`
  width: ${({ widthTotal }) => widthTotal || '100px'};
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 14px;
  padding: 0.2rem 0.5rem;
  font-size: 12px;
  font-weight: 600;
  color: ${({ color }) => totalTextColors[color] || 'white'};
  background-color: ${({ background }) => totalBackgroundColors[background]};
`;

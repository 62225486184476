import styled from 'styled-components';

export const StyledButton = styled.button`
  border: none;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '2.5rem')};
  padding: ${(props) =>
    props.padding ? `${props.padding}` : props.theme.space[2]};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors[props.color || 'gray400']};
  border-radius: ${(props) => props.theme.radii.xs};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: flex;
  align-items: ${(props) => (props.justify ? props.justify : 'center')};
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  gap: ${(props) => (props.gap ? props.gap : 0)};

  border: 1px solid
    ${(props) =>
      props.borderColor ? props.theme.colors[props.borderColor] : ''};

  &:hover:not(:disabled) {
    filter: brightness(85%);
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

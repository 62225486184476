import React from 'react';
import {
  CardContainer,
  Container,
  IndicatorItem,
  IndicatorTotalBadge,
  Title,
  TitleContainer,
} from './IndicatorCard.styles';
import PropTypes from 'prop-types';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

export function IndicatorCard({
  type = 'single',
  variant,
  titleColor = 'white',
  textColor = 'white',
  showTotalBadge = false,
  title,
  value,
  total,
  loading = false,
  cardWidth = '219px',
  cardHeight = '199px',
  fontSize = '28px',
  widthTotal = '100px',
}) {
  return (
    <Container
      background={variant}
      type={type}
      width={cardWidth}
      height={cardHeight}
    >
      <CardContainer
        background={variant}
        width={cardWidth}
        height={cardHeight / 2.5}
      >
        {type === 'single' && (
          <>
            <TitleContainer>
              <Title color={titleColor}>{title}</Title>
              {showTotalBadge && (
                <IndicatorTotalBadge
                  color={textColor}
                  background={textColor}
                  widthTotal={widthTotal}
                >
                  Total: {total}
                </IndicatorTotalBadge>
              )}
            </TitleContainer>
            {loading ? (
              <LoadingSpinnerFullHeight />
            ) : (
              <IndicatorItem color={textColor} fontSize={fontSize}>
                {currency(value || 0)}*
              </IndicatorItem>
            )}
          </>
        )}
      </CardContainer>
    </Container>
  );
}

IndicatorCard.propTypes = {
  type: PropTypes.oneOf(['single', 'double']),
  variant: PropTypes.oneOf(['white', 'indigo', 'red', 'blue']).isRequired,
  titleColor: PropTypes.oneOf(['white', 'indigo', 'red', 'gray']),
  textColor: PropTypes.oneOf(['white', 'indigo', 'red', 'gray']),
  showTotalBadge: PropTypes.bool,
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string,
  value: PropTypes.number.isRequired,
  secondaryValue: PropTypes.number,
  total: PropTypes.string,
  secondaryTotal: PropTypes.string,
  cardWidth: PropTypes.string,
  cardHeight: PropTypes.string,
  fontSize: PropTypes.string,
  widthTotal: PropTypes.string,
};

IndicatorCard.defaultProps = {
  type: 'single',
  titleColor: 'white',
  textColor: 'white',
  showTotalBadge: false,
  secondaryTitle: '',
  secondaryValue: 0,
  total: '',
  secondaryTotal: '',
  cardWidth: '219px',
  cardHeight: '199px',
  fontSize: '28px',
  widthTotal: '100px',
};

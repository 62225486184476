import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import superlogicaRepository from 'repositories/Superlogica';
import { format } from 'date-fns';
import {
  BankSlipAlertButtonStyled,
  DropdownMenu,
  DropdownMenuItem,
} from './BankSlipAlertButton.styles';
import { SidebarSubMenuItem } from 'v2/components/layout/Sidebar/components/SidebarSubMenuDegree/SidebarSubMenuDegree.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';

export const BankSlipAlertButton = () => {
  const { companyId } = useAuth();
  const [bankSlipList, setBankSlipList] = useState([]);

  useEffect(() => {
    if (!!companyId) {
      handleGetBankSlip();
    }
  }, [companyId]);

  const handleGetBankSlip = async () => {
    try {
      const data = await superlogicaRepository.getBankSlipSuperLogica(
        companyId
      );
      setBankSlipList(data.data.Boletos);
    } catch (err) {
      console.log(err);
    }
  };

  const getLabel = () => {
    if (bankSlipList.length === 0) {
      return 'Sem boletos pendentes';
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    for (const bill of bankSlipList) {
      const [dia, mes, ano] = bill.Vencimento.split('/'); // Ajustar a ordem de acordo com o formato atual
      const dateFormated = `${ano}-${mes}-${dia}`; // Formato reconhecido pelo Date: YYYY-MM-DD
      const data = new Date(dateFormated);
      data.setHours(0, 0, 0, 0);

      if (data < today) {
        return 'Existem boletos em atraso';
      }
    }

    return 'Existem boletos a vencer';
  };

  const handleRedirectToBankSlip = async (e) => {
    window.open(e, '_blank');
  };

  function formatedDate(dataString) {
    const [ano, dia, mes] = dataString.split('/'); // Ajustar a ordem de acordo com o formato atual
    const dateFormated = `${ano}-${mes}-${dia}`; // Formato reconhecido pelo Date: YYYY-MM-DD
    const data = new Date(dateFormated);
    return format(data, 'dd/MM/yyyy');
  }

  return (
    <>
      <BankSlipAlertButtonStyled hasBankSlips={getLabel()}>
        {getLabel()}
        {bankSlipList.length > 0 && (
          <DropdownMenu>
            {bankSlipList.map((item) => {
              return (
                <SidebarSubMenuItem
                  active={true}
                  onClick={() => handleRedirectToBankSlip(item.LinkBoleto)}
                  disabledStyle={false}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '0 5px',
                    padding: '0 5px',
                  }}
                >
                  <FontAwesomeIcon icon={faMoneyCheckAlt} />
                  <DropdownMenuItem>
                    {`${formatedDate(
                      item.Vencimento
                    )} - Clique aqui para visualizar.`}
                  </DropdownMenuItem>
                </SidebarSubMenuItem>
              );
            })}
          </DropdownMenu>
        )}
      </BankSlipAlertButtonStyled>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { format, isValid } from 'date-fns';
import PDFButton from 'client/components/PDFButton';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import CostCenterRepository from 'v2/repositories/CostCenterRepository';

export default function CostCenterMain() {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeOfDate, setTypeOfDate] = useState('createdAt');
  const [isReportReady, setIsReportReady] = useState(false);
  const [showAccountPlan, setShowAccountPlan] = useState(true);

  useEffect(() => {
    if (initialDate && finalDate) {
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [initialDate, finalDate]);

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate)) ||
      !typeOfDate
    ) {
      toastr.warning(
        'Data inválida.',
        'Insira um período válido e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setIsReportReady(false);
    try {
      const data = await CostCenterRepository.getCostCenterList({
        companyId,
        initialDate,
        finalDate,
        typeOfDate,
        showAccountPlan,
      });

      if (data?.report.length) {
        handlePrint();
      } else {
        return toastr.warning('Sem informações', 'Nenhum dado a ser exibido.');
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a busca. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsReportReady(true);
    }
  };

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/cost-center?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&showAccountPlan=${showAccountPlan}&typeOfDate=${typeOfDate}`,
      '_blank'
    );
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel fontSize="12px" fontWeight="bold">
              Tipo de Data:
            </InputLabel>
            <select
              className="form-control foco-input select-families fontSize-12"
              value={typeOfDate}
              onChange={(e) => setTypeOfDate(e.target.value)}
            >
              <option value="createdAt">Lançamento</option>
              <option value="lowDate">Baixa de Título</option>
            </select>
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel fontSize="12px" fontWeight="bold">
              Data Inicial:
            </InputLabel>
            <input
              type="date"
              className="form-control foco-input fontSize-12"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel fontSize="12px" fontWeight="bold">
              Data Final:
            </InputLabel>
            <input
              type="date"
              className="form-control foco-input fontSize-12"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-035">
          <InputContainer variant="column">
            <InputLabel fontSize="12px" fontWeight="bold">
              Exibir plano de contas?
            </InputLabel>
            <select
              className="form-control foco-input select-families fontSize-12"
              value={showAccountPlan}
              onChange={(e) => setShowAccountPlan(e.target.value)}
            >
              <option value={true}>Exibir</option>
              <option value={false}>Não exibir</option>
            </select>
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <PDFButton
            isLoading={loading}
            disabled={!isReportReady}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

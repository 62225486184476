import api from '../services/api';

const getThemeByLocation = async (location) => {
  const response = await api.get('branding-theme/', {
    params: {
      location,
    },
  });
  return response.data;
};

const BrandingThemeRepository = {
  getThemeByLocation,
};

export default BrandingThemeRepository;

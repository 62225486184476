import { PLANS } from 'v2/utils/constants';

export const currentBranding = process.env.REACT_APP_BRANDING || 'OSD';

const getCurrentBrandingId = () => {
  switch (currentBranding) {
    case 'OSD':
      return 1;
    case 'WM':
      return 2;
    case 'TECNOMOTOR':
      return 3;
    case 'BARROS':
      return 4;
    case 'PO360':
      return 5;
    default:
      return 1;
  }
};

export const currentBrandingId = getCurrentBrandingId();

export const getbrandingNameById = (brandingId) => {
  switch (brandingId) {
    case 1:
      return 'OS Digital';
    case 2:
      return 'WorkMotor';
    case 3:
      return 'Tecnomotor';
    case 4:
      return 'Barros';
    case 5:
      return 'PO360';
    default:
      return '';
  }
};

export const currentBrandingName = (() => {
  switch (currentBranding) {
    case 'OSD':
      return 'OS Digital';
    case 'WM':
      return 'WorkMotor';
    case 'TECNOMOTOR':
      return 'Tecnomotor';
    case 'BARROS':
      return 'Barros';
    case 'PO360':
      return 'PO360';
  }
})();

export const getPlansByBrandingId = (brandingId) => {
  return PLANS.filter((plan) =>
    brandingId ? plan.brandingId === brandingId : true
  );
};

export const getPlansOptionsByBrandingId = (brandingId, showAllOption) => {
  const plans = getPlansByBrandingId(brandingId);

  let serializedOptions = plans.map((plan) => ({
    label: plan.description,
    value: plan.id,
  }));

  if (showAllOption) {
    serializedOptions.unshift({
      label: 'Todos',
      value: '*',
    });
  }

  return serializedOptions;
};

import { currency } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import { Text } from 'v2/components/Text';
const RefusedItemsModal = ({ show, onHide, data }) => {
  return (
    <Modal show={show} dialogClassName={'modal-70w'}>
      <Modal.Header closeButton style={{ padding: '10px 15px' }}>
        <Modal.Title>
          <Text fontWeight="bold" size="2x">
            Itens Recusados pelo Cliente
          </Text>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactTable
          style={{
            width: '100%',
            fontWeight: 'bold',
            textAlign: 'center',
            height: '250px',
            fontSize: '12px',
          }}
          data={data}
          columns={[
            {
              Header: 'Código Interno',
              accessor: 'Code',
              headerClassName: 'text-left',
              width: 100,
              Cell: (props) => <div>{props.original.ItemCode}</div>,
            },
            {
              Header: 'Ref. Fabricante',
              accessor: 'Manufacturer_Ref',
              headerClassName: 'text-left',
              width: 120,
              Cell: (props) => <div>{props.original.manufacturerRef}</div>,
            },
            {
              Header: 'Tipo',
              accessor: 'Type',
              headerClassName: 'text-left',
              width: 50,
              Cell: (props) => (
                <div>
                  <strong>{props.original.type}</strong>
                </div>
              ),
            },
            {
              Header: 'Descrição',
              accessor: 'Description',
              width: '100%',
              headerClassName: 'text-left',
              Cell: (props) => <span>{props.original.description}</span>,
            },

            {
              Header: 'Qtd',
              accessor: 'Quantity',
              headerClassName: 'text-left',
              width: 115,
              Cell: (props) => <span>{props.original.quantity}</span>,
            },

            {
              Header: 'Valor',
              accessor: 'Amount',
              headerClassName: 'text-left',
              width: 90,
              Cell: (props) => (
                <div>
                  <span>{currency(props.original.amount)}</span>
                </div>
              ),
            },
            {
              Header: 'Data de Recusa',
              accessor: 'refusalDate',
              headerClassName: 'text-left',
              width: 150,
              Cell: (props) => {
                return (
                  <span>
                    {format(
                      new Date(props.original.refusalDate),
                      'dd/MM/yyyy HH:mm:ss'
                    )}
                  </span>
                );
              },
            },
          ]}
          defaultPageSize={5}
          // loading={loading}
          showPagination={true}
          sortable={false}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            gap: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <button className="button button-red button-h35" onClick={onHide}>
            Voltar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RefusedItemsModal;

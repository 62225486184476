import React from 'react';
import { reduxForm } from 'redux-form';
import MainData from './MainData';
import CollapsibleSection from 'components/CollapsibleSection';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import Button from 'client/components/CustomButton/CustomButton';

const FormVehicle = ({
  onCancel,
  handleSubmit,
  vehicleId,
  loading,
  selectedModel,
  setSelectedModel,
  isViewingData,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <CollapsibleSection
        isExpanded={true}
        title="Dados do Veículo/Obj. de Manutenção"
        handleExpand={() => null}
      >
        <MainData
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          isViewingData={isViewingData}
        />
      </CollapsibleSection>
      {!!isViewingData ? (
        <div
          className="form-submit-buttons"
          style={{
            height: '35px',
            boxSizing: 'border-box',
          }}
        >
          <Button bsStyle="danger" fill onClick={onCancel}>
            Voltar
          </Button>
        </div>
      ) : (
        <FormSubmitButtons
          onCancel={onCancel}
          hasId={vehicleId}
          onSubmit={handleSubmit}
          loading={loading}
        />
      )}
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'vehicle',
})(FormVehicle);

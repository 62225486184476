import React, { useState, useEffect, useCallback } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import { useAuth } from 'contexts/auth';
import { listSalesWithRefusedItems } from 'v2/repositories/SalesItemsRefusedRepository';

const defaultStyles = (height, width) => ({
  control: (base) => ({
    ...base,
    width: width,
    height: height,
    minHeight: height,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: width,
    height: height,
    padding: '0 0 0 10px',
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    height: height,
  }),
});

const defaultPlaceholder =
  'Pesquisa por CPF/CNPJ ou Nome, Razão Social ou Nome Fantasia';

export default function SelectSales({
  onChange,
  value,
  styles,
  status = 'Ativo',
  placeholder = defaultPlaceholder,
  showDefaultLabel = true,
  showDetails = true,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  height = '40px',
  width,
  initialDate,
  finalDate,
  saleStatus = 'all',
  typeOfDate = 'refusedItems',
  ...rest
}) {
  const { companyId } = useAuth();
  const [dateChangeCounter, setDateChangeCounter] = useState(0);

  useEffect(() => {
    setDateChangeCounter((prev) => prev + 1);
  }, [initialDate, finalDate]);

  function handleLabel(sale) {
    switch (true) {
      case showDefaultLabel:
        return `${sale.saleCode} - ${
          sale.customerCompanyName || sale.customerTradingName
        } ${
          sale.vehicleLicensePlate
            ? `- ${sale.vehicleLicensePlate}/${sale.vehicleModel}`
            : ''
        }`;
      default:
        return sale.label;
    }
  }

  const handleLoadSales = async (search, loadedOptions, additional) => {
    const page = additional?.page || 1;
    try {
      const res = await listSalesWithRefusedItems({
        company_id: companyId,
        page,
        limit: 10,
        query: search,
        initialDate,
        finalDate,
        saleStatus,
        typeOfDate,
      });

      const salesOptions = res.data.map((sale) => ({
        label: handleLabel(sale),
        value: {
          ...sale,
          label: handleLabel(sale),
        },
      }));

      return {
        options: salesOptions,
        hasMore: page * 10 < res.sales_total,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <AsyncPaginate
      isDisabled={disabled}
      key={`${dateChangeCounter}-${initialDate}-${finalDate}`}
      debounceTimeout={500}
      loadOptions={handleLoadSales}
      defaultValue={null}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      additional={{
        page: 1,
      }}
      noOptionsMessage={() => 'Sem opções'}
      loadingMessage={() => 'Carregando...'}
      styles={styles || defaultStyles(height, width)}
      {...rest}
    />
  );
}

import { milhar } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import { Field } from 'redux-form';
import { yearMask } from 'utils/masks';
import Select from 'react-select';

import '../styles.css';
import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';

const TractorExcavatorForm = ({
  RenderField,
  isVehicleByPlate,
  Type,
  brands,
  Editing,
  vehiclesOptions,
  selectedModel,
  modelInput,
  loadingBrandModels,
  handleModelChange,
  isViewingData,
}) => {
  const vehicleOptionsFiltered = vehiclesOptions.filter(
    (item) => item.type === 'Trator/Escavadeira'
  );

  return (
    <>
      <div
        className="form-new-vehicle__simple-row"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <span>
          Placa/Identificação: <span style={{ color: 'red' }}>*</span>
        </span>
        <Field
          disabled={isViewingData}
          name="serialIdentification"
          component={RenderField}
          maxLength="30"
          normalize={onlyNumbersLetters}
          style={{width: '270px', height: '35px'}}
        />
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div>
          <span>
            Marca: <span style={{ color: 'red' }}>*</span>{' '}
          </span>

          <Field
            name="Brand"
            required
            as="select"
            component={RenderField}
            disabled={(isVehicleByPlate && Type == 1) || isViewingData}
          >
            <option value="">Selecione</option>
            {brands?.map((child) => (
              <option key={child.Brand} value={child.Brand}>
                {child.Brand.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <span>
            Modelo: <span style={{ color: 'red' }}>*</span>
          </span>
          {!Editing || (Editing && vehiclesOptions?.length !== 0) ? (
            <Select
              options={vehicleOptionsFiltered}
              value={selectedModel}
              placeholder={
                vehicleOptionsFiltered?.length === 0
                  ? 'Nenhum modelo encontrado'
                  : 'Selecione'
              }
              openMenuOnFocus
              noOptionsMessage={() => 'Nenhum modelo encontrado'}
              ref={modelInput}
              isDisabled={loadingBrandModels || isViewingData}
              onChange={(model) => handleModelChange(model)}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
            />
          ) : (
            ''
          )}
        </div>

        <div style={{ width: '80px' }}>
          <span htmlFor="year">
            Ano <strong>Fab.</strong>:<span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            disabled={isViewingData}
            name="Year_Manufacture"
            component={RenderField}
            {...yearMask}
          />
        </div>

        <div style={{ width: '80px' }}>
          <span htmlFor="modelYear">
            Ano <strong>Mod.</strong>:<span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            name="Year_Model"
            component={RenderField}
            {...yearMask}
            disabled={isViewingData}
          />
        </div>
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div style={{ width: '120px' }}>
          <span>KM:</span>
          <Field
            disabled={isViewingData}
            name="Kilometers"
            component={RenderField}
            normalize={(value) => milhar(onlyNumbers(value))}
            maxLength={10}
          />
        </div>

        <div>
          <span>Chassi/Série:</span>
          <Field
            disabled={isViewingData}
            name="NChassi"
            component={RenderField}
            normalize={(value) => onlyNumbersLetters(value.toUpperCase())}
            maxLength={30}
          />
        </div>

        <div style={{ width: 150 }}>
          <span>Cor:</span>

          <Field
            disabled={isViewingData}
            name="Color"
            as="text"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>
      </div>
    </>
  );
};

export default TractorExcavatorForm;

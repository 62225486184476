import styled from 'styled-components';

export const BankConciliationInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  small {
    margin-top: 1rem;
    color: #d9534f;
    font-size: 12px;
    min-width: 150px;
  }
`;
export const BankConciliationInfoSelect = styled.div``;

export const BankConciliationUploadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 20px;
    color: #1d7391;
    text-align: center;
  }
`;

export const BankConciliationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

import React, { useEffect, useState } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Card from './components/Card';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';
import { AppLoading } from 'client/routes/index.routes';
import incomeStatementRepository from 'repositories/IncomeStatement';
import AppError from 'components/AppError';
import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';

const IncomeStatement = ({ location }) => {
  const { setDocumentTitle } = useThemeContext();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [report, setReport] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
    setDocumentTitle('DRE');
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyIdHash = searchParams.get('companyId');
      let companyId;
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId');
      }

      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'));
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));

      return loadReport(companyId, { initialDate, finalDate });
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (companyId, params) => {
    setLoading(true);
    try {
      const data = await incomeStatementRepository.getAll(companyId, params);
      setReport(data);
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <>
      <Header />
      <Card report={report} initialDate={initialDate} finalDate={finalDate} />
      <Footer />
    </>
  );
};

export default IncomeStatement;

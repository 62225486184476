import api from '../services/api';

export const listBanksConciliations = async (params) => {
  try {
    const res = await api.get('/bank-conciliation', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const BankConciliationRepository = {
  listBanksConciliations,
};

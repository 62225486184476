import React from 'react';

import { Header } from './components/Header';
import { CRMContainer } from './CRM.styles';
import { MainPage } from './sections/MainPage';
import { Campaigns } from './sections/Campaigns';

import useCRMStore from './store/crm-store';

export function CRM() {
  const { selectedOption } = useCRMStore();

  return (
    <CRMContainer>
      <Header />
      {selectedOption === 'Página Principal' && <MainPage />}
      {selectedOption === 'Campanhas' && <Campaigns />}
    </CRMContainer>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileVideo, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const renderDragMessage = (isDragActive, isDragReject, label) => {
  if (!isDragActive) return <span>{label}</span>;

  if (isDragReject)
    return <span style={{ color: 'red' }}>O arquivo não é um vídeo</span>;

  return <span style={{ color: 'green' }}>Importar vídeo</span>;
};

const UploadContainer = ({
  getRootProps,
  getInputProps,
  isDragActive,
  isDragReject,
  video,
  onRemove,
  label,
  onClick,
  disabled,
}) => {
  const handleClick = (event) => {
    if (onClick && !onClick(event)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      {!video?.preview ? (
        <div
          {...getRootProps()}
          className={`container-include-image ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          <FontAwesomeIcon icon={faFileVideo} />
          <br />
          {renderDragMessage(isDragActive, isDragReject, label)}

          <input {...getInputProps()} onClick={handleClick} />
        </div>
      ) : (
        <div>
          <div>
            <video src={video.preview} controls width="100%" height="280px" />
          </div>

          <center>
            <button {...getRootProps()} type="button" className="btn-link">
              <input {...getInputProps()} />
              Alterar
            </button>
          </center>
        </div>
      )}
    </>
  );
};

export default UploadContainer;

import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import companyRepository from 'repositories/Companies';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { isCNPJ } from 'brazilian-values';

const defaultStyles = (height, width) => ({
  control: (base) => ({
    ...base,
    width: width,
    height: height,
    minHeight: height,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: width,
    height: height,
    padding: '0 0 0 10px',
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    height: height,
  }),
});

const defaultPlaceholder = 'Pesquisar por CNPJ, Razão Social ou Nome Fantasia';

export default function SelectPaidCompanies({
  onChange,
  value,
  styles,
  placeholder = defaultPlaceholder,
  reloadTrigger,
  isLoading,
  setReloadTrigger,
  disabled = false,
  height = '35px',
  width,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  function handleLabel(company) {
    return `${company.Company_Name} - ${cpfOrCnpjMask(company.Cpf_Cnpj)}`;
  }

  async function handleLoadCompanies(search, loadedOptions, { page }) {
    const serilizedQuery = isCNPJ(search) ? search.replace(/\D/g, '') : search;
    setLoading(true);
    try {
      const res = await companyRepository.getPaginatedCompaniesWithPaidPlan({
        page,
        limit: 10,
        query: serilizedQuery,
      });

      const companiesOptions = res.rows.map((company) => ({
        label: handleLabel(company),
        value: {
          ...company,
          label: handleLabel(company),
        },
      }));

      return {
        options: companiesOptions,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    } finally {
      setLoading(false);
    }
  }

  return (
    <AsyncPaginate
      isDisabled={disabled}
      isLoading={isLoading || loading}
      debounceTimeout={500}
      loadOptions={handleLoadCompanies}
      defaultValue={null}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      additional={{
        page: 1,
      }}
      noOptionsMessage={() => 'Sem opções'}
      loadingMessage={() => 'Carregando...'}
      styles={styles || defaultStyles(height, width)}
      {...rest}
    />
  );
}

import create from 'zustand';

const useCRMStore = create((set) => ({
  selectedOption: 'Página Principal',
  setSelectedOption: (newOption) =>
    set({ selectedOption: newOption, openNewCampaignForm: false }),
  openNewCampaignForm: false,
  setOpenNewCampaignForm: (newOption) =>
    set({ openNewCampaignForm: newOption }),
  selectedCard: null,
  setSelectedCard: (newCard) => set({ selectedCard: newCard }),
}));

export default useCRMStore;

import React from 'react';
import {
  BackgroundWrapper,
  CardWrapper,
  FlexEndWrapper,
} from '../../../MainPage/MainPage.styles';

import { IndicatorCard } from '../../../../components/IndicatorCard';
import { useState } from 'react';

const HeaderMenu = ({ headerData }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',

        marginTop: '10px',
      }}
    >
      <CardWrapper style={{ flexDirection: 'column', gap: '0px' }}>
        <>
          <BackgroundWrapper style={{ border: 'none' }}>
            <IndicatorCard
              title="Todos"
              value={headerData?.totalValue || 0}
              showTotalBadge
              total={headerData?.totalCount || 0}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="red"
              loading={loading}
              cardWidth="219px"
              cardHeight="66px"
              fontSize="22px"
              widthTotal="110px"
            />
            <IndicatorCard
              title="Celular Completo"
              value={headerData?.correctPhoneTotalValue || 0}
              showTotalBadge
              total={headerData?.correctPhoneTotalCount || 0}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={loading}
              cardWidth="219px"
              cardHeight="66px"
              fontSize="22px"
              widthTotal="110px"
            />

            <IndicatorCard
              title="Celular Incompleto"
              value={headerData?.incorrectPhoneTotalValue || 0}
              showTotalBadge
              total={headerData?.incorrectPhoneTotalCount || 0}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={loading}
              cardWidth="219px"
              cardHeight="66px"
              fontSize="22px"
              widthTotal="110px"
            />
          </BackgroundWrapper>
          <FlexEndWrapper>
            * Valores Aproximados de compras pelos clientes
          </FlexEndWrapper>
        </>
      </CardWrapper>
    </div>
  );
};

export default HeaderMenu;

import React, { useState, useEffect } from 'react';

import { ExternalIndicatorsContent } from './components/ExternalIndicatorsContent';

import companyRepository from '../../../repositories/Companies';

import OsDigitalLogo from 'assets/img/os-digital-letra-branca.png';
import logoWM from 'assets/img/workmotor/logo-horizontal-text-white.png';

import './index.css';
import { toastr } from 'react-redux-toastr';
import { useBranding } from 'hooks/useBranding';
import { useThemeContext } from 'v2/contexts/themeContext';

export const ExternalIndicators = ({ history, match, location }) => {
  const [companyId, setCompanyId] = useState(null);

  const [indicators, setIndicators] = useState({});

  useEffect(() => {
    const url = location?.pathname?.split('/');
    setCompanyId(url[url.length - 1]);
  }, []);

  useEffect(() => {
    if (!!companyId) loadIndicators();
  }, [companyId]);

  const loadIndicators = async () => {
    try {
      if (companyId === null) return;

      const indicatorsData = await companyRepository.getIndicatorsByCompanyId(
        companyId
      );

      setIndicators(indicatorsData);
    } catch (error) {
      toastr.error(
        'Ocorreu um erro ao carregar indicadores',
        error?.response?.data?.message
      );
      setIndicators({
        averageTicketSale: 0,
        averageServiceOrderTicket: 0,
        companyId: 0,
        pedingAmountToReceiveToday: 0,
        pedingAmountToReceiveLate: 0,
        pedingAmountToPayToday: 0,
        pedingAmountToPayLate: 0,
        countBudgetNewsToday: 0,
        countSaleNewsToday: 0,
        countPDVNewsToday: 0,
        countOSNewsToday: 0,
        schedulingForToday: 0,
      });
    }
  };

  return (
    <div className="external-indicators">
      <HeaderExternalIndicators />
      <ExternalIndicatorsContent indicators={indicators} history={history} />
      <ExternalIndicatorsOSLink history={history} />
      <FooterExternalIndicators />
    </div>
  );
};

const HeaderExternalIndicators = () => {
  return <div className="external-indicators__header bg-primary"></div>;
};

const ExternalIndicatorsOSLink = ({ history }) => {
  const { brandingName } = useThemeContext();

  return (
    <>
      <div className={`external-indicators__os-link--web`}>
        <a
          className="text-primary"
          onClick={() => history?.push('/client-login')}
        >
          Para mais informações, acesse o {brandingName}
        </a>
      </div>

      <div className="external-indicators__os-link--mobile">
        <a className="text-primary">
          Para mais informações, acesse o {brandingName}
        </a>
      </div>
    </>
  );
};

const FooterExternalIndicators = () => {
  const { getBrandingLogo } = useThemeContext();

  return (
    <div className={`external-indicators__footer bg-primary `}>
      <img
        className="external-indicator__footer__os-dig-logo bg-primary"
        src={getBrandingLogo()}
        alt={getBrandingLogo('alt')}
      />
    </div>
  );
};

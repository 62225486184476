import React from 'react';
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import {
  cepMask,
  cnpjMask,
  percentage,
  cpfMask,
  milhar,
  phoneMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import fontRobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import fontRobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import fontRobotoThin from '../../../assets/fonts/Roboto-Thin.ttf';
import fontRobotoItalic from '../../../assets/fonts/Roboto-Italic.ttf';
import { getDefaultText } from 'v2/helpers/sheetHelpers';

const ItemsHeader = ({ children, isProductionOrder, style = {}, ...rest }) => (
  <View
    style={{
      flexDirection: 'row',
      height: 15,
      fontWeight: 'bold',
      marginBottom: 5,
      width: '100%',
    }}
  >
    <View
      style={{
        width: '10%',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <Text>Código</Text>
    </View>
    <View
      style={{
        width: '55%',
        textAlign: 'start',
        justifyContent: 'center',
      }}
    >
      <Text>Descrição</Text>
    </View>
    <View
      style={{
        width: '5%',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <Text>Qtd</Text>
    </View>
    {!isProductionOrder ? (
      <>
        <View
          style={{
            width: '10%',
            textAlign: 'start',
            justifyContent: 'center',
          }}
        >
          <Text>Valor Unit.</Text>
        </View>

        <View
          style={{
            width: '10%',
            textAlign: 'start',
            justifyContent: 'center',
          }}
        >
          <Text>Desc. Unit</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'start',
            justifyContent: 'center',
          }}
        >
          <Text>Valor Total</Text>
        </View>
      </>
    ) : (
      <View></View>
    )}
  </View>
);

function splitDescription(description) {
  if (description.length <= 100) {
    return [description, ''];
  }
  return [description.substring(0, 100), description.substring(100)];
}

const ItemRow = ({
  code,
  description,
  quantity,
  unitPrice,
  discount,
  total,
  isProductionOrder,
}) => {
  return (
    <View style={{ flexDirection: 'row', width: '100%' }}>
      <View
        style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{code}</Text>
      </View>
      <View
        style={{
          width: '55%',
          textAlign: 'start',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.descriptionText}>
          {splitDescription(description)}
        </Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{quantity}</Text>
      </View>
      {!isProductionOrder ? (
        <>
          <View
            style={{
              width: '10%',
              textAlign: 'start',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.span}>{currency(unitPrice)}</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'start',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.span}>{percentage(discount)}</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'start',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.span}>{currency(total)}</Text>
          </View>
        </>
      ) : (
        <View></View>
      )}
    </View>
  );
};

const Card = ({ children, style, ...rest }) => (
  <View style={[styles.cardBorder, style]} {...rest}>
    {children}
  </View>
);

function translateDate(date) {
  const engDate = format(date, 'ccc');

  let portugueseDay;

  switch (engDate) {
    case 'Sun':
      portugueseDay = 'Domingo';
      break;
    case 'Mon':
      portugueseDay = 'Segunda-feira';
      break;
    case 'Tue':
      portugueseDay = 'Terça-feira';
      break;
    case 'Wed':
      portugueseDay = 'Quarta-feira';
      break;
    case 'Thu':
      portugueseDay = 'Quinta-feira';
      break;
    case 'Fri':
      portugueseDay = 'Sexta-feira';
      break;
    case 'Sat':
      portugueseDay = 'Sábado';
      break;
  }

  return portugueseDay;
}

const SalePrintingSheet1 = ({
  sale,
  quickQuote,
  config,
  isProductionOrder,
  showPaymentSuggestions,
  companyBranch,
}) => {
  const company = sale.Company;
  const cpfCnpj =
    company.Cpf_Cnpj.length <= 11
      ? cpfMask(company.Cpf_Cnpj)
      : cnpjMask(company.Cpf_Cnpj);
  const isJuridica = company.Type === 'Jurídica';
  const companyAddress = company.Address[0];
  const companyPhones = company.Phones[0];
  const address = !companyAddress
    ? ''
    : `${companyAddress.Address}, ${companyAddress.Address_Number}, ${companyAddress.Neighborhood} - ${companyAddress.City} /${companyAddress.State}`;
  const zipcode = !companyAddress ? '' : `${cepMask(companyAddress.Zipcode)}`;
  const phone = !companyPhones ? '' : phoneMask(companyPhones.Number_Phone1);
  const salesType = sale.SalesType.id === 3 ? 'OS' : sale.SalesType.Description;
  const saleDate = format(
    getDateOnlyFromDate(new Date(sale.Date)),
    'dd/MM/yyyy'
  );

  const products = sale.SalesItems.filter((item) => item.Type === 'Produto');
  const services = sale.SalesItems.filter((item) => item.Type === 'Serviço');
  let scheduling;
  sale.SalesScheduling?.startScheduling
    ? (scheduling = new Date(sale.SalesScheduling?.startScheduling))
    : (scheduling = null);
  const customer = {
    name:
      sale.Customer.Type === 'Fisica'
        ? sale.Customer.Company_Name
        : sale.Customer.Trading_Name || sale.Customer.Company_Name,
    phone: !sale.Customer.Phones[0]
      ? ''
      : phoneMask(sale.Customer.Phones[0].Number_Phone2),
    cpfCnpj:
      sale.Customer.Cpf_Cnpj.length <= 11
        ? cpfMask(sale.Customer.Cpf_Cnpj)
        : cnpjMask(sale.Customer.Cpf_Cnpj),
    street: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].Address,
    neighborhood: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Neighborhood,
    zipCode: !sale.Customer.Address[0]
      ? ''
      : cepMask(sale.Customer.Address[0].Zipcode),
    city: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].City,
    UF: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].State,
    complement: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Complement,
    referencePoint: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Reference_Point,
    address_number: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Address_Number,
  };

  const vehicle = {
    description: sale.Vehicle?.Model || '',
    licensePlate: sale.Vehicle?.License_Plate || '',
    color: sale.Vehicle?.Color || '',
    manufactureYear: sale.Vehicle?.Year_Manufacture || '',
    modelYear: sale.Vehicle?.Year_Model || '',
    kilometers: milhar(sale.Kilometers) || '',
    brand: sale.Vehicle?.Brand,
    Maintenance_Object: sale.Vehicle?.Maintenance_Object || '',

    serialIdentification: sale.Vehicle?.serialIdentification || '',
    vehicleTypeId: sale.Vehicle?.vehicleTypeId || '',
    brand: sale.Vehicle?.Brand || '',
    model: sale.Vehicle?.Model || '',
    brakeType: sale.Vehicle?.BicycleBrakeType?.description || '',
    rearGears: sale.Vehicle?.BicycleRearGears?.quantity || '',
    frontGears: sale.Vehicle?.BicycleFrontGears?.quantity || '',
    frameSize: sale.Vehicle?.BicycleFrameSize?.description || '',
    frameMaterial: sale.Vehicle?.BicycleFrameMaterial?.description || '',
    modality: sale.Vehicle?.BicycleModality?.description || '',
    rearDerailleur: sale.Vehicle?.BicycleRearDerailleurVehicle?.description
      ? sale.Vehicle?.BicycleRearDerailleurVehicle?.description
      : sale.Vehicle?.BicycleRearDerailleur?.description || '',
    rimSize: sale.Vehicle?.BicycleRimSize?.description || '',
    suspensionType: sale.Vehicle?.BicycleSuspensionType?.description || '',
  };

  const payment = {
    paymentCondition: sale.Payments?.CondPayment?.Description || '',
    parcels:
      sale.Payments?.Parcels?.map((parcel) => ({
        id: parcel.id,
        dueDate: format(
          getDateOnlyFromDate(new Date(parcel.Due_Date)),
          'dd/MM/yyyy'
        ),
        paymentForm: parcel.FormOfPayment?.Desciption || '',
        value: currency(parcel.Amount),
      })) || [],
  };

  return (
    <Document
      title={`impresso-${sale.SalesType.Description}-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject={`${sale.SalesType.Description} Nº ${sale.Code}`}
    >
      <Page style={styles.page}>
        <Card style={{ marginBottom: 7 }}>
          <View fixed style={{ flexDirection: 'row' }}>
            {company.Url_Logo ? (
              <View
                style={{
                  width: '16%',
                  borderRightWidth: 1,
                  borderRightColor: 'black',
                  padding: 3,
                }}
              >
                <Image
                  src={company.Url_Logo}
                  style={{
                    width: 82,
                    height: 72,
                  }}
                />
              </View>
            ) : (
              <View />
            )}
            <View
              fixed
              style={{
                width: company.Url_Logo ? '84%' : '90%',
                paddingLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  paddingLeft: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text
                    style={[
                      styles.mainTitle,
                      {
                        width: '350px',
                      },
                    ]}
                  >
                    {company.Trading_Name}
                  </Text>
                  <Text>
                    {isJuridica ? 'CNPJ' : 'CPF'}: {cpfCnpj}{' '}
                    {company.IE && `IE: ${company.IE}`}
                  </Text>
                </View>
                <View style={{ paddingRight: 5 }}>
                  <Text style={styles.mainTitle}>
                    {quickQuote
                      ? `ORC. RÁPIDO #${sale.QuickQuoteCode}`
                      : `${salesType} ${sale.Code || sale.code}`}
                  </Text>
                  <Text>Data: {saleDate}</Text>
                </View>
              </View>

              <View style={{ paddingLeft: 5 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingTop: '20px',
                  }}
                >
                  <Text>
                    Endereço: {companyAddress.Address},{' '}
                    {companyAddress.Address_Number},{' '}
                    {companyAddress.Neighborhood}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', paddingBottom: '2px' }}>
                  <Text style={{ paddingRight: '5px' }}>
                    {companyAddress.City}/{companyAddress.State} - CEP:{' '}
                    {zipcode}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', paddingBottom: '5px' }}>
                  <Text style={{ paddingRight: '10px' }}>
                    Telefone: {phone}
                  </Text>
                  <Text>Email: {company.Email}</Text>
                </View>
              </View>
            </View>
          </View>
        </Card>
        {!!customer?.name?.trim() && customer.name !== 'Cliente Consumidor' ? (
          <Card fixed style={{ marginBottom: 7 }}>
            <View
              style={[styles.customerRow, quickQuote ? { height: 15 } : {}]}
            >
              <View style={{ width: '55%', paddingTop: 1, paddingLeft: 3 }}>
                <Text>
                  <Text style={styles.bold}>Cliente: </Text>
                  {customer.name}
                </Text>
              </View>
              {!quickQuote && (
                <View
                  style={{
                    width: '45%',
                    textAlign: 'right',
                    paddingRight: 5,
                  }}
                >
                  <Text>
                    <Text style={styles.bold}>CPF/CNPJ: </Text>{' '}
                    {customer.cpfCnpj}{' '}
                  </Text>
                </View>
              )}
            </View>
            {!quickQuote && (
              <>
                <View style={[styles.customerRow1 /*styles.borderBottom*/]}>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>Endereço: </Text>
                      {customer.street}, {customer.address_number}
                    </Text>
                  </View>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>Bairro: </Text>{' '}
                      {customer.neighborhood}{' '}
                    </Text>
                  </View>
                </View>

                <View style={[styles.customerRow1 /*styles.borderBottom*/]}>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>Cidade: </Text> {customer.city}/
                      {customer.UF}{' '}
                    </Text>
                  </View>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>CEP: </Text> {customer.zipCode}{' '}
                    </Text>
                  </View>
                </View>

                <View style={styles.customerRow1}>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>Complemento: </Text>{' '}
                      {customer.complement}
                    </Text>
                  </View>
                  <View style={{ width: '50%', paddingLeft: 3 }}>
                    <Text>
                      <Text style={styles.bold}>Ponto de Referência: </Text>{' '}
                      {customer.referencePoint}{' '}
                    </Text>
                  </View>
                </View>
              </>
            )}
          </Card>
        ) : null}
        {(!customer?.name?.trim() || customer.name === 'Cliente Consumidor') &&
          sale.SalesType.Description === 'PDV' && (
            <Card fixed>
              <View style={[styles.customerRow, { height: 15 }]}>
                <View style={{ width: '55%', paddingTop: 1, paddingLeft: 3 }}>
                  <Text>
                    <Text style={styles.bold}>Cliente: </Text>
                    Cliente Consumidor
                  </Text>
                </View>
              </View>
            </Card>
          )}
        {!!sale.Vehicle ? (
          (!vehicle.licensePlate ||
            !vehicle.Maintenance_Object ||
            !vehicle.serialIdentification) &&
          !vehicle.description ? null : (
            <>
              {vehicle?.vehicleTypeId !== 4 ? (
                <Card style={{ marginTop: 7 }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      paddingTop: quickQuote ? 2 : 5,
                      paddingLeft: 3,
                    }}
                  >
                    <View style={{ width: '100%', paddingLeft: '3px' }}>
                      <Text>
                        <Text style={styles.bold}>
                          {+companyBranch === 5
                            ? 'Item de Manutenção:'
                            : 'Veículo:'}
                        </Text>{' '}
                        {vehicle.Maintenance_Object
                          ? vehicle.Maintenance_Object
                          : vehicle.licensePlate ?? null}{' '}
                        {(vehicle.licensePlate || vehicle.Maintenance_Object) &&
                        vehicle.Maintenance_Object
                          ? `- ${vehicle.description}`
                          : vehicle.description
                          ? `- ${vehicle.description}`
                          : null}
                      </Text>
                    </View>
                  </View>
                  {!quickQuote && (
                    <View style={{ flexDirection: 'row', paddingLeft: 3 }}>
                      <View style={{ width: '15%', paddingLeft: '3px' }}>
                        <Text>
                          <Text style={styles.bold}>Cor:</Text> {vehicle.color}
                        </Text>
                      </View>

                      <View style={{ width: '25%' }}>
                        <Text>
                          <Text style={styles.bold}>Marca:</Text>{' '}
                          {vehicle.brand}
                        </Text>
                      </View>
                      <View style={{ width: '20%' }}>
                        <Text>
                          <Text style={styles.bold}>KM Atual:</Text>{' '}
                          {vehicle.kilometers}
                        </Text>
                      </View>
                    </View>
                  )}
                </Card>
              ) : (
                <Card style={{ marginTop: 7 }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      paddingTop: quickQuote ? 2 : 5,
                      paddingLeft: 3,
                    }}
                  >
                    <View style={{ width: '100%', paddingLeft: '3px' }}>
                      <Text>
                        <Text style={styles.bold}>Série/Identificação :</Text>{' '}
                        {vehicle?.serialIdentification}
                      </Text>
                    </View>
                  </View>
                  {!quickQuote && (
                    <>
                      <View style={{ flexDirection: 'row', paddingLeft: 3 }}>
                        <View style={{ width: '50%', paddingLeft: '3px' }}>
                          <Text>
                            <Text style={styles.bold}>Marca/Modelo:</Text>{' '}
                            {vehicle?.brand} - {vehicle?.model}
                          </Text>
                        </View>
                        <View style={{ width: '15%' }}>
                          <Text>
                            <Text style={styles.bold}>Ano:</Text>{' '}
                            {vehicle?.manufactureYear}
                          </Text>
                        </View>
                        <View style={{ width: '10%' }}>
                          <Text>
                            <Text style={styles.bold}>Aro:</Text>{' '}
                            {vehicle?.rimSize}
                          </Text>
                        </View>
                        <View style={{ width: '25%' }}>
                          <Text>
                            <Text style={styles.bold}>Tam. do quadro:</Text>{' '}
                            {vehicle?.frameSize}
                          </Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', paddingLeft: 3 }}>
                        <View style={{ width: '33%', paddingLeft: '3px' }}>
                          <Text>
                            <Text style={styles.bold}>Modalidade:</Text>{' '}
                            {vehicle?.modality}
                          </Text>
                        </View>
                        <View style={{ width: '33%' }}>
                          <Text>
                            <Text style={styles.bold}>Material do Quadro:</Text>{' '}
                            {vehicle?.frameMaterial}
                          </Text>
                        </View>
                        <View style={{ width: '33%' }}>
                          <Text>
                            <Text style={styles.bold}>Tipo de Freio:</Text>{' '}
                            {vehicle?.brakeType}
                          </Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', paddingLeft: 3 }}>
                        <View style={{ width: '60%', paddingLeft: '3px' }}>
                          <Text>
                            <Text style={styles.bold}>Tipo de Suspensão:</Text>{' '}
                            {vehicle?.suspensionType}
                          </Text>
                        </View>
                        <View style={{ width: '40%' }}>
                          <Text>
                            <Text style={styles.bold}>Câmbio Traseiro:</Text>{' '}
                            {vehicle?.rearDerailleur}
                          </Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', paddingLeft: 3 }}>
                        <View style={{ width: '25%', paddingLeft: '3px' }}>
                          <Text>
                            <Text style={styles.bold}>Marchas Dianteiras:</Text>{' '}
                            {vehicle?.frontGears}
                          </Text>
                        </View>
                        <View style={{ width: '25%' }}>
                          <Text>
                            <Text style={styles.bold}>Marchas Traseiras:</Text>{' '}
                            {vehicle?.rearGears}
                          </Text>
                        </View>
                        <View style={{ width: '50%' }}>
                          <Text>
                            <Text style={styles.bold}>Cor:</Text>{' '}
                            {vehicle?.color}
                          </Text>
                        </View>
                      </View>
                    </>
                  )}
                </Card>
              )}
            </>
          )
        ) : (
          <View />
        )}
        {(sale.SalesType.Description === 'Orçamento' ||
          sale.SalesType.Description === 'Ordem de Serviço') &&
          sale?.SalesEmployees?.length > 0 && (
            <>
              <Card style={{ marginTop: 7 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                  }}
                >
                  <View style={{ width: '100%', paddingLeft: '3px' }}>
                    <Text>
                      <Text style={styles.bold}>
                        {+companyBranch === 5
                          ? 'Técnicos Responsáveis:'
                          : 'Mecânicos Responsáveis:'}
                      </Text>{' '}
                      {sale.SalesEmployees.map(
                        (item) => item.Employee.name
                      ).join(', ')}
                    </Text>
                  </View>
                </View>
              </Card>
            </>
          )}
        {!!products.length ? (
          <>
            <Text style={styles.cardTitle}>PRODUTOS</Text>

            <Card>
              <ItemsHeader isProductionOrder={isProductionOrder} />
            </Card>
            {products.map((item) => (
              <ItemRow
                key={item.id}
                code={item.Products.Code}
                description={item.Description}
                quantity={item.Quantity}
                unitPrice={item.Unit_Value}
                discount={item.Discount_Value}
                total={item.Amount}
                isProductionOrder={isProductionOrder}
              />
            ))}
          </>
        ) : (
          <View />
        )}
        {!!services.length ? (
          <>
            <Text style={styles.cardTitle}>SERVIÇOS</Text>
            <Card>
              <ItemsHeader isProductionOrder={isProductionOrder} />
            </Card>
            {services.map((item) => (
              <ItemRow
                key={item.id}
                code={item.Services.Code}
                description={item.Description}
                quantity={item.Quantity}
                unitPrice={item.Unit_Value}
                discount={item.Discount_Value}
                total={item.Amount}
                isProductionOrder={isProductionOrder}
              />
            ))}
          </>
        ) : (
          <View />
        )}
        {sale.freightValue > 0 && (
          <View style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <View style={styles.line} />

            <View
              style={[
                {
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
                styles.span,
              ]}
            >
              <Text style={styles.bold}>Frete: &nbsp;</Text>
              <Text>{currency(sale.freightValue)}</Text>
            </View>
          </View>
        )}
        {!isProductionOrder ? (
          <>
            <Card style={{ marginTop: 10 }}>
              <View
                style={{
                  flexDirection: 'row',
                  height: 30,
                  alignItems: 'center',
                  paddingLeft: 5,
                }}
              >
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total de Serviços:</Text>{' '}
                    {currency(sale.Service_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Services)} (
                    {percentage(sale.Discount_Services_Percentage)})
                  </Text>
                </View>
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total de Produtos:</Text>{' '}
                    {currency(sale.Product_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Products)} (
                    {percentage(sale.Discount_Products_Percentage)})
                  </Text>
                </View>
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total:</Text>{' '}
                    {currency(sale.Final_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Value)} (
                    {percentage(sale.Discount_Value_Percentage)})
                  </Text>
                </View>
              </View>
            </Card>
            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: 'black',
                marginTop: 10,
                marginBottom: quickQuote ? 0 : 10,
              }}
            />
          </>
        ) : (
          <View />
        )}
        {!!sale.Payments && !isProductionOrder ? (
          <Card style={{ padding: 5 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              {payment.paymentCondition}
            </Text>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <View style={{ width: '20%' }}>
                <Text style={[styles.span, styles.bold]}>Vencimento</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={[styles.span, styles.bold]}>
                  Forma de Pagamento
                </Text>
              </View>
              <View style={{ width: '55%' }}>
                <Text style={[styles.span, styles.bold]}>Valor</Text>
              </View>
            </View>
            {payment.parcels.map((parcel) => (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '20%' }}>
                  <Text style={styles.span}>{parcel.dueDate}</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={styles.span}>{parcel.paymentForm}</Text>
                </View>
                <View style={{ width: '55%' }}>
                  <Text style={styles.span}>{parcel.value}</Text>
                </View>
              </View>
            ))}
          </Card>
        ) : (
          <View />
        )}
        {!sale?.Payments &&
          sale.paymentSuggestions?.length > 0 &&
          showPaymentSuggestions && (
            <Card style={{ padding: 5, marginTop: 7 }}>
              <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                {'Sugestão de Pagamento'}
              </Text>

              {sale.paymentSuggestions.map((paymentSuggestion) => (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: '100%' }}>
                    <Text style={{ fontSize: 9 }}>{paymentSuggestion}</Text>
                  </View>
                </View>
              ))}
            </Card>
          )}
        {(!!sale.Comments ||
          !!sale.Defects ||
          !!sale.Malfunctions ||
          !!scheduling) &&
        sale.SalesType.Description !== 'PDV' ? (
          <Card
            style={{
              marginTop: 10,
              flexDirection: 'row',
              minHeight: 80,
              maxHeight: 250,
            }}
          >
            <View
              style={{
                width: '33%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Observações</Text>
              <Text style={styles.span}>{sale.Comments}</Text>
              {scheduling !== null ? (
                <Text style={styles.span}>{`Agendamento efetuado para ${
                  salesType === 'Orçamento' ? 'esse orçamento' : 'essa venda'
                } para o dia ${format(
                  scheduling,
                  'dd/MM/yyyy'
                )} (${translateDate(scheduling)}) as ${format(
                  scheduling,
                  'HH:mm'
                )}`}</Text>
              ) : null}
            </View>

            <View
              style={{
                width: '33%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Defeitos</Text>
              <Text style={styles.span}>{sale.Defects}</Text>
            </View>

            <View style={{ width: '33%', padding: 5 }}>
              <Text style={[styles.span, styles.bold]}>Avarias</Text>
              <Text style={styles.span}>{sale.Malfunctions}</Text>
            </View>
          </Card>
        ) : (
          <View />
        )}
        {!!sale.Comments && sale.SalesType.Description === 'PDV' && (
          <Card style={{ marginTop: 10, flexDirection: 'row', minHeight: 50 }}>
            <View
              style={{
                width: '100%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Observações</Text>
              <Text style={styles.span}>{sale.Comments}</Text>
            </View>
          </Card>
        )}
        {(!!sale.Company.defaultSalesOrderFooterText ||
          !!sale.Company.defaultOrcamentFooterText) && (
          <View style={{ marginTop: 8 }}>
            <Card style={{ padding: 5 }}>
              <Text style={{ fontSize: 8 }}>
                {getDefaultText(
                  !!quickQuote
                    ? 'Orçamento Rápido'
                    : sale.SalesType.Description,
                  sale
                )}
              </Text>
            </Card>
          </View>
        )}
        {sale.SalesType.Description === 'Ordem de Serviço' &&
        config.showSheetSignatureField === 1 &&
        !isProductionOrder ? (
          <Card
            style={{
              padding: 5,
              marginTop: 10,
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  Autorização do Cliente
                </Text>
              </View>
              <View style={{ marginTop: 8, width: '90%' }}>
                <Text style={{ fontSize: 10 }}>
                  Declaro, através dessa Ordem de Serviço, nº{sale.Code}, que
                  autorizo a execução do serviços apresentados no{' '}
                  {+companyBranch === 5 ? 'item de manutenção' : 'veículo'} em
                  questão.
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 8,
                }}
              >
                <Text style={{ fontSize: 10, fontStyle: 'italic' }}>
                  {companyAddress.City}, ____ /____ /____ Ass. do
                  cliente:__________________________________________________________________________
                </Text>
              </View>
            </View>
          </Card>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoRegular },
    { src: fontRobotoThin, fontWeight: 'thin' },
    { src: fontRobotoBold, fontWeight: 'bold' },
    { src: fontRobotoItalic, fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  descriptionText: {
    fontSize: 9,
  },
  descriptionTextPartTwo: {
    fontSize: 9,
  },
  span: {
    fontSize: 9,
  },
  text: {
    fontSize: 7,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  customerRow: {
    flexDirection: 'row',
    height: 30,
    paddingTop: 1,
    paddingLeft: 3,
  },
  customerRow1: {
    paddingTop: 1,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  cardTitle: {
    marginTop: 7,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    borderBottom: '1px solid black',
    marginTop: '5px',
    marginBottom: '5px',
  },
});
export default SalePrintingSheet1;

import styled from 'styled-components';

export const ContainerCampaignsScreen = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
`;

export const CampaignsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
`;

export const CampaignFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

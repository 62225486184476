import React, { useState, useEffect } from 'react';
import {
  Document,
  Text,
  Font,
  Page,
  StyleSheet,
  View,
  Image,
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';

import {
  cnpjMask,
  cpfMask,
  milhar,
  phoneMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';

import constants from '../../../utils/constants';

const SATThermalCoil = ({ sale }) => {
  const company = sale.Company;
  const cpfCnpj =
    company.Cpf_Cnpj.length <= 11
      ? cpfMask(company.Cpf_Cnpj)
      : cnpjMask(company.Cpf_Cnpj);

  const isLegalPerson = company.Type === 'Jurídica';
  const companyAddress = company.Address[0];
  const companyPhone = company.Phones[0];
  const addressFirstLine = !companyAddress
    ? ''
    : `${companyAddress.Address}, ${companyAddress.Address_Number}`;
  const addressSecondLine = !companyAddress
    ? ''
    : `${companyAddress.Neighborhood}, ${companyAddress.City} / ${companyAddress.State}`;
  const phone = !companyPhone ? '' : phoneMask(companyPhone.Number_Phone1);

  const salesType = sale.SalesType.Description;
  const isOrcament = sale.SalesType_id === constants.SALES_TYPE_ID.ORCAMENT;
  const isOrderOfService =
    sale.SalesType_id === constants.SALES_TYPE_ID.ORDER_OF_SERVICE;
  const isOpen = sale.SaleStatus_id === constants.SALE_STATUS_ID.OPEN;

  const items = sale.SalesItems.filter((item) => item.Product_id);
  const payment = sale.Payments;

  const addAmountsInParcelsByPaymentForm = (parcels) => {
    const uniquePaymentForms = [];
    const hashTable = {};

    let totalParcelsAcumulate = 0;
    const valueServiceDiscount =
      sale.Service_Value > 0
        ? (sale.Discount_Value_Percentage / 100) * sale.Service_Value
        : 0;

    parcels.forEach((parcel, i) => {
      const paymentFormDescription = parcel.FormOfPayment.Desciption;

      if (!hashTable[paymentFormDescription]) {
        hashTable[paymentFormDescription] = {
          description: paymentFormDescription,
          amount: 0,
        };

        uniquePaymentForms.push(hashTable[paymentFormDescription]);
      }

      const proporateParcel = Math.round(
        (parcel.Amount * 100) / payment.Amount
      );

      let parcelAmount = Number(
        (
          parcel.Amount -
          (proporateParcel / 100) * (sale.Service_Value - valueServiceDiscount)
        ).toFixed(2)
      );

      totalParcelsAcumulate += parcelAmount;

      if (
        i === parcels.length - 1 &&
        totalParcelsAcumulate !==
          payment.Amount - (sale.Service_Value - valueServiceDiscount)
      ) {
        parcelAmount =
          totalParcelsAcumulate >
          payment.Amount - (sale.Service_Value - valueServiceDiscount)
            ? parcelAmount -
              (totalParcelsAcumulate -
                (payment.Amount - (sale.Service_Value - valueServiceDiscount)))
            : parcelAmount +
              (payment.Amount -
                (sale.Service_Value - valueServiceDiscount) -
                totalParcelsAcumulate);
      }

      hashTable[paymentFormDescription].amount += +parcelAmount;
    });

    return uniquePaymentForms;
  };

  const paymentForms = addAmountsInParcelsByPaymentForm(sale.Payments.Parcels);

  const sat = sale.SATSale;
  const haveSatCustomer = sat.cpfCnpj ? true : false;

  const customer = haveSatCustomer
    ? {
        name: sale.Customer.Company_Name,
        document:
          sat.cpfCnpj.length <= 11
            ? cpfMask(sat.cpfCnpj)
            : cnpjMask(sat.cpfCnpj),
        isLegalPerson: sale.Customer.Type === 'Fisica',
        street: !sale.Customer.Address[0]
          ? ''
          : sale.Customer.Address[0].Address +
            ' ' +
            sale.Customer.Address[0].Address_Number,
        neighborhood: !sale.Customer.Address[0]
          ? ''
          : sale.Customer.Address[0].Neighborhood,
        city: !sale.Customer.Address[0]
          ? ''
          : sale.Customer.Address[0].City +
            ' / ' +
            sale.Customer.Address[0].State,
      }
    : {
        name: 'Consumidor não identificado',
        document: '000.000.000-00',
      };

  const formatSATAccessKey = (satAccessKey) => {
    const accessKeyArray = satAccessKey.split('');
    const slicedAccessKeyArray = [];

    for (let i = 0; i < accessKeyArray.length; i += 4) {
      const accessKeyPart = accessKeyArray.slice(i, i + 4);
      slicedAccessKeyArray.push(accessKeyPart.join(''));
    }

    return slicedAccessKeyArray.join(' ');
  };

  const satAccessKey = formatSATAccessKey(sat.accessKey);

  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (!sat.qrCodeLink) return;
    QRCode.toDataURL(sat.qrCodeLink).then((qrCode) => setQrCode(qrCode));
  }, []);

  const canvas = document.createElement('canvas');
  JsBarcode(canvas, sat.accessKey, {
    displayValue: false,
  });
  const barcode = canvas.toDataURL();

  return (
    <Document>
      <Page
        size={{
          width: 226.77,
        }}
        style={styles.page}
      >
        <View
          style={
            company.Url_Logo
              ? styles.itemRow
              : { textAlign: 'center', marginBottom: 10 }
          }
        >
          {company.Url_Logo && (
            <Image
              src={company.Url_Logo}
              style={{ width: 70, marginRight: 5 }}
            />
          )}
          <View>
            <Text style={styles.companyName}>{company.Trading_Name}</Text>
            <Text>{company.Company_Name}</Text>
            <Text>{addressFirstLine}</Text>
            <Text>{addressSecondLine}</Text>
            <Text>Tel {phone}</Text>
          </View>
        </View>
        {isLegalPerson && (
          <View
            style={[
              styles.itemRow,
              {
                justifyContent: 'space-between',
              },
            ]}
          >
            <Text>CNPJ: {cpfCnpj}</Text>
            {company.IE && <Text>IE: {company.IE}</Text>}
          </View>
        )}
        <LineBreak />
        {haveSatCustomer && (
          <View style={styles.itemRow}>
            <Text style={{ fontWeight: 'bold' }}>CPF/CNPJ do Consumidor: </Text>
            <Text>{customer.document}</Text>
          </View>
        )}
        <View style={styles.itemRow}>
          <Text style={{ fontWeight: 'bold' }}>Razão Social/Nome: </Text>
          <Text>{customer.name}</Text>
        </View>
        <LineBreak />
        <View style={styles.padding2}>
          {/* HEADERS */}
          <View style={[styles.itemRow, { fontWeight: 'bold' }]}>
            <View style={{ width: '25%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '75%' }}>
              <Text>Item</Text>
            </View>
          </View>

          <View style={[styles.itemRow, { fontWeight: 'bold' }]}>
            <View style={{ width: '35%' }}>
              <Text>Quantidade</Text>
            </View>
            <View style={{ width: '35%' }}>
              <Text>Preço Unit.</Text>
            </View>
            <View style={{ width: '30%' }}>
              <Text>Preço Total</Text>
            </View>
          </View>

          {/* ITEMS */}
          {items.map((item, i) => {
            const discountPercentage = (100 - item.Discount_Value) / 100;
            const unitPrice = item.Unit_Value * discountPercentage;
            return (
              <View style={{ fontSize: 7 }}>
                <View style={styles.itemRow}>
                  <View style={{ width: '25%', textAlign: 'center' }}>
                    <Text>
                      {i + 1} -{' '}
                      {item.Products?.Code || item.Services?.Code || ''}
                    </Text>
                  </View>

                  <View style={{ width: '75%' }}>
                    <Text>{item.Description}</Text>
                  </View>
                </View>

                <View style={styles.itemRow}>
                  <View style={{ width: '25%', textAlign: 'center' }}>
                    <Text>{item.Quantity}</Text>
                  </View>
                  <View style={{ width: '55%' }}>
                    <Text>x {currency(unitPrice)} =</Text>
                  </View>
                  <View style={{ width: '20%' }}>
                    <Text>{currency(item.Amount)}</Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
        <LineBreak />
        <View
          style={[
            styles.itemRow,
            {
              justifyContent: 'space-between',
            },
          ]}
        >
          <Text>Subtotal:</Text>
          <Text>{currency(sale.Product_Value)}</Text>
        </View>
        <View
          style={[
            styles.itemRow,
            {
              justifyContent: 'space-between',
            },
          ]}
        >
          <Text>Desconto:</Text>
          <Text>
            {currency(
              (sale.Discount_Value_Percentage / 100) * sale.Product_Value
            )}
          </Text>
        </View>
        <View
          style={[
            styles.itemRow,
            {
              justifyContent: 'space-between',
              marginTop: 15,
            },
          ]}
        >
          <Text>Total:</Text>
          <Text>
            {currency(
              sale.Product_Value -
                (sale.Discount_Value_Percentage / 100) * sale.Product_Value
            )}
          </Text>
        </View>
        <View
          style={[
            styles.itemRow,
            {
              justifyContent: 'space-between',
              marginBottom: 15,
            },
          ]}
        >
          <Text>Troco:</Text>
          <Text>{currency(sale.returnedValue)}</Text>
        </View>

        {paymentForms.map((paymentForm) => (
          <View
            style={[
              styles.itemRow,
              {
                justifyContent: 'space-between',
              },
            ]}
            key={paymentForm.description}
          >
            <Text>{paymentForm.description}</Text>
            <Text>{currency(paymentForm.amount)}</Text>
          </View>
        ))}
        <LineBreak />
        {sale.additionalInfo && (
          <>
            <View>
              <Text style={{ fontWeight: 'bold' }}>
                OBSERVAÇÕES DO CONTRIBUINTE
              </Text>
              <Text style={{ fontSize: 7 }}>
                {sale.additionalInfo}{' '}
                {sale.customerRequest
                  ? `Nota fiscal referente ao pedido: ${sale.customerRequest}`
                  : ''}
              </Text>
            </View>
            <LineBreak />
          </>
        )}
        <View style={{ textAlign: 'center' }}>
          <Text>SAT No. {sat.code}</Text>
          <Text style={{ fontWeight: 'bold' }}>
            {format(new Date(sat.issuedAt), 'dd/MM/yyyy - HH:mm:ss')}
          </Text>
          <Text style={{ fontSize: 7, fontWeight: 'bold', marginTop: 10 }}>
            {satAccessKey}
          </Text>
          <Image src={barcode} />
          {qrCode && (
            <Image src={qrCode} style={{ width: 150, margin: '0 auto' }} />
          )}
        </View>
        <LineBreak />
      </Page>
    </Document>
  );
};

const LineBreak = () => (
  <View
    style={{
      marginTop: 5,
      marginBottom: 5,
      borderBottomStyle: 'dashed',
      borderBottomWidth: 1,
    }}
  />
);

const styles = StyleSheet.create({
  companyName: {
    fontWeight: 'bold',
    fontSize: 9,
    marginBottom: 5,
  },
  padding2: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  padding5: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemRow: {
    flexDirection: 'row',
    width: '100%',
  },
  page: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 8,
    fontFamily: 'Arial',
  },
});

export default SATThermalCoil;

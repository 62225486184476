import React, { createContext, useState, useEffect, useContext } from 'react';
import BrandingThemeRepository from 'v2/repositories/BrandingThemeRepository';
import { applicationTheme } from 'v2/styles/themes';
import { ThemeProvider } from 'styled-components';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [branding, setBranding] = useState({});
  const [brandingName, setBrandingName] = useState('');
  const [brandingId, setBrandingId] = useState('');
  const [theme, setTheme] = useState(applicationTheme);
  const [styles, setStyles] = useState('');

  const getBrandingLogo = (type) => {
    switch (type) {
      case 'alt':
        return branding.logoAlt || '';
      case 'white':
        return branding.logoWhite || '';
      case 'horizontal':
        return branding.logoHorizontal || '';
      case 'horizontal-white':
        return branding.logoHorizontalWhite || '';
      default:
        return branding.logo || '';
    }
  };

  const getBrandingIcon = (type) => {
    switch (type) {
      case 'alt':
        return branding.iconAlt;
      case 'white':
        return branding.iconWhite;
      default:
        return branding.icon;
    }
  };

  const setFavIcons = (favIcon) => {
    const headTitleOSD = document.querySelector('head');
    const brandingFaviconOSD = document.createElement('link');
    brandingFaviconOSD.setAttribute('rel', 'shortcut icon');
    brandingFaviconOSD.setAttribute('href', favIcon);
    headTitleOSD.appendChild(brandingFaviconOSD);
  };

  //TODO: Refatorar e retirar necessidade de useEffect do brandingName/brandingId
  //nas páginas _blank, pois recarregam o contexto e ficam sem o brandingName pra exibir
  const setDocumentTitle = (pageTitle, showBrandingName = true) => {
    switch (true) {
      case !showBrandingName:
        return (window.document.title = pageTitle);
      case !pageTitle:
        return (window.document.title = brandingName);
      default:
        return (window.document.title = `${brandingName} - ${pageTitle}`);
    }
  };

  const setThemeColors = (themeData) => {
    setTheme({
      ...theme,
      colors: {
        ...theme.colors,
        ...themeData,
      },
    });
  };

  useEffect(() => {
    const location = window.location.origin.includes('localhost')
      ? currentBrandingName
      : window.location.host;
    const fetchTheme = async () => {
      const fetchedTheme = await BrandingThemeRepository.getThemeByLocation(
        location
      );

      setThemeColors(fetchedTheme.colors);
      setStyles(fetchedTheme.styles);
      setBranding(fetchedTheme.branding);
      setBrandingName(fetchedTheme.branding.product);
      setBrandingId(fetchedTheme.branding.brandingId);
      setFavIcons(fetchedTheme.branding.favIcon);
    };

    fetchTheme();
  }, []);

  useEffect(() => {
    if (styles) {
      const style = document.createElement('style');
      style.innerHTML = styles;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [styles]);

  return (
    <ThemeContext.Provider
      value={{
        brandingId,
        brandingName,
        getBrandingLogo,
        getBrandingIcon,
        setDocumentTitle,
      }}
    >
      {theme && styles && (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      )}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

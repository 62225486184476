import React, { useState, useEffect } from 'react';
import {
  faUserFriends,
  faBoxOpen,
  faFileInvoiceDollar,
  faTools,
  faMoneyBillAlt,
  faHandHoldingUsd,
  faCommentDollar,
  faCommentsDollar,
  faCalendarDay,
  faCalendarMinus,
  faBirthdayCake,
  faExchangeAlt,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { currency, milhar } from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';
import DashboardCard from './DashboardCard';
import companiesRepository from '../../../repositories/Companies';
import companyConfigRepository from '../../../repositories/CompanyConfig';
import { toastr } from 'react-redux-toastr';
import { useAuth } from '../../../contexts/auth';
import { format, nextSaturday, subDays } from 'date-fns';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { dashboardFeatureItems } from './DashboardFeatureItems';
import AccessPermissionsRepository from 'v2/repositories/AccessPermissionsRepository';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { handleMenuItemClick, validateFeatureAvailability } = useSidebar();

  const [counters, setCounters] = useState({
    clients: 0,
    products: 0,
    sales: 0,
    services: 0,
    amountToReceiveToday: 0,
    pedingAmountToReceiveToday: 0,
    amountToReceiveLate: 0,
    pedingAmountToReceiveLate: 0,
    amountToPayToday: 0,
    pedingAmountToPayToday: 0,
    amountToPayLate: 0,
    pedingAmountToPayLate: 0,
    schedulingForToday: 0,
    schedulingForWeek: 0,
    birthdaysOfToday: 0,
    birthdaysOfWeek: 0,
    returnForecastToday: 0,
    returnForecastWeek: 0,
  });
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const [permissionsDashboard, setPermissionsDashboard] = useState([]);
  const { companyId, company, user } = useAuth();

  useEffect(() => {
    if (!!companyId) {
      getCounters();
    }
  }, [companyId]);

  useEffect(() => {
    getAccessPermissionDashboard();
  }, []);

  const getAccessPermissionDashboard = async () => {
    try {
      const response = await AccessPermissionsRepository.getByProfileId(
        user.profileId,
        companyId
      );

      setPermissionsDashboard(
        response.data.groupFeatures[11].mainFeatures[0].subFeatures
      );
    } catch (err) {
      throw err;
    }
  };

  const getMonthRangeOfCustomerReturnCalc = async (companyId) => {
    try {
      const { monthRangeOfCustomerReturnCalc } =
        await companyConfigRepository.show(companyId);

      return monthRangeOfCustomerReturnCalc;
    } catch (err) {
      throw err;
    }
  };

  const getCounters = async () => {
    setIsLoading(true);
    try {
      const monthRangeOfCustomerReturnCalc =
        await getMonthRangeOfCustomerReturnCalc(companyId);

      const counter = await companiesRepository.getCounters(
        companyId,
        monthRangeOfCustomerReturnCalc
      );
      setCounters({
        clients: counter.customers,
        products: counter.products,
        services: counter.services,
        sales: counter.sales,
        amountToReceiveToday: counter.amountToReceiveToday,
        pedingAmountToReceiveToday: counter.pedingAmountToReceiveToday,
        amountToReceiveLate: counter.amountToReceiveLate,
        pedingAmountToReceiveLate: counter.pedingAmountToReceiveLate,
        amountToPayToday: counter.amountToPayToday,
        pedingAmountToPayToday: counter.pedingAmountToPayToday,
        amountToPayLate: counter.amountToPayLate,
        pedingAmountToPayLate: counter.pedingAmountToPayLate,
        schedulingForToday: counter.schedulingForToday,
        schedulingForWeek: counter.schedulingForWeek,
        birthdaysOfToday: counter.birthdaysOfToday,
        birthdaysOfWeek: counter.birthdaysOfWeek,
        returnForecastToday: counter.returnForecastToday,
        returnForecastWeek: counter.returnForecastWeek,
        forecastMessage: counter.forecastMessage,
      });
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os contadores da empresa. Por favor, tente novamente'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getNextSaturday = nextSaturday(new Date());
  const getLastSunday = subDays(getNextSaturday, 6);

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const disabledMyPosition = isPlanFree || isPlanStart || isPlanBasic;

  const CustomerReturnIndicadorMessage = () => {
    const splitedMessage = counters?.forecastMessage?.split(
      ' da sua base retornou nos últimos '
    );

    return splitedMessage.length > 1 ? (
      <p
        style={{
          color: '#444',
          fontSize: '10px',
          textAlign: 'left',
          width: '100%',
        }}
      >
        <span style={{ color: '#72bb53', fontWeight: 'bold' }}>
          {splitedMessage[0]?.replace('.', ',')}
        </span>{' '}
        da sua base retornou nos últimos{' '}
        <span style={{ color: '#d9534f', fontWeight: 'bold' }}>
          {splitedMessage[1]}
        </span>
      </p>
    ) : (
      ''
    );
  };

  const isResourceAvailable = (resourceDescription, permissions) => {
    const resource = permissions.find(
      (permission) =>
        permission.description.toLowerCase() ===
        resourceDescription.toLowerCase()
    );
    return resource ? resource.status : false;
  };

  return (
    <div className="dashboard-container">
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[1], false)
            ? 'sales'
            : false
        }
        title="OS/Vendas"
        value={milhar(counters.sales)}
        icon={faFileInvoiceDollar}
        color="rgb(63, 81, 181)"
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[1], true);
        }}
        isAvailable={isResourceAvailable('OS/Vendas', permissionsDashboard)}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[31], false)
            ? 'customers'
            : false
        }
        title="Clientes"
        value={milhar(counters.clients)}
        Message={CustomerReturnIndicadorMessage}
        icon={faUserFriends}
        color="#673ab7"
        showMessage={!disabledMyPosition}
        isLoading={isLoading}
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[31], true);
        }}
        isAvailable={isResourceAvailable('Clientes', permissionsDashboard)}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[35], false)
            ? 'products'
            : false
        }
        title="Produtos"
        value={milhar(counters.products)}
        icon={faBoxOpen}
        color="#009688"
        onClick={() => {
          handleMenuItemClick(
            validateFeatureAvailability(dashboardFeatureItems[35], true)
          );
        }}
        isAvailable={isResourceAvailable('Produtos', permissionsDashboard)}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[7], false)
            ? 'services'
            : false
        }
        title="Serviços"
        value={milhar(counters.services)}
        icon={faTools}
        color="#ff9800"
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[7], true);
        }}
        isAvailable={isResourceAvailable('Serviços', permissionsDashboard)}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[99], false)
            ? 'bills-to-receive?receive=today'
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[99], true);
        }}
        title="A Receber Hoje"
        style={{ marginRight: '26px' }}
        value={currency(counters.amountToReceiveToday)}
        icon={faMoneyBillAlt}
        color="#6ab187"
        fontColor="#6ab187"
        pendingAmount={currency(counters.pedingAmountToReceiveToday)}
        isAvailable={isResourceAvailable(
          'A Receber Hoje',
          permissionsDashboard
        )}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[99], false)
            ? 'bills-to-receive?receive=late'
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[99], true);
        }}
        title="Recebimentos em atraso"
        style={{ marginRight: '26px' }}
        value={currency(counters.amountToReceiveLate)}
        icon={faHandHoldingUsd}
        color="#d32d41"
        fontColor="#d32d41"
        pendingAmount={currency(counters.pedingAmountToReceiveLate)}
        isAvailable={isResourceAvailable(
          'Recebimentos em atraso',
          permissionsDashboard
        )}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[100], false)
            ? 'bills-to-pay?receive=today'
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[100], true);
        }}
        title="A pagar hoje"
        style={{ marginRight: '26px' }}
        value={currency(counters.amountToPayToday)}
        icon={faCommentDollar}
        color="#1c4e80"
        fontColor="#1c4e80"
        pendingAmount={currency(counters.pedingAmountToPayToday)}
        isAvailable={isResourceAvailable('A pagar hoje', permissionsDashboard)}
      />

      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[100], false)
            ? 'bills-to-pay?receive=late'
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[100], true);
        }}
        title="Pagamentos em atraso"
        style={{ marginRight: '26px' }}
        value={currency(counters.amountToPayLate)}
        icon={faCommentsDollar}
        color="#d32d41"
        fontColor="#d32d41"
        pendingAmount={currency(counters.pedingAmountToPayLate)}
        isAvailable={isResourceAvailable(
          'Pagamentos em atraso',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[4], false)
            ? `schedules?firstDate=${format(
                new Date(),
                "yyyy-MM-dd'T'HH:mm"
              )}&secondDate=${format(new Date(), "yyyy-MM-dd'T'HH:mm")}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[4], true);
        }}
        title="Agendamentos de Hoje"
        style={{ marginRight: '26px' }}
        value={milhar(counters.schedulingForToday)}
        icon={faCalendarDay}
        color="#06648c"
        isAvailable={isResourceAvailable(
          'Agendamentos de Hoje',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[4], false)
            ? `schedules?firstDate=${format(
                getLastSunday,
                "yyyy-MM-dd'T'HH:mm"
              )}&secondDate=${format(getNextSaturday, "yyyy-MM-dd'T'HH:mm")}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[4], true);
        }}
        title="Agendamentos da Semana"
        style={{ marginRight: '26px' }}
        value={milhar(counters.schedulingForWeek)}
        icon={faCalendarMinus}
        ss
        color="#015668"
        isAvailable={isResourceAvailable(
          'Agendamentos da Semana',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[45], false)
            ? `birthdays?firstDate=${format(
                new Date(),
                'yyyy-MM-dd'
              )}&secondDate=${format(new Date(), 'yyyy-MM-dd')}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[45], true);
        }}
        title="Aniversariantes de Hoje"
        style={{ marginRight: '26px' }}
        value={milhar(counters.birthdaysOfToday)}
        icon={faBirthdayCake}
        color="#ec87c0"
        isAvailable={isResourceAvailable(
          'Aniversariantes de Hoje',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[45], false)
            ? `birthdays?firstDate=${format(
                getLastSunday,
                'yyyy-MM-dd'
              )}&secondDate=${format(getNextSaturday, 'yyyy-MM-dd')}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[45], true);
        }}
        title="Aniversariantes da Semana"
        style={{ marginRight: '26px' }}
        value={milhar(counters.birthdaysOfWeek)}
        icon={faBirthdayCake}
        color="#fb65d2"
        isAvailable={isResourceAvailable(
          'Aniversariantes da Semana',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[70], false)
            ? `return-forecast?firstDate=${format(
                new Date(),
                'yyyy-MM-dd'
              )}&secondDate=${format(new Date(), 'yyyy-MM-dd')}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[70], true);
        }}
        title="Previsão de Retorno Hoje"
        style={{ marginRight: '26px' }}
        value={milhar(counters.returnForecastToday)}
        icon={faExchangeAlt}
        color="#74b9cd"
        isAvailable={isResourceAvailable(
          'Previsão de Retorno Hoje',
          permissionsDashboard
        )}
      />
      <DashboardCard
        link={
          validateFeatureAvailability(dashboardFeatureItems[70], false)
            ? `return-forecast?firstDate=${format(
                getLastSunday,
                'yyyy-MM-dd'
              )}&secondDate=${format(getNextSaturday, 'yyyy-MM-dd')}`
            : false
        }
        onClick={() => {
          validateFeatureAvailability(dashboardFeatureItems[70], true);
        }}
        title="Previsão de Retorno da Semana"
        style={{ marginRight: '26px' }}
        value={milhar(counters.returnForecastWeek)}
        icon={faExchangeAlt}
        color="#2f96b4"
        isAvailable={isResourceAvailable(
          'Previsão de Retorno da Semana',
          permissionsDashboard
        )}
      />
      <DashboardCard
        title="Ticket Médio - Venda"
        style={{ marginRight: '26px' }}
        value={
          isPlanFree || isPlanStart
            ? currency('0')
            : currency(company?.salesAverageTicket)
        }
        onClick={() =>
          (isPlanFree || isPlanStart) && setIsOpenFeatureNotAvailableModal(true)
        }
        icon={faChartLine}
        color="#9f8cff"
        averageTicket={
          isPlanFree || isPlanStart
            ? 'Valores não calculados para esse plano'
            : 'Os valores são atualizados diariamente uma única vez'
        }
        isAvailable={isResourceAvailable(
          'Ticket Médio - Venda',
          permissionsDashboard
        )}
      />
      <DashboardCard
        title="Ticket Médio - Ordem de Serviço"
        style={{ marginRight: '26px' }}
        value={
          isPlanFree || isPlanStart
            ? currency('0')
            : currency(company?.serviceOrdersAverageTicket)
        }
        onClick={() =>
          (isPlanFree || isPlanStart) && setIsOpenFeatureNotAvailableModal(true)
        }
        icon={faChartLine}
        color="#7f66ff"
        averageTicket={
          isPlanFree || isPlanStart
            ? 'Valores não calculados para esse plano'
            : 'Os valores são atualizados diariamente uma única vez'
        }
        isAvailable={isResourceAvailable(
          'Ticket Médio - Ordem de Serviço',
          permissionsDashboard
        )}
      />
      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
    </div>
  );
};

export default Dashboard;

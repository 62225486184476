import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../client/components/CustomButton/CustomButton.jsx';
import { currency } from '../ToNormalize/ToNormalize.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

export const GenericBlockedModuleModal = ({
  show,
  module,
  subTitle,
  message,
  onHide,
  onSubmit,
}) => {
  function handleSubmit() {
    const WHATSAPP = `https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20%20sou%20cliente%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20%20m%C3%B3dulo%20de%20${module.description.replace(
      / /g,
      '%20'
    )}`;

    window.open(WHATSAPP, '_blank');
    onHide();
  }

  return (
    <Modal show={show} animation onHide={onHide} dialogClassName="modal-40w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Módulo não disponível</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'left' }}>
          <strong
            style={{
              fontSize: '14px',
            }}
          >
            {subTitle
              ? subTitle
              : `O módulo de ${module.description} não está habilitado.`}
          </strong>
          <p
            style={{
              fontSize: '14px',
              marginTop: '20px',
            }}
          >
            {message
              ? message
              : `Para utilizar o módulo de ${
                  module.description
                } é necessário contratar o módulo. Com isso, será adicionado o valor de ${currency(
                  module.price
                )} na sua mensalidade.`}
          </p>
          <p
            style={{
              fontSize: '14px',
            }}
          >
            Para contratar ou em caso de dúvidas, clique abaixo e fale com seu
            gerente de sucesso.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="upgrade-modal-footer">
          <Button bsStyle="danger" name="Voltar" onClick={onHide} fill>
            Voltar
          </Button>
          <Button
            bsStyle="info"
            className="btn btn-sucesso"
            fill
            onClick={handleSubmit}
          >
            <FontAwesomeIcon
              icon={faHeadset}
              style={{ marginRight: '5px' }}
              size="1x"
            />
            <span>Solicitar módulo</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

import api from '../services/api'

export const index = async (query) => {
  const { data } = await api.get('customer', { params: query })
  return data
}

export const indexCustomersWithVehicles = async (query) => {
  const { data } = await api.get('customer/vehicle', { params: query })
  return data
}

export const updatePhone = async (customerId, cellphone) => {
  const { data } = await api.post('customer/cellphone', {
    customerId,
    cellphone,
  });
  return data;
};

const CustomerRepository = {
  index,
  indexCustomersWithVehicles,
  updatePhone,
};

export default CustomerRepository

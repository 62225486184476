import React from 'react';

import { Collapse } from 'react-bootstrap';

import './index.css';

export default function CollapsibleSection({
  title,
  children,
  isExpanded,
  handleExpand,
}) {
  return (
    <section className="collapsible-section-container">
      <div
        className="collapsible-section-header bg-primary bg-primary-border-color"
        onClick={handleExpand}
      >
        <span
          className={`fa fa-angle-${isExpanded ? 'down' : 'right'}`}
          aria-hidden="true"
        />
        <span>{title}</span>
      </div>
      <Collapse in={isExpanded}>
        <div className="collapsible-section-content">{children}</div>
      </Collapse>
    </section>
  );
}

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModulesContext } from 'contexts/modules';

import { SwitchRow } from 'v2/components/form';

export function SubFeatureContent({
  currentGroupFeatureIndex,
  currentMainFeatureIndex,
  isMainFeatureVisible,
}) {
  const { watch, setValue } = useFormContext();

  const { groupFeatures } = watch();

  const currentSubfeatures =
    groupFeatures[currentGroupFeatureIndex].mainFeatures[
      currentMainFeatureIndex
    ]?.subFeatures.sort((a, b) => a.lineNumber - b.lineNumber) || [];

  const hasAnyActiveSubfeature = currentSubfeatures.some(
    (subFeature) => subFeature.status
  );

  const {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  } = useModulesContext();

  const modules = {
    isAnyFiscalModuleActive: [
      'CFOP',
      'NCM/NBS',
      'Texto para Notas Fiscais',
      'Transportadora',
      'Tributo por Estado',
      'CNAE',
      'Intermediador',
      'Fiscal',
      'Notas Fiscais',
    ],
    isNFeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFCeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFSeModuleActive: ['Nota Fiscal de Serviço (NFS-e)'],
    isWorkshopPanelModuleActive: ['Painel da Oficina'],
  };

  const activeModules = {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  };

  function hasActiveFeatureModule(feature) {
    for (let module in modules) {
      if (modules[module].includes(feature.description)) {
        return feature.isAllowedToUse && activeModules[module];
      }
    }
    return feature.isAllowedToUse;
  }

  function handleSubFeatureSwitchToggle(subFeature, subFeatureIndex) {
    const newStatus = hasActiveFeatureModule(subFeature) && !subFeature.status;

    setValue(
      `groupFeatures[${currentGroupFeatureIndex}].mainFeatures[${currentMainFeatureIndex}].subFeatures[${subFeatureIndex}].status`,
      newStatus
    );

    changeIsMasterStatus();

    if (newStatus === true) {
      return changeMainFeatureStatus(true);
    }
  }

  function changeIsMasterStatus() {
    const newStatus = currentSubfeatures.some(
      (subFeature) => !subFeature.status
    );

    setValue(`isMaster`, !newStatus);
  }

  function changeMainFeatureStatus(newStatus) {
    setValue(
      `groupFeatures[${currentGroupFeatureIndex}].mainFeatures[${currentMainFeatureIndex}].status`,
      newStatus
    );
  }

  function getItemBorderRadius(isFirstItemVisible, isLastItem, isOnlyItem) {
    if (isOnlyItem) return 'full';
    if (isFirstItemVisible) return 'top';
    if (isLastItem) return 'bottom';

    return 'none';
  }

  function getItemBorderTop(isFirstItemVisible, isOnlyItem) {
    if (!isOnlyItem && !isFirstItemVisible) return 'none';

    return 'normal';
  }

  useEffect(() => {
    if (!hasAnyActiveSubfeature) {
      changeMainFeatureStatus(false);
    }
  }, [hasAnyActiveSubfeature]);

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        {currentSubfeatures.map((subFeature, index) => {
          const isFirstItemVisible = !isMainFeatureVisible && index === 0;
          const isLastItem = index === currentSubfeatures.length - 1;
          const isOnlyItem =
            !isMainFeatureVisible && currentSubfeatures.length === 1;

          return (
            <SwitchRow
              key={subFeature.id}
              width={'100%'}
              title={`Acesso a ${subFeature.description}`}
              checked={hasActiveFeatureModule(subFeature) && subFeature.status}
              onChange={() => handleSubFeatureSwitchToggle(subFeature, index)}
              boldTitle={!isMainFeatureVisible}
              borderTop={getItemBorderTop(isFirstItemVisible, isOnlyItem)}
              radius={getItemBorderRadius(
                isFirstItemVisible,
                isLastItem,
                isOnlyItem
              )}
              disabledToggle={!hasActiveFeatureModule(subFeature)}
            />
          );
        })}
      </div>
    </div>
  );
}

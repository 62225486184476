import React, { useEffect, useState } from 'react';
import { Field, change } from 'redux-form';
import { Col, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import Input from 'client/components/Input/Input.jsx';
import { email } from 'client/components/ToNormalize/ToNormalize';
import Button from '../../../components/CustomButton/CustomButton';
import renderFieldSpan from 'client/components/Input/renderInputSpan.jsx';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import emailsRepository from '../../../../repositories/Emails';

import employeesRepository from '../../../../repositories/Employees';
import profilesRepository from '../../../../repositories/Profiles';
import { useAuth } from 'contexts/auth';
import { usePlanSignatureContext } from '../../../../contexts/plan-signature';
import renderField from '../../../../components/RenderField';

import FormProfile from '../../Profiles/NewProfile/FormProfile';
import FormEmployee from '../../Employees/NewEmployee/FormEmployee';
import UnavailableFeatureModal from '../../../components/UnavailableFeatureModal';
import { useThemeContext } from 'v2/contexts/themeContext';

const DadosBasicos = ({ userId }) => {
  const [isSendingRestorePasswordEmail, setIsSendingRestorePasswordEmail] =
    useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);

  const { companyId, user } = useAuth();
  const { isPlanBasic, isPlanFree, isPlanStart } = usePlanSignatureContext();
  const { brandingId } = useThemeContext();

  const dispatch = useDispatch();
  const { IsActive, Email, Name } = useSelector(
    (state) => state.form.formUser.values
  );

  useEffect(() => {
    if (companyId) {
      loadProfiles();
      loadEmployees();
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId === null) {
      loadProfiles();
    }
  }, [user]);

  const resetPassword = async () => {
    setIsSendingRestorePasswordEmail(true);

    try {
      await emailsRepository.restorePassword({
        Email,
        User_id: userId,
        Name,
        Location: window.location.origin,
        brandingId: brandingId,
      });
      toastr.success('Solicitação de senha enviada com sucesso');
    } catch (err) {
      toastr.error('Erro', 'Ocorreu algum erro ao enviar E-mail');
    } finally {
      setIsSendingRestorePasswordEmail(false);
    }
  };

  const handleToggle = () => {
    dispatch(change('formUser', 'IsActive', !IsActive));
  };

  const loadEmployees = async () => {
    try {
      const { data } = await employeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      });
      setEmployees(data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os funcionários. Por favor, tente novamente'
      );
    }
  };

  const loadProfiles = async () => {
    try {
      const { data } = await profilesRepository.getAllByCompany(companyId, {
        isActive: 1,
      });
      setProfiles(data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os perfis. Por favor, tente novamente'
      );
    }
  };

  const registerEmployeeSuccess = (employeeId) => {
    setIsEmployeeModalOpen(false);
    dispatch(change('formUser', 'employeeId', employeeId));
    loadEmployees();
  };
  const registerProfileSuccess = (profileId) => {
    setIsProfileModalOpen(false);
    dispatch(change('formUser', 'profileId', profileId));
    loadProfiles();
  };

  const handleOpenAddEmployeeModal = () => {
    if (isPlanFree || isPlanStart)
      return setIsOpenFeatureNotAvailableModal(true);
    setIsEmployeeModalOpen(true);
  };

  const handleOpenAddProfileModal = () => {
    if (isPlanBasic || isPlanFree || isPlanStart)
      return setIsOpenFeatureNotAvailableModal(true);
    setIsProfileModalOpen(true);
  };

  return (
    <>
      <div>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Col lg={4} md={4} sm={4} xs={12}>
            <label>
              Nome<span style={{ color: 'red' }}>*</span>
            </label>
            <Field
              name="Name"
              placeholder="Nome"
              component={Input}
              type="text"
            />
          </Col>
          <Col lg={3} md={4} sm={4} xs={12}>
            <label>
              Email<span style={{ color: 'red' }}>*</span>
            </label>
            <Field
              disabled={!!userId}
              name="Email"
              placeholder="E-mail"
              component={Input}
              normalize={(value) => email(value.toLowerCase())}
              type="text"
            />
          </Col>
          {!!!userId && (
            <>
              <Col lg={2} md={4} sm={4} xs={12}>
                <label>
                  Senha<span style={{ color: 'red' }}>*</span>
                </label>
                <Field name="Password" component={renderFieldSpan} />
              </Col>
              <Col lg={2} md={4} sm={4} xs={12}>
                <label>
                  Confirmar Senha<span style={{ color: 'red' }}>*</span>
                </label>
                <Field name="Password2" component={renderFieldSpan} />
              </Col>
            </>
          )}
          <Col
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ paddingLeft: '10px', maxWidth: '100px' }}
          >
            <label>Status</label>

            <div style={{ paddingLeft: '10px' }}>
              <Toggle checked={IsActive} onChange={handleToggle} />
            </div>
          </Col>
          {!!userId && (
            <>
              <Col lg={2} md={4} sm={4} xs={12} style={{ maxWidth: '180px' }}>
                <label>
                  Telefone<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="Phone"
                  component={Input}
                  normalize={phoneMask}
                  type="text"
                />
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <Button
                  type="button"
                  bsStyle="info"
                  disabled={isSendingRestorePasswordEmail}
                  onClick={resetPassword}
                  fill
                  style={{ marginTop: 38, width: '132px', height: '38px' }}
                >
                  <span
                    className={
                      isSendingRestorePasswordEmail
                        ? 'fa fa-spinner fa-pulse fa-1x'
                        : ''
                    }
                    style={{ marginLeft: '-5px' }}
                  ></span>
                  Redefinir Senha
                </Button>
              </Col>
            </>
          )}
        </Col>

        <Col xs={12} sm={12} md={12} lg={12}>
          {!!!userId && (
            <Col lg={2} md={4} sm={4} xs={12} style={{ maxWidth: '180px' }}>
              <label>
                Telefone<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="Phone"
                component={Input}
                normalize={phoneMask}
                type="text"
              />
            </Col>
          )}
          <Col xs={12} sm={6} md={3} lg={3}>
            <div className="div-a-modal">
              <a onClick={handleOpenAddEmployeeModal}>Adicionar Funcionário</a>
            </div>
            <Field
              label="Funcionário:"
              name="employeeId"
              component={renderField}
              as="select"
            >
              <option name="" value="">
                Selecione
              </option>
              {employees.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Field>
          </Col>
          <Col xs={12} sm={6} md={3} lg={3}>
            <div className="div-a-modal">
              <a onClick={handleOpenAddProfileModal}>Adicionar Perfil</a>
            </div>
            <Field
              label="Perfil de Acesso:"
              name="profileId"
              component={renderField}
              required
              as="select"
            >
              <option name="" value="">
                Selecione
              </option>
              {profiles.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.description}
                </option>
              ))}
            </Field>
          </Col>
        </Col>
      </div>
      {isEmployeeModalOpen && (
        <Modal
          dialogClassName="modal-formProduto"
          show={isEmployeeModalOpen}
          onHide={() => setIsEmployeeModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Novo Funcionário</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormEmployee
              modal={true}
              handleModal={(employeeId) => registerEmployeeSuccess(employeeId)}
              hideModal={() => setIsEmployeeModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}
      {isProfileModalOpen && (
        <Modal
          dialogClassName="modal-formProduto"
          show={isProfileModalOpen}
          onHide={() => setIsProfileModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Novo Perfil</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProfile
              modal={true}
              handleModal={(profileId) => registerProfileSuccess(profileId)}
              hideModal={() => setIsProfileModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
    </>
  );
};

export default DadosBasicos;

const envConfig = {
  endpoint: 'https://api.osdig.com.br/',
  webSocketEndpoint: 'wss://api.osdig.com.br/ws/v2',
  queridocarroendpoint: 'https://api.sobrerodas.club/',
  whatsappendpoint: 'https://wa.me/55',
  mysuiteendpoint: 'http://workg.mysuite.com.br/client/chatan.php?:',
  movideskendpoint: 'https://workgroup.movidesk.com',
  sobreRodasWebEndpoint: 'https://sobrerodas.club',
};

export default envConfig;

import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';
import { milhar, onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import Select from 'react-select';
import { Field } from 'redux-form';
import { yearMask } from 'utils/masks';
import '../styles.css';

const CarMotobikeTruckForm = ({
  loadingVehiclesByPlate,
  licensePlateMask,
  hasSale,
  loadVehicleByPlate,
  License_Plate,
  RenderField,
  brands,
  isVehicleByPlate,
  Type,
  Editing,
  vehiclesOptions,
  defaultModelIndex,
  selectedModel,
  modelInput,
  loadingBrandModels,
  handleModelChange,
  isViewingData,
}) => {
  return (
    <>
      <div
        style={{
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
        }}
      >
        <span>
          Placa: <span style={{ color: 'red' }}>*</span>
        </span>
        <Field
          name="License_Plate"
          required
          disabled={hasSale || isViewingData}
          component={RenderField}
          normalize={(value) => value.toUpperCase()}
          {...licensePlateMask}
          style={{ width: '100px', height: '35px' }}
        />
        {!isViewingData && (
          <button
            className="btn btn-sucesso"
            type="button"
            onClick={loadVehicleByPlate}
            disabled={License_Plate.length < 7}
            style={{
              width: '200px',
              height: '35px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span
              className={`${
                loadingVehiclesByPlate ? 'fa fa-spinner fa-pulse fa-1x' : ''
              }`}
            />
            Buscar Dados do Veículo
          </button>
        )}
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div>
          <span>
            Marca: <span style={{ color: 'red' }}>*</span>{' '}
          </span>

          <Field
            name="Brand"
            required
            as="select"
            component={RenderField}
            disabled={(isVehicleByPlate && Type == 1) || isViewingData}
          >
            <option value="">Selecione</option>
            {brands?.map((child) => (
              <option key={child.Brand} value={child.Brand}>
                {child.Brand.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <span>
            Modelo: <span style={{ color: 'red' }}>*</span>
          </span>
          {!Editing || (Editing && vehiclesOptions?.length !== 0) ? (
            <Select
              options={vehiclesOptions}
              defaultValue={vehiclesOptions[defaultModelIndex]?.value}
              value={selectedModel}
              placeholder="Selecione"
              openMenuOnFocus
              noOptionsMessage={() => 'Nenhum modelo encontrado'}
              ref={modelInput}
              isDisabled={loadingBrandModels || isViewingData}
              onChange={(model) => handleModelChange(model)}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
            />
          ) : (
            ''
          )}
        </div>

        <div>
          <span>Renavam:</span>
          <Field
            name="Vin_Renavam"
            component={RenderField}
            style={{ width: '200px' }}
            disabled={isViewingData}
            normalize={(value) => onlyNumbers(value)}
          />
        </div>

        <div>
          <span>Nº do Motor:</span>
          <Field
            disabled={isViewingData}
            name="engineNumber"
            maxLength={15}
            component={RenderField}
            style={{ width: '200px' }}
            normalize={(value) => onlyNumbersLetters(value)}
          />
        </div>
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div style={{ width: '80px' }}>
          <span htmlFor="year">
            Ano <strong>Fab.</strong>:<span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            disabled={isViewingData}
            name="Year_Manufacture"
            component={RenderField}
            {...yearMask}
          />
        </div>

        <div style={{ width: '80px' }}>
          <span htmlFor="modelYear">
            Ano <strong>Mod.</strong>:<span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            name="Year_Model"
            component={RenderField}
            {...yearMask}
            disabled={isViewingData}
          />
        </div>

        <div style={{ width: '120px' }}>
          <span>
            KM:<span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            name="Kilometers"
            required
            component={RenderField}
            normalize={(value) => milhar(onlyNumbers(value))}
            maxLength={10}
            disabled={isViewingData}
          />
        </div>

        <div>
          <span>Cor:</span>
          <Field
            name="Color"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
            disabled={isViewingData}
          />
        </div>

        <div>
          <span>Chassi/Série:</span>
          <Field
            name="NChassi"
            component={RenderField}
            normalize={(value) => onlyNumbersLetters(value.toUpperCase())}
            disabled={isViewingData}
          />
        </div>

        <div>
          <span>Combustível:</span>
          <Field
            label="Combustível"
            name="Fuel_Type"
            component="select"
            placeholder="Selecione"
            className="form-control foco-input"
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Elétrico">Elétrico</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Elétrico">
              Híbrido (Gasolina/Elétrico)
            </option>
            <option value="Outro">Outro</option>
          </Field>
        </div>
      </div>
    </>
  );
};

export default CarMotobikeTruckForm;

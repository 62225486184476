export const applicationTheme = {
  colors: {
    white: '#FFF',
    white100: '#f1f4f9',
    black: '#000',
    red: '#ff0000',

    green100: '#9DD49C',
    green200: '#7DC67B',
    green300: '#5DB85B',
    green400: '#53A551',
    green500: '#41803F',

    gray100: '#CACACA',
    gray150: '#C0C0C0',
    gray160: '#AAAAAA',
    gray180: '#E3E3E3',
    gray200: '#9E9E9E',
    gray300: '#747474',
    gray400: '#4C4C4D',
    gray500: '#282828',

    blue300: '#3c8af7',

    red400: '#D9534f',

    paleGray: '#F7F6F6',

    japaneseIndigo: '#2C3F50',
    japaneseIndigo200: '#21384d',

    cerulean: '#005580',
    midnightGreen: '#0F465A',

    labelBudget: '#363636',
    labelOS: '#00008B',
    labelSale: 'purple',
    labelPDV: '#FFA834',

    labelPhysicalPerson: 'blue',
    labelLegalPerson: 'purple',

    labelSaleStatusScheduled: '#747474',
    labelSaleStatusCancelled: '#D9534f',
    labelSaleStatusOpen: '#FFC40D',
    labelSaleStatusInProgress: '#FFC40D',
    labelSaleStatusFinished: '#53A551',
    labelSaleStatusFinancingUnderAnalysis: '#5BC0DE',
    labelSaleStatusFinancingApproved: '#428BCA',
    labelSaleStatusFinancingRejected: '#F0AD4E',
    labelSaleStatusFinancingWaitingFirstPayment: '#0D47A1',
    labelSaleStatusFinancingExpired: '#FF6900',

    buttonSaleSatisfactionSurvey: '#FFB400',
    buttonClean: '#5BC0DE',
    buttonImportClients: '#5BC0DE',
    buttonChangePlate: '#F0AD4E',
    buttonReturningCustomers: '#1565C0',
    buttonPrint: '#428BCA',

    buttonEmitNFe: '#53A551',
    buttonEmitNFCe: '#3472f7',
    buttonEmitNFSe: '#F0AD4E',

    buttonShareViaEmail: '#428BCA',
    buttonPartsRequisition: '#3472f7',
    buttonDevolutionHistory: '#FF9500',
    buttonSaleEmployees: '#5BC0DE',
    buttonSaleFinancing: '#5BC0DE',
    buttonClientHistoric: '#3472f7',
    buttonChangeCashierBank: '#428bca',
    buttonAddPhotos: '#FF9500',
    buttonReturnAllItems: '#3472f7',

    salesFooterBackground: '#E2E2E2',
    sugestionPurchaseBackground: '#5bc0de',
  },
  fonts: {
    default: 'Roboto, sans-serif',
  },
  fontSizes: {
    '4xs': '0.375rem',
    '3xs': '0.563rem',
    '2xs': '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '4xl': '2rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '4rem',
    '8xl': '4.5rem',
    '9xl': '6rem',
  },
  fontWeights: {
    regular: '400',
    medium: '500',
    semi: '600',
    bold: '700',
  },
  lineHeights: {
    shorter: '125%',
    short: '140%',
    base: '160%',
    tall: '180%',
  },
  radii: {
    px: '1px',
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '16px',
    full: '99999px',
  },
  space: {
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    16: '4rem',
    20: '5rem',
    22: '7rem',
    40: '10rem',
    64: '16rem',
    80: '20rem',
  },
  zIndex: {
    static: -1,
    normal: 0,
    tooltip: 4,
    dropdown: 20,
    menu: 100,
    overlay: 150,
    modal: 200,
  },
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash,
  faEye,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
  init,
  addItems,
  handleChange,
  calculate,
  createInstallments,
  handleSaleStatusAndType,
} from './redux/actions';
import { useAuth } from '../../../../../contexts/auth';

import Button from 'client/components/CustomButton/CustomButton.jsx';
import CardForm from 'client/components/CardForm';
import ClientData from './ClientData';
import ItemsData from './ItemsData';
import PaymentData from './PaymentData';
import SaleFooter from './SaleFooter';
import SaveModal from './SaveModal';
import StonePaymentModal from './StonePaymentModal';
import { CustomerHistorySaleButton } from './CustomerHistorySaleButton';
import { SalePhotosButton } from './SalePhotosButton';
import { SaleSearchSuiv } from './SaleSearchSuiv';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import SchedulingModal from './SchedulingModal';
import PureLoader from 'components/PureLoader';
import SalesEmployeesSelectModal from '../SalesEmployeesSelectModal';

import companyServiceTypeRepository from 'repositories/CompanyServiceType';
import productsRepository from 'repositories/Products';
import servicesRepository from 'repositories/Services';

import { companyBranch as companyBranchAtom } from 'storage/companyDetailsStorage';
import { CRMBonusModals } from 'client/components/CRMBonus';
import { useBonus } from 'hooks/useBonus';
import AppointmentsModal from '../../AppointmentsModal';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal';
import { ChecklistModal } from './components/ChecklistModal/ChecklistModal';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import { listSalesRefusedItemBySaleId } from 'v2/repositories/SalesItemsRefusedRepository';
import RefusedItemsModal from './components/RefusedItemsModal/RefusedItems';
import MaintenanceRepository from 'repositories/Maintenance';

export default function FormSale({ saleId, location }) {
  const [showClientData, setShowClientData] = useState(true);
  const [showItemsData, setShowItemsData] = useState(false);
  const [showPaymentData, setShowPaymentData] = useState(false);
  const [showMechanicalsButton, setShowMechanicalsButton] = useState(false);
  const [companyBranch] = useRecoilState(companyBranchAtom);
  const [isSelectEmployeesModalOpen, setIsSelectEmployeesModalOpen] =
    useState(false);
  const [isAppointmentsModalOpen, setIsAppointmentsModalOpen] = useState(false);
  const [
    isAppointmentsConfirmationModalOpen,
    setIsAppointmentsConfirmationModalOpen,
  ] = useState(false);
  const [
    isChecklistConfirmationModalOpen,
    setIsChecklistConfirmationModalOpen,
  ] = useState(false);

  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isRefusedItemsModalOpen, setIsRefusedItemsModalOpen] = useState(false);

  const {
    saleReducer: {
      items,
      selectedClient,
      clientVehicles,
      selectedVehicle,
      currentKilometers,
      openSchedulingModal,
      openStonePaymentProgressModal,
      loading,
      saleTypeId,
      openSaveModal,
      salesEmployeesIds,
      saleStatusId,
      generateTitleAfterCloseSale,
      salesSubtypeId,
      loadingSpin,
      prismaBoxId,
      showOnPanel,
      saleId: saleIdInternal,
    },
  } = useSelector((state) => state);

  const { saleReducer } = useSelector((state) => state);

  const selectorData = useSelector((state) => state);
  const { companyId, userId, company } = useAuth();
  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const [showChecklistButton, setShowChecklistButton] = useState(false);
  const [
    allMaintenanceServicesAreCompleted,
    setAllMaintenanceServicesAreCompleted,
  ] = useState(false);
  const [refusedItemsData, setRefusedItemsData] = useState([]);
  const dispatch = useDispatch();
  const isOsVisibilityButtonVisible =
    !!company?.companyConfig?.activateWorkshopPanel;

  const {
    crmApplyBonusTrigger,
    redeemedBonus,
    crmcontinueWithoutBonusTrigger,
    saveOrcamentTrigger,
  } = useBonus(saleReducer.selectedClientId);

  useEffect(() => {
    dispatch(init(saleId, companyId, userId, company));
    validateMechanicalsButton();

    let cotations = localStorage.getItem('COTATIONS');
    let cotationsInfo = localStorage.getItem('COTATIONSINFO');

    const updateItems = async (cotations) => {
      const serializedItems = await Promise.all(
        cotations.map(async (item) => {
          if (item.Type === 'Produto') {
            const response = await productsRepository.getById(item.id);
            return {
              ...response,
              id: item.id,
              Brand: item.Brand,
              Product_Id: item.Product_Id,
              Unit_Value: item.Unit_Value,
              Quantity: item.Quantity,
              Type: item.Type,
              Discount_Value: item.Discount_Value,
              Value_Cost: response.Purchase_Price,
              edit: item.edit,
            };
          }
          if (item.Type === 'Serviço') {
            const response = await servicesRepository.getById(item.id);
            return {
              ...response,
              id: item.id,
              Brand: item.Brand,
              Service_id: item.Service_id,
              Unit_Value: item.Unit_Value,
              Quantity: item.Quantity,
              Type: item.Type,
              Discount_Value: item.Discount_Value,
              edit: item.edit,
            };
          }
        })
      );
      dispatch(addItems(serializedItems, items));
    };

    const listRefusedItemsByCustomer = async () => {
      const { data } = await listSalesRefusedItemBySaleId(saleId);

      setRefusedItemsData(data);
    };
    if (!!saleId) {
      listRefusedItemsByCustomer();
    }
    if (!!cotations) {
      checkParams();
      localStorage.removeItem('COTATIONS');
      localStorage.removeItem('COTATIONSINFO');

      cotations = JSON.parse(cotations);
      updateItems(cotations);

      let parsedCotationsInfo;
      try {
        parsedCotationsInfo = JSON.parse(cotationsInfo);
      } catch (error) {
        parsedCotationsInfo = {};
      }

      const observations = parsedCotationsInfo?.observations || '';
      dispatch(handleChange(observations, 'observations'));
    }
    return () => {
      dispatch({ type: 'COMPONENT_UNMOUNT' });
    };
  }, []);

  useEffect(() => {
    const notEssencialOrLess = !isPlanFree && !isPlanStart && !isPlanBasic;
    const shouldShowChecklistButton =
      +companyBranch !== 1 && notEssencialOrLess;
    setShowChecklistButton(shouldShowChecklistButton);
  }, [isPlanFree, isPlanStart, isPlanBasic, companyBranch]);

  function checkParams() {
    const params = new URLSearchParams(location.search);
    const idParams = params.get('quickQuoteId');

    if (idParams) {
      readHash(idParams);
    }
  }

  async function readHash(hash) {
    const quickQuoteId = await decrypt(hash, '@OS-dig:quickQuoteId');

    dispatch(handleChange(quickQuoteId, 'quickQuoteId'));
  }

  const validateIfCompanyHasServices = async () => {
    try {
      const response = await companyServiceTypeRepository.getServicesByCompany(
        companyId
      );

      const hasServices = response.data.length > 0;
      return hasServices;
    } catch (e) {
      console.error(e);
    }
  };

  async function validateMechanicalsButton() {
    if (+companyBranch === 1) {
      return setShowMechanicalsButton(false);
    }

    if (+companyBranch === 4) {
      const companyHasServices = await validateIfCompanyHasServices();
      return companyHasServices
        ? setShowMechanicalsButton(true)
        : setShowMechanicalsButton(false);
    }

    return setShowMechanicalsButton(true);
  }

  const handleApplyBonus = () => {
    dispatch(handleChange(redeemedBonus, 'redeemedBonus'));
    dispatch(handleChange(true, 'usedCRMBonus'));

    const { items, installments } = saleReducer;
    dispatch([calculate(items), createInstallments(installments.length)]);
  };

  const handleContinueWithoutBonus = () => {
    dispatch(handleChange(true, 'continueWithoutBonus'));
  };

  useEffect(() => {
    if (crmApplyBonusTrigger && redeemedBonus > 0) {
      handleApplyBonus();
    }
  }, [crmApplyBonusTrigger, redeemedBonus]);

  useEffect(() => {
    if (crmcontinueWithoutBonusTrigger) {
      handleContinueWithoutBonus();
    }
  }, [crmcontinueWithoutBonusTrigger]);

  useEffect(() => {
    if (saleReducer.triggerOpenAppointmentsModal) {
      setIsAppointmentsModalOpen(true);
      dispatch(handleChange(false, 'triggerOpenAppointmentsModal'));
    }
  }, [saleReducer.triggerOpenAppointmentsModal]);

  useEffect(() => {
    if (saleReducer.triggerOpenChecklistModal) {
      setIsChecklistModalOpen(true);
      dispatch(handleChange(false, 'triggerOpenChecklistModal'));
    }
  }, [saleReducer.triggerOpenChecklistModal]);

  useEffect(() => {
    const loadMaintenanceByIdInfo = async () => {
      try {
        const response = await MaintenanceRepository.getMaintenanceBySaleId(
          companyId,
          saleId
        );

        if (response?.status === 'Finalizado') {
          setAllMaintenanceServicesAreCompleted(true);
          dispatch(handleChange(false, 'showOnPanel'));
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (companyId && saleId) {
      loadMaintenanceByIdInfo();
    }
  }, [saleId, companyId]);

  function handleAppointmentSaveSaleStatus() {
    if (+saleTypeId === 3 && +saleStatusId === 3) {
      return 'os-aberta';
    }

    if (saleStatusId === 5) {
      return 'os/venda';
    }

    return 'orcament';
  }

  async function handleSaveSale(
    shouldShowAppointmentModal,
    shouldShowChecklistModal
  ) {
    try {
      dispatch(handleChange(true, 'keepOnScreenAfterSave'));
      if (shouldShowAppointmentModal) {
        dispatch(handleChange(true, 'shouldShowAppointmentModal'));
      }
      if (shouldShowChecklistModal) {
        dispatch(handleChange(true, 'shouldShowChecklistModal'));
      }
      dispatch(handleChange(true, 'isAppointmentSave'));

      await dispatch(
        handleSaleStatusAndType(
          saleReducer,
          handleAppointmentSaveSaleStatus(),
          null,
          company,
          userId,
          true
        )
      );
    } catch (err) {
      console.log(err);
      dispatch(handleChange(false, 'keepOnScreenAfterSave'));
      toastr.error('Aviso', 'Erro ao salvar venda.');
    }
  }

  const handleOpenAppointmentsModal = () => {
    if (Object.keys(selectedClient).length === 0) {
      return toastr.warning('Aviso', 'Selecione um cliente.');
    }

    if (!items.length) {
      return toastr.warning(
        'Aviso',
        'Selecione um item para conseguir apontar.'
      );
    }

    if (saleId && saleStatusId === 5) {
      setIsAppointmentsModalOpen(true);
      return;
    }

    if (saleId || saleIdInternal) {
      toastr.info('Processando alterações', 'Por favor, aguarde...');
      handleSaveSale(true, false);
    } else {
      setIsAppointmentsConfirmationModalOpen(true);
    }
  };

  const handleOpenChecklistModal = () => {
    if (Object.keys(selectedClient).length === 0) {
      return toastr.warning('Aviso', 'Selecione um cliente.');
    }

    if (!items.length) {
      return toastr.warning('Aviso', 'Selecione pelo menos um item na venda.');
    }

    if (saleId && saleStatusId === 5) {
      setIsChecklistModalOpen(true);
      return;
    }

    if (saleId || saleIdInternal) {
      toastr.info('Processando alterações', 'Por favor, aguarde...');
      handleSaveSale(false, true);
    } else {
      setIsChecklistConfirmationModalOpen(true);
    }
  };

  const handlePrintChecklist = () => {
    const clientId = selectedClient.id;
    const vehicleId = selectedVehicle.id;

    let clientIdHash;
    let vehicleIdHash;
    let saleIdHash;
    let prismaBoxIdHash;

    if (clientId) {
      clientIdHash = encrypt(clientId, '@OS-dig:clientId');
    }

    if (vehicleId) {
      vehicleIdHash = encrypt(vehicleId, '@OS-dig:vehicleId');
    }

    if (saleId || saleIdInternal) {
      saleIdHash = encrypt(saleId || saleIdInternal, '@OS-dig:saleId');
    }

    if (prismaBoxId) {
      prismaBoxIdHash = encrypt(prismaBoxId, '@OS-dig:prismaBoxId');
    }

    const kilometers =
      typeof currentKilometers === 'string'
        ? currentKilometers.replace('.', '')
        : currentKilometers;

    window.open(
      window.location.origin +
        `/checklist?type=custom${
          clientIdHash ? `&clientId=${clientIdHash}` : ''
        }${vehicleIdHash ? `&vehicleId=${vehicleIdHash}` : ''}${
          saleIdHash ? `&saleId=${saleIdHash}` : ''
        }${prismaBoxId ? `&prismaBoxId=${prismaBoxIdHash}` : ''}
          ${kilometers ? `&Kilometers=${kilometers}` : ''}`,
      '_blank'
    );
  };

  const handleCheckboxChange = async (value) => {
    dispatch(handleChange(value, 'showOnPanel'));
  };

  let hasService = items?.length
    ? items.filter((item) => item.Type === 'Serviço').length > 0
      ? true
      : false
    : false;

  return (
    <>
      {loading && <PureLoader message="Carregando dados..." />}
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ marginTop: '10px' }}
      >
        <CardForm
          show={showClientData}
          title="Dados do Cliente"
          onClick={() => setShowClientData(!showClientData)}
        >
          <ClientData />
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              marginTop: '15px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {isOsVisibilityButtonVisible && (
              <button
                className={`button button-h35 ${
                  (saleStatusId === 5 && saleTypeId === 3) ||
                  allMaintenanceServicesAreCompleted
                    ? 'button-green'
                    : !showOnPanel
                    ? 'button-green'
                    : 'button-red'
                } flex center`}
                disabled={
                  !selectedVehicle?.Model ||
                  !hasService ||
                  (saleTypeId !== 3 && saleId) ||
                  !saleTypeId ||
                  (saleStatusId === 5 && saleTypeId === 3) ||
                  allMaintenanceServicesAreCompleted
                }
                onClick={() => handleCheckboxChange(!showOnPanel)}
                style={{
                  marginRight: '10px',
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={
                    (saleStatusId === 5 && saleTypeId === 3) ||
                    allMaintenanceServicesAreCompleted
                      ? faClipboardCheck
                      : !showOnPanel
                      ? faEye
                      : faEyeSlash
                  }
                />
                {(saleStatusId === 5 && saleTypeId === 3) ||
                allMaintenanceServicesAreCompleted
                  ? 'Serviço Finalizado'
                  : !showOnPanel
                  ? 'Exibir OS no Painel'
                  : 'Ocultar OS no Painel'}
              </button>
            )}

            {showChecklistButton && (
              <button
                className="button button-h35 button-cyan flex center"
                onClick={() => {
                  handleOpenChecklistModal();
                }}
                style={{
                  marginRight: '10px',
                }}
              >
                Checklist Personalizado
              </button>
            )}

            <button
              className="button button-blue button-h35 flex center"
              fill
              onClick={() => handleOpenAppointmentsModal()}
              style={{
                marginRight: '10px',
              }}
              disabled={
                saleStatusId === 2 || loadingSpin || isPlanFree || isPlanStart
              }
            >
              Apontamento
            </button>
            {showMechanicalsButton === true && (
              <Button
                className="button button-h35 flex center"
                fill
                onClick={() => setIsSelectEmployeesModalOpen(true)}
                disabled={isPlanFree || isPlanStart}
                style={{
                  marginRight: '10px',
                  backgroundColor: '#5bc0de',
                  borderColor: '#5bc0de',
                  color: '#FFFFFF',
                }}
              >
                {+companyBranch === 5
                  ? 'Técnicos Responsáveis'
                  : 'Mecânicos Responsáveis'}
              </Button>
            )}

            {selectedVehicle !== null &&
            selectedVehicle?.vehicleTypeId === 1 ? (
              <SaleSearchSuiv saleTypeId={saleTypeId} />
            ) : (
              ''
            )}
            <SalePhotosButton />
            <CustomerHistorySaleButton
              saleTypeId={saleTypeId}
              customer={selectedClient}
              vehicles={clientVehicles}
            />
            <Button
              className="button button-h35 flex center"
              id="btn-prosseguir"
              bsStyle="info"
              pullRight
              fill
              onClick={() => {
                setShowClientData(false);
                setShowItemsData(true);
                setShowPaymentData(false);
              }}
              style={{ color: '#FFFFFF' }}
            >
              Prosseguir
            </Button>
          </div>
        </CardForm>

        <CardForm
          show={showItemsData}
          title="Itens"
          onClick={() => setShowItemsData(!showItemsData)}
        >
          <ItemsData />
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{ marginTop: '15px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {saleId && refusedItemsData.length > 0 && (
                <Button
                  width="130px"
                  height="35px"
                  fontSize="14px"
                  fontWeight="regular"
                  style={{
                    border: 'none',
                    background: '#008DB1',
                    color: '#ffffff',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    setIsRefusedItemsModalOpen(true);
                  }}
                >
                  Itens Recusados ({refusedItemsData.length})
                </Button>
              )}
              <Button
                id="btn-prosseguir"
                bsStyle="info"
                pullRight
                fill
                onClick={() => {
                  setShowItemsData(false);
                  setShowClientData(false);
                  setShowPaymentData(true);
                }}
              >
                Prosseguir
              </Button>
            </div>
          </div>
        </CardForm>

        {!!generateTitleAfterCloseSale && (
          <CardForm
            show={showPaymentData}
            title="Pagamento"
            onClick={() => setShowPaymentData(!showPaymentData)}
            style={{ marginBottom: 60 }}
          >
            <PaymentData />
          </CardForm>
        )}

        <SaleFooter />

        <SaveModal isOpen={openSaveModal} />

        {openStonePaymentProgressModal && (
          <StonePaymentModal sale_id={saleId} />
        )}

        {isSelectEmployeesModalOpen && (
          <SalesEmployeesSelectModal
            open={isSelectEmployeesModalOpen}
            handleClose={() => setIsSelectEmployeesModalOpen(false)}
            selectedEmployees={salesEmployeesIds}
            disabledSubmit={
              saleStatusId === 5 ||
              saleStatusId === 2 ||
              saleStatusId === 6 ||
              saleStatusId === 9
            }
            onSubmit={(selectedEmployees) => {
              dispatch(handleChange(selectedEmployees, 'salesEmployeesIds'));
            }}
          />
        )}

        {openSchedulingModal && <SchedulingModal saleId={saleId} />}
      </div>

      <CRMBonusModals sale={saleReducer} />

      {isAppointmentsModalOpen && (
        <AppointmentsModal
          open={isAppointmentsModalOpen}
          saleId={saleReducer.saleId}
          handleClose={() => setIsAppointmentsModalOpen(false)}
          isFromSale={true}
        />
      )}

      {isAppointmentsConfirmationModalOpen && (
        <AlertModal
          title="Confirmação de apontamento"
          show={isAppointmentsConfirmationModalOpen}
          message="Para fazer o apontamento, será necessário salvar a venda. Deseja prosseguir?"
          onCancel={() => setIsAppointmentsConfirmationModalOpen(false)}
          onSubmit={() => {
            handleSaveSale(true, false);
            setIsAppointmentsConfirmationModalOpen(false);
          }}
          onHide={() => setIsAppointmentsConfirmationModalOpen(false)}
        />
      )}

      {isChecklistConfirmationModalOpen && (
        <AlertModal
          title="Confirmação de Checklist"
          show={isChecklistConfirmationModalOpen}
          message="Para fazer o checklist, será necessário salvar a venda. Deseja prosseguir?"
          onCancel={() => setIsChecklistConfirmationModalOpen(false)}
          onSubmit={() => {
            handleSaveSale(false, true);
            setIsChecklistConfirmationModalOpen(false);
          }}
          onHide={() => setIsChecklistConfirmationModalOpen(false)}
        />
      )}

      {isUpgradePlanModalOpen && (
        <UnavailableFeatureModal
          show={isUpgradePlanModalOpen}
          onHide={() => setIsUpgradePlanModalOpen(false)}
        />
      )}
      {isRefusedItemsModalOpen && (
        <RefusedItemsModal
          show={isRefusedItemsModalOpen}
          data={refusedItemsData}
          onHide={() => setIsRefusedItemsModalOpen(false)}
        />
      )}

      {isChecklistModalOpen && (
        <ChecklistModal
          open={isChecklistModalOpen}
          handleClose={() => {
            setIsChecklistModalOpen(false);
          }}
          saleId={saleReducer.saleId}
          handlePrint={handlePrintChecklist}
        />
      )}
    </>
  );
}

const PRINTING_SHEETS_DESCRIPTION = {
  1: 'Impresso 1 - A4',
  2: 'Impresso 2 - A4',
  3: 'Impresso 3 - Ticket/Bobina Térmica',
};

const PRINTING_SHEETS = {
  SHEET1: 1,
  SHEET2: 2,
  THERMAL_COIL: 3,
  SAT_THERMAL_COIL: 4,
};

const PRINTING_SHEETS_IMAGES = {
  MODEL1:
    'https://os-digital-images.s3-sa-east-1.amazonaws.com/sale-printing-sheet-model-1.png',
  MODEL2:
    'https://os-digital-images.s3-sa-east-1.amazonaws.com/sale-printing-sheet-model-2.png',
  MODEL3:
    'https://os-digital-images.s3-sa-east-1.amazonaws.com/sale-printing-sheet-model-3.png',
};

const ROUTES = {
  HOME: 'home',

  SALES: 'sales',
  SALE: 'sale',
  EDIT_SALE: 'sale#:id',

  PDV_ONLINE: 'pdv-online',
  CASHIER_BANK_HISTORIC: 'cashier-bank-historic',

  SCHEDULES: 'schedules',
  NEW_SCHEDULING: 'scheduling',
  EDIT_SCHEDULING: 'scheduling#:id',

  FAMILIES: 'families',
  FAMILY: 'family',
  EDIT_FAMILY: 'family#:id',

  WARRANTIES: 'warranties',
  WARRANTY: 'warranty',
  EDIT_WARRANTY: 'warranty#:id',

  BRANDS: 'brands',
  BRAND: 'brand',
  EDIT_BRAND: 'brand#:id',

  REVISIONS: 'revisions',
  REVIEW: 'review',
  EDIT_REVIEW: 'review#:id',

  CFOPS: 'CFOPS',
  CFOP: 'CFOP',
  EDIT_CFOP: 'CFOP#:id',

  NCM_NBS: 'NCM-NBS',

  TEXTS_FOR_INVOICES: 'texts-for-invoices',
  TEXT_FOR_INVOICE: 'text-for-invoices',
  EDIT_TEXT_FOR_INVOICE: 'text-for-invoices#:id',

  SHIPPING_COMPANIES: 'shipping-companies',
  SHIPPING_COMPANY: 'shipping-company',
  EDIT_SHIPPING_COMPANY: 'shipping-company#:id',

  TAXES_BY_STATE: 'taxes-by-state',
  TAX_BY_STATE: 'tax-by-state',
  EDIT_TAX_BY_STATE: 'tax-by-state#:id',

  CNAES: 'cnaes',
  CNAE: 'cnae',
  EDIT_CNAE: 'cnae#:id',

  INTERMEDIARIES: 'intermediaries',
  INTERMEDIARY: 'intermediary',
  EDIT_INTERMEDIARY: 'intermediary#:id',

  PAYMENT_CONDITIONS: 'payment-conditions',
  PAYMENT_CONDITION: 'payment-condition',
  EDIT_PAYMENT_CONDITION: 'payment-condition#:id',

  PAYMENT_FORMS: 'payment-forms',
  PAYMENT_FORM: 'payment-form',
  EDIT_PAYMENT_FORM: 'payment-form#:id',

  CASHIER_BANKS: 'cashier-banks',
  CASHIER_BANK: 'cashier-bank',
  EDIT_CASHIER_BANK: 'cashier-bank',

  COST_CENTERS: 'cost-centers',
  COST_CENTER: 'cost-center',
  EDIT_COST_CENTER: 'cost-center',

  CUSTOMERS: 'customers',
  CUSTOMER: 'customer',
  EDIT_CUSTOMER: 'customer#:id',

  PROVIDERS: 'providers',
  PROVIDER: 'provider',
  EDIT_PROVIDER: 'provider#:id',

  PRODUCTS: 'products',
  PRODUCT: 'product',
  EDIT_PRODUCT: 'product#:id',
  NEW_PRODUCT_SERVICE_KIT: 'product-service-kit',
  EDIT_PRODUCT_SERVICE_KIT: 'product-service-kit#:id',

  PURCHASES: 'purchases',
  PURCHASE: 'purchase',
  EDIT_PURCHASE: 'purchase#:id',

  STOCKS: 'stocks',
  STOCK: 'stock',
  EDIT_STOCK: 'stock#:id',

  ONLINE_PARTS_CONSULTATION: 'online-parts-consultation',

  BATCH_PRICE_CHANGES: 'batch-price-changes',
  BATCH_PRICE_CHANGE: 'batch-price-change',
  EDIT_BATCH_PRICE_CHANGE: 'batch-price-change#:id',

  SERVICES: 'services',
  SERVICE: 'service',
  EDIT_SERVICE: 'service#:id',

  BIRTHDAYS: 'birthdays',

  ACTUAL_STOCK_REPORT: 'actual-stock',
  BRAND_FAMILY_REPORT: 'on-brand-and-family',
  SALES_BY_FAMILY_REPORT: 'Relatorios-Vendas-PorFamilia',
  SALES_BY_PAYMENT_FORM_REPORT: 'Relatorios-Vendas-PorFormaPgto',
  SALES_BY_PRODUCT_SERVICE_REPORT: 'sales-by-product-service',
  SALES_BY_TYPE_REPORT: 'Relatorios-Vendas-PorTipo',
  SALES_COMMISSIONS_REPORT: 'Relatorios-Vendas-Comissoes',
  SALES_CANCELED_BUDGETS_REPORT: 'canceled-budgets-report',
  RECEIVEDS_BY_PAYMENT_FORM_REPORT: 'receiveds-by-form-of-payment',
  VALUES_BY_STATUS_REPORT: 'values-by-status',
  PAYMENTS_BY_STATUS_REPORT: 'payments-by-status',
  PURCHASES_BY_STATUS_REPORT: 'purchases-by-status',
  PURCHASES_BY_PROVIDER_REPORT: 'purchases-by-provider',
  APROVES_DISAPROVED_ITENS: 'purchases-aproved-disaproved-itens',
  REVENUE_PER_PERIOD_REPORT: 'revenue-per-period',

  BILLS_TO_RECEIVE: 'bills-to-receive',
  BILL_TO_RECEIVE: 'bill-to-receive',
  EDIT_BILL_TO_RECEIVE: 'bill-to-receive#:id',

  BILLS_TO_PAY: 'bills-to-pay',
  BILL_TO_PAY: 'bill-to-pay',
  EDIT_BILL_TO_PAY: 'bill-to-pay#:id',

  PROVIDER_PRODUCTS: 'provider-products#:id',

  NEW_PAYMENT: 'financial-new-payments',
  FINANCIAL_NEW_PAYMENT: 'financial-new-payment',
  // EDIT_FINANCIAL_NEW_PAYMENT: 'financial-movement#:id',

  FINANCIAL_MOVEMENTS: 'financial-movements',
  FINANCIAL_MOVEMENT: 'financial-movement',
  EDIT_FINANCIAL_MOVEMENT: 'financial-movement#:id',

  INVOICES: 'invoices',
  SERVICE_INVOICES: 'service-invoices',
  EXPORT_XML: 'export-xml',

  USERS: 'users',
  USER: 'user',
  EDIT_USER: 'user#:id',

  PLAN: 'plan',

  PAYMENT_DETAILS: 'payment-details',
  CONTACT_US: 'contact-us',
  SIGN_PLAN: 'AssinarPlano',
  NFE: 'NFe',
  EDIT_NFE: 'EditarNFe',

  NFCE: 'NFCe',
  EDIT_NFCE: 'EditarNFCe',

  NEW_SATCFE: 'SATCFe',
  EDIT_SATCFE: 'EditarSATCFe',

  NFSE: 'NFSe',
  EDIT_NFSE: 'NFSe#:id',
  COMPANY_DATA: 'MeusDados',
  COMPANIES: 'companies',
  COMPANY_DETAILS: 'MeusDetalhes',
  COMPANY_TAX_DATA: 'DadosFiscais',
  COMPANY_CONFIGURATIONS: 'configurations',

  SAT: 'SAT',
  EDIT_SAT: 'SAT#:id',

  CASHIER_FLOW_REPORT: 'cashier-flow-report',
  POSITIONS: 'positions',
  POSITION: 'position',
  PROFILES: 'profiles',
  PROFILE: 'profile',
  EMPLOYEES: 'employees',
  EMPLOYEE: 'employee',
  MIN_MAX_STOCK_REPORT: 'min-max-stock-report',
  SIMILARITY: 'similarity',
  SIMILARITIES: 'similarities',
  QUICK_QUOTES: 'quick-quotes',
  PERMISSIONS: 'permissions',
  PRODUCT_HISTORY_REPORT: 'product-history-report',
  RETURN_FORECAST: 'return-forecast',

  ACCOUNT_PLANS: 'account-plans',
  ACCOUNT_PLAN: 'account-plan',
  EDIT_ACCOUNT_PLAN: 'account-plan#:id',

  RETURN_HISTORY: 'return-history',

  STONE_TRANSACTIONS: 'stone-transactions',

  FINANCIAL_STATEMENT_REPORT: 'financial-statement-report',

  ISSUED_SLIP_REPORT: 'issued-slip-report',

  MY_FINANCIAL_AREA: 'my-financial-area',

  BOX_PRISMA: 'box-prismas',
  NEW_PRISMA_BOX: 'box-prisma',
  EDIT_PRISMA_BOX: 'box-prisma#:id',

  DISCOUNT_GROUP_PRODUCTS: 'discount-group-products',
  DISCOUNT_GROUP_SERVICES: 'discount-group-services',

  LINKED_SERVICES: 'linked-services',
  LINKED_SERVICE: 'linked-service',

  PART_APPLICATIONS: 'part-applications',
  PART_APPLICATION: 'part-application',

  RANKING_SALES_REPORT: 'ranking-sales',
  TITLE_LISTING: 'title-listing',

  INTEGRATIONS: 'integrations',
  DIGITAL_ACCOUNT: 'digital-account',

  DISCOUNT_GROUPS: 'discount-groups-tables',
  NEW_DISCOUNT_GROUPS: 'discount-group-table',
  EDIT_DISCOUNT_GROUPS: 'discount-group-table#:id',

  LOSS_REASONS: 'loss-reasons',
  NEW_LOSS_REASON: 'loss-reason',
  EDIT_LOSS_REASON: 'loss-reason#:id',

  QUOTATIONS: 'quotations',
  NEW_QUOTATION: 'quotation',
  EDIT_QUOTATION: 'quotation#:id',
  CARD_TAXES_REPORT: 'card-taxes',

  VEHICLE_HISTORY: 'vehicle-history',
  WORKSHOP_PANEL: 'workshop-panel',
  CLIENT_WORKSHOP_PANEL: 'client-workshop-panel',

  TRADE_REPRESENTATIVES: 'trade-representatives',
  NEW_TRADE_REPRESENTATIVES: 'trade-representative',
  EDIT_TRADE_REPRESENTATIVES: 'trade-representative#:id',

  ABC_CURVE: 'abc-curve',

  VOUCHER_PAYMENTS: 'voucher-payments',
  NEW_VOUCHER_PAYMENT: 'voucher-payment',
  EDIT_VOUCHER_PAYMENT: 'voucher-payment#:id',

  VOUCHER_ISSUED: 'voucher-issued',

  PRODUCTS_WITHOUT_MOVEMENT: 'products-without-movement',

  PRODUCTS_INVENTORY: 'report-inventory',

  INVOICES_REPORT: 'report-invoices',

  REPORT_RETURN_LIST: 'return-report',

  QUOTES_SUPPLIER: 'quote-supplier',

  LIST_PURCHASES_SUPPLIER: 'list-purchases-supplier',

  PRODUCTS_BY_FAMILIES_OR_BRANDS: 'product-family-brand',

  INVENTORY_MOVEMENT_LISTING: 'Inventory-movement-listing',
  PRODUCT_SERVICE_REVIEWS_WARRANTY: 'product-service-reviews',

  SALES_PER_FAMILY_PRODUCT: 'sale-family-product',

  SALES_PER_FAMILY_SERVICE: 'sale-family-service',

  PRODUCT_LISTING_LOCATION: 'product-listing-location',

  CUSTOMER_LIST: 'customer-list',

  PENDING_CUSTOMERS_SUPPLIERS: 'pending-customers-suppliers',

  LIST_OF_EMPLOYEES: 'list-employees',

  INVENTORY_COUNT: 'inventories-counts',
  NEW_INVENTORY_COUNT: 'inventory-count',
  EDIT_INVENTORY_COUNT: 'inventory-count#:id',

  PARTS_PER_APPLICATION: 'parts-per-application',

  SALE_PASSING: 'sale-passing',

  ACCOUNTS_RECEIVABLE_REPORT: 'accounts-receivable-report',

  ACCOUNTS_PAYABLE_REPORT: 'accounts-payable-report',

  TRADE_REPRESENTATIVES: 'trade-representatives',

  REPRESENTATIVE_COMISSIONS: 'representative-comissions',

  SALES_BY_ATTENDANCE: 'sales-by-attendance',

  EXTERNAL_INDICATORS: 'external-indicators',

  CUSTOMER_CREDIT: 'customer-credit',

  PARTS_REQUISITION: 'parts-requisition',
  NEW_PART_REQUISITION: 'part-requisition',
  VIEW_PART_REQUISITION: 'part-requisition#:id',

  PROMOTIONAL_PRICE_TABLE: 'promotional-price-table',
  NEW_PROMOTIONAL_PRICE_TABLE: 'new-promotional-price-table',
  EDIT_PROMOTIONAL_PRICE_TABLE: 'edit-promotional-price-table#:id',

  HANG_TANGS: 'hang-tangs',

  SALES_PER_DAY_REPORT: 'sales-per-day',
  FAMILY_BRAND_STOCK_REPORT: 'family-brand-stock',
  LIST_CANCELED_ITEMS_REPORT: 'list-canceled-items',
  CUSTOMER_APPROACH_REPORT: 'customer-approach',
  PROFIT_PANEL: 'profit-panel',
  CRM: 'crm',
  REJECTED_ITEMS_BY_CUSTOMER: 'rejected-items-by-customer',
  BANK_CONCILIATION: 'bank-conciliation',
  COST_CENTER_REPORT: 'cost-center-report',
};

const WHATSAPP_API_SEND_MESSAGE_ENDPOINT = 'https://api.whatsapp.com/send';

const CASHIER_BANK_TYPE_ID = {
  BANK: 1,
  CASHIER: 2,
};

const FEATURES = {
  CONTROLE_DE_ESTOQUE: 'Controle de Estoque',
  IMPORTACAO_DE_XML: 'Importação de XML(NF-e)',
  CONTAS_A_PAGAR_E_RECEBER: 'Contas a Pagar e Receber',
  RELATORIO_DE_ESTOQUE: 'Relatório de Estoque',
  RELATORIO_DE_VENDAS: 'Relatório de Vendas',
  RELATORIOS_FINANCEIROS: 'Relatórios Financeiros',
  RELATORIO_DE_COMPRAS: 'Relatório de Compras',
  RELATORIO_ORCAMENTOS_CANCELADOS: 'Listagem de Orçamentos Cancelados',
  NOTAS_FISCAIS_ILIMITADAS: 'Notas Fiscais ilimitadas',
  CONTROLE_CAIXA_BANCO: 'Controle de Caixa/Banco',
  PDV: 'PDV',
  CADASTRO_DE_FORNECEDORES: 'Cadastro de Fornecedores',
  COMISSAO: 'Comissão',
  RELATORIO_DE_COMISSAO: 'Relatório de Comissão',
  PRODUTOS_SIMILARES: 'Produtos Similares',
  PLANO_DE_CONTAS: 'Plano de Contas',
  CENTRO_DE_CUSTO: 'Centro de Custo',
  DRE: 'Relatório DRE',
  TAXA_DE_CARTAO: 'Taxa de Cartão',
  PRISMA_BOX: 'Prisma/Box',
  SCHEDULING: 'Agendamento',
  RANKING_DE_VENDAS: 'Ranking dos Produtos/Serviços Vendidos',
  LISTAGEM_DE_TITULOS: 'Listagem de Títulos',
  PREÇOS_DIFERENCIADOS: 'Tabela de Preços Diferenciados',
  CONSULTA_PECAS: 'Consulta de Peças Online',
  MOTIVOS_DE_PERDA: 'Motivos de Perda',
  COTACAO_PECAS: 'Cotação de Peças Online',
  RELATORIO_HISTORICO_VEICULO: 'Histórico de Veículo',
  WORKSHOP_PANEL: 'Painel da oficina',
  CURVA_ABC: 'Curva ABC de Produtos',
  PAGAMENTO_VALE: 'Pagamento de Vale',
  RELATORIO_VALES_EMITIDOS: 'Relatório de Vales Emitidos',
  PRODUTOS_SEM_MOVIMENTO: 'Relatório de Produtos Sem Movimento',
  RELATORIO_DE_INVENTARIO: 'Relatório de Inventário',
  RELATORIO_SINTETICO: 'Relatório de Comissão',
  RELATORIO_DE_DEVOLUÇÃO: 'Relatório de Comissão',
  LISTAGEM_COTACOES_FORNECEDOR: 'Listagem de Cotações por Fornecedor',
  RELATORIO_DE_LISTAGEM_DE_COMPRAS_POR_FORNECEDOR: 'Relatório de Comissão',
  RELATORIO_DE_REVISAO_DE_PRODUTOS_E_SERVICOS:
    'Relatório de Revisão de produtos e serviços',
  RELATORIO_LISTAGEM_DE_PRODUTOS_POR_LOCALIZACAO:
    'Relatório Listagem de produtos por localização',
  LISTAGEM_PRODUTOS_FAMILIAS_E_MARCAS:
    'Listagem de Produtos por Famílias/Marcas',
  RELATORIO_DE_LISTAGEM_DE_MOVIMENTAÇÃO_DE_ESTOQUE:
    'Relatorio de listagem de movimentação de estoque',
  LISTAGEM_DE_CLIENTES: 'Relatório de Listagem de Clientes',
  VENDAS_POR_FAMILIA_PRODUTO: 'Vendas por Família(Produto)',
  VENDAS_POR_FAMILIA_SERVICO: 'Vendas por Família(Serviço)',
  LISTAGEM_DE_FUNCIONARIOS: 'Relatório de Listagem de Funcionários',
  INVENTARIO_CONTAGEM: 'Inventário/Contagem',
  LISTAGEM_PENDENCIAS: 'Listagem de Pendências de Cliente/Fornecedor',
  RELATORIO_PECAS: 'Relatório Peças por Aplicação',
  CONTAS_A_RECEBER_RELATORIO: 'Relatório de Contas a Receber',
  CONTAS_A_PAGAR_RELATORIO: 'Relatório de Contas a Pagar',
  TRADE_REPRESENTATIVES: 'Meus Representantes',
  COMISSOES_REPRESENTANTES: 'Comissões de Representantes',
  RELATORIO_VENDAS_ATENDIMENTO: 'Relatório de Vendas por Atendimento',
  CREDITO_PARA_CLIENTE: 'Crédito para Cliente',
  REQUISICAO_DE_PECAS: 'Requisição de Peças',
  TABELA_DE_PRECO_PROMOCIONAL: 'Tabela de Preço Promocional',
  ETIQUETAS: 'Etiquetas',
  RELATORIO_DE_VENDAS_OS_PASSAGENS: 'Relatório de Vendas/OS - Passagens',
  ABORDAGEM_VENDA: 'Abordagem',
  SUBTIPO_DE_VENDA: 'Subtipo de Venda',
  DEPARTAMENTO: 'Departamento',
  SECAO: 'Seção',
  RELATORIO_VENDAS_POR_DIA: 'Vendas Por Dia',
  RELATORIO_ESTOQUE_POR_MARCA_FAMILIA: 'Estoque por Marca/Família',
  RELATORIO_LISTAGEM_DE_ITENS_CANCELADOS: 'Listagem de Itens Cancelados',
  RELATORIO_ABORDAGEM_DE_CLIENTES: 'Relatório de Abordagem de Clientes',
  INTERMEDIADOR: 'Intermediador',
  RELATORIO_PRODUTOS_SEM_MARCA: 'Listagem de Produtos Sem Marca e Família',
  ESTOQUE_MINIMO_MAXIMO: 'Estoque Mínimo e Máximo',
  PRODUTOS_SEM_MOVIMENTO: 'Produtos sem Movimento',
  INVENTARIO: 'Inventário',
  ITENS_RECUSADOS_PELOS_CLIENTES:
    'Itens Recusados pelos Clientes (Orçamento Digital)',
  COST_CENTER_REPORT: 'Centro de Custo',
};

const FINANCIAL_MOVEMENT_TYPES_ID = {
  TRANSFER: 2,
  MOVEMENT: 1,
};

const FINANCIAL_MOVEMENT_STATUS_ID = {
  OPEN: 1,
  CLOSED: 2,
  CANCELED: 3,
};

const FINANCIAL_MOVEMENTS_FINALITY = {
  ENTRY: 1,
  EXIT: 2,
};

const START_DATE_FOR_PLAN_RENEWAL = 15;

const ADMIN_ROLES = {
  ADMINISTRATOR: 1,
  FINANCIAL: 2,
};

const SIGNATURE_STATUS = {
  DEGUSTATION: 1,
  ACTIVE: 2,
  BLOCKED: 3,
  CANCELED: 4,
  DISABLED: 5,
  END_PLAN: 6,
  MANUALLY_DISABLED: 7,
};

const SIGNATURE_PAYMENT_TYPES = {
  CREDIT_CARD: 'Cartão de Credito',
  BANK_SLIP: 'Boleto',
};

const SIGNATURE_PERIODS = {
  MONTHLY: 'Mensal',
  YEARLY: 'Anual',
  QUARTERLY: 'Trimestral',
};

const STATUS_ID = {
  DEGUSTATION: 1,
  ACTIVE: 2,
  BLOCKED: 3,
  CANCELED: 4,
  INACTIVE: 5,
  END_PLAN: 6,
};

const SALE_TYPE_DESCRIPTION = {
  1: 'Orçamento',
  2: 'Venda',
  3: 'Ordem de Serviço',
  4: 'PDV',
};

const SALE_STATUS = {
  OPEN: 'Em Aberto(a)',
  CANCELED: 'Cancelado(a)',
  FINISHED: 'Finalizado(a)',
  SCHEDULED: 'Agendado(a)',
  IN_PROGRESS: 'Em Andamento',
};

const SALE_STATUS_ID = {
  SCHEDULED: 1,
  CANCELED: 2,
  OPEN: 3,
  IN_PROGRESS: 4,
  FINISHED: 5,
};

const SALE_STATUS_DESCRIPTION = {
  [SALE_STATUS_ID.SCHEDULED]: [SALE_STATUS.SCHEDULED],
  [SALE_STATUS_ID.OPEN]: [SALE_STATUS.OPEN],
  [SALE_STATUS_ID.CANCELED]: [SALE_STATUS.CANCELED],
  [SALE_STATUS_ID.FINISHED]: [SALE_STATUS.FINISHED],
  [SALE_STATUS_ID.IN_PROGRESS]: [SALE_STATUS.IN_PROGRESS],
};

const SALE_TYPE = {
  ORCAMENT: 'Orçamento',
  ORDER_OF_SERVICE: 'Ordem de Serviço',
  SALE: 'Venda',
  PDV: 'PDV',
};

const SALES_TYPE_DESCRIPTION = {
  1: SALE_TYPE.ORCAMENT,
  2: SALE_TYPE.SALE,
  3: SALE_TYPE.ORDER_OF_SERVICE,
  4: SALE_TYPE.PDV,
};

const SALES_TYPE = ['Orçamento', 'Ordem de Serviço', 'Venda', 'PDV'];

const SALES_TYPE_ID = {
  ORCAMENT: 1,
  SALE: 2,
  ORDER_OF_SERVICE: 3,
  PDV: 4,
};

const SEGMENTS_DESCRIPTION = {
  Motos: 'Motos',
  Carros: 'Carros',
  Pesados: 'Pesados',
  Van: 'Van',
  Bicicleta: 'Bicicleta',
  Colheitadeira: 'Colheitadeira',
  Outros: 'Outros',
  Vazio: 'Vazio',
};

const BRANCHES = {
  Autopeças: 1,
  Autocenter: 2,
  Oficina: 3,
  Loja: 4,
  Outros: 5,
};

const BILLS_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  CANCELED: 3,
};

const BILLS_STATUS_DESCRIPTION = {
  1: 'Aberto',
  2: 'Finalizado',
  3: 'Cancelado',
};

const PURCHASES_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  CANCELED: 3,
};

const PURCHASES_STATUS_DESCRIPTION = {
  1: 'Aberto',
  2: 'Finalizado',
  3: 'Cancelado',
};

const PLANS_ID = {
  ESSENCIAL: 1,
  PRIME: 2,
  START: 3,
  FREE: 4,
  WORKMOTOR_FREE: 5,
  WORKMOTOR: 6,
  WORKMOTOR_FISCAL: 7,
  ESSENCIAL_FISCAL: 8,
  PRIME_FISCAL: 9,
  TECNOMOTOR_ESSENCIAL: 10,
  TECNOMOTOR_PRIME: 11,
  TECNOMOTOR_START: 12,
  TECNOMOTOR_FREE: 13,
  TECNOMOTOR_ENTERPRISE: 14,
  BARROS_ESSENCIAL: 15,
  BARROS_PRIME: 16,
  BARROS_START: 17,
  BARROS_FREE: 18,
  BARROS_ENTERPRISE: 19,
  PO360_ESSENCIAL: 20,
  PO360_PRIME: 21,
  PO360_START: 22,
  PO360_FREE: 23,
  PO360_ENTERPRISE: 24,

  TECNOMOTOR_ESSENCIAL_FISCAL: 26,
  TECNOMOTOR_PRIME_FISCAL: 27,
  TECNOMOTOR_ENTERPRISE_FISCAL: 25,

  BARROS_ESSENCIAL_FISCAL: 29,
  BARROS_PRIME_FISCAL: 30,
  BARROS_ENTERPRISE_FISCAL: 28,

  PO360_ESSENCIAL_FISCAL: 32,
  PO360_PRIME_FISCAL: 33,
  PO360_ENTERPRISE_FISCAL: 31,
};

export const FREE_PLANS_ID = [
  PLANS_ID.FREE,
  PLANS_ID.TECNOMOTOR_FREE,
  PLANS_ID.BARROS_FREE,
  PLANS_ID.PO360_FREE,
  PLANS_ID.WORKMOTOR_FREE,
];

export const START_PLANS_ID = [
  PLANS_ID.START,
  PLANS_ID.TECNOMOTOR_START,
  PLANS_ID.BARROS_START,
  PLANS_ID.PO360_START,
];

export const ESSENCIAL_PLANS_ID = [
  PLANS_ID.ESSENCIAL,
  PLANS_ID.ESSENCIAL_FISCAL,
  PLANS_ID.TECNOMOTOR_ESSENCIAL,
  PLANS_ID.BARROS_ESSENCIAL,
  PLANS_ID.PO360_ESSENCIAL,
  PLANS_ID.TECNOMOTOR_ESSENCIAL_FISCAL,
  PLANS_ID.BARROS_ESSENCIAL_FISCAL,
  PLANS_ID.PO360_ESSENCIAL_FISCAL,
];

export const PRIME_PLANS_ID = [
  PLANS_ID.PRIME,
  PLANS_ID.PRIME_FISCAL,
  PLANS_ID.TECNOMOTOR_PRIME,
  PLANS_ID.BARROS_PRIME,
  PLANS_ID.PO360_PRIME,
  PLANS_ID.TECNOMOTOR_PRIME_FISCAL,
  PLANS_ID.BARROS_PRIME_FISCAL,
  PLANS_ID.PO360_PRIME_FISCAL,
];

export const WORKMOTOR_EQUIVALENT_PLANS_ID = [
  PLANS_ID.WORKMOTOR,
  PLANS_ID.WORKMOTOR_FISCAL,
  PLANS_ID.WORKMOTOR_FREE,
  PLANS_ID.TECNOMOTOR_ENTERPRISE,
  PLANS_ID.BARROS_ENTERPRISE,
  PLANS_ID.PO360_ENTERPRISE,
  PLANS_ID.TECNOMOTOR_ENTERPRISE_FISCAL,
  PLANS_ID.BARROS_ENTERPRISE_FISCAL,
  PLANS_ID.PO360_ENTERPRISE_FISCAL,
];

export const PRIME_ONLY_PLANS_ID = [
  PLANS_ID.PRIME,
  PLANS_ID.PRIME_FISCAL,
  PLANS_ID.TECNOMOTOR_PRIME,
];

export const PRIME_EQUIVALENT_PLANS_ID = [
  PLANS_ID.PRIME,
  PLANS_ID.PRIME_FISCAL,
  PLANS_ID.TECNOMOTOR_PRIME,
  ...WORKMOTOR_EQUIVALENT_PLANS_ID,
];

const PLANS_STATUS_DESCRIPTION = {
  1: 'Essencial',
  2: 'Prime',
  3: 'Start',
  4: 'Free',
  5: 'Workmotor Free',
  6: 'Workmotor',
  7: 'Workmotor Fiscal',
  8: 'Essencial Fiscal',
  9: 'Prime Fiscal',
  10: 'Tecnomotor Essencial',
  11: 'Tecnomotor Prime',
  12: 'Tecnomotor Start',
  13: 'Tecnomotor Free',
  14: 'Tecnomotor Enterprise',
};

const SPECIAL_TAX_REGIME_CODE = [
  {
    label: 'Microempresa Municipal',
    value: 1,
  },
  {
    label: 'Estimativa',
    value: 2,
  },
  {
    label: 'Sociedade de Profissionais',
    value: 3,
  },
  {
    label: 'Cooperativa',
    value: 4,
  },
  {
    label: '(MEI) Microempresário Indivídual',
    value: 5,
  },
  {
    label: '(ME EPP) Microempresário e Empresa de Pequeno Porte',
    value: 6,
  },
  {
    label: 'Optante pelo Simples Nacional',
    value: 7,
  },
];

const SERVICE_LOCATION_CODE = [
  {
    label: 'No Município Sem Retenção',
    value: 1,
  },
  {
    label: 'No Município Com Retenção',
    value: 2,
  },
  {
    label: 'Fora do Município Sem Retenção',
    value: 3,
  },
  {
    label: 'Fora do Município Com Retenção',
    value: 4,
  },
  {
    label: 'Fora do Município com pagamento no local',
    value: 5,
  },
];

const PRINTING_TEMPLATE_LABEL = [
  'Detalhado antigo',
  'Detalhado',
  'Bobina Térmica (Cupom)',
];

const STOCK_PERMISSIONS_ID = {
  ALLOW: 1,
  BLOCK: 2,
};

const OPTING_SN_OPTIONS = [
  {
    label: 'Sim',
    value: 1,
  },
  {
    label: 'Não',
    value: 2,
  },
  {
    label: 'Não Enviar',
    value: 0,
  },
  {
    label: 'SIMEI (Exclusivo do padrão INFISC)',
    value: 3,
  },
  {
    label: 'Simples Federal (Alíquota 1,0%)',
    value: 7,
  },
  {
    label: 'Simples Federal (Alíquota 0,5%)',
    value: 8,
  },
  {
    label: 'Simples Municipal (NFPAULISTANA)',
    value: 9,
  },
];

export default {
  STOCK_PERMISSIONS_ID,
  SALES_TYPE_ID,
  PRINTING_SHEETS_DESCRIPTION,
  PRINTING_SHEETS,
  PRINTING_SHEETS_IMAGES,
  WHATSAPP_API_SEND_MESSAGE_ENDPOINT,
  FINANCIAL_MOVEMENTS_FINALITY,
  CASHIER_BANK_TYPE_ID,
  ROUTES,
  FINANCIAL_MOVEMENT_STATUS_ID,
  FINANCIAL_MOVEMENT_TYPES_ID,
  FEATURES,
  SALE_TYPE,
  SALES_TYPE,
  SALES_TYPE_DESCRIPTION,
  SALE_STATUS,
  SALE_STATUS_ID,
  SALE_STATUS_DESCRIPTION,
  SPECIAL_TAX_REGIME_CODE,
  SERVICE_LOCATION_CODE,
  PRINTING_TEMPLATE_LABEL,
  SIGNATURE_STATUS,
  SIGNATURE_PAYMENT_TYPES,
  ADMIN_ROLES,
  START_DATE_FOR_PLAN_RENEWAL,
  SIGNATURE_PERIODS,
  STATUS_ID,
  BILLS_STATUS,
  SALE_TYPE_DESCRIPTION,
  SEGMENTS_DESCRIPTION,
  BRANCHES,
  PURCHASES_STATUS,
  BILLS_STATUS_DESCRIPTION,
  PURCHASES_STATUS_DESCRIPTION,
  PLANS_ID,
  OPTING_SN_OPTIONS,
  FREE_PLANS_ID,
  START_PLANS_ID,
  ESSENCIAL_PLANS_ID,
  PRIME_EQUIVALENT_PLANS_ID,
  PRIME_ONLY_PLANS_ID,
  WORKMOTOR_EQUIVALENT_PLANS_ID,
};

export const FORM_PAYMENT_TYPES = [
  'Dinheiro',
  'Cheque',
  'Cartão de Crédito',
  'Cartão de Débito',
  'Crédito Loja',
  'Vale Alimentação',
  'Vale Refeição',
  'Vale Presente',
  'Vale Combustível',
  'Boleto Bancário',
  'Depósito Bancário',
  'Pagamento Instantâneo (PIX)',
  'Transferência bancária, Carteira Digital',
  'Programa de fidelidade, Cashback, Crédito Virtual',
  'Sem pagamento',
];

export const PRODUCT_ORIGIN = [
  'Nacional, exceto as indicadas nos códigos 3 a 5',
  'Estrangeira - Importação direta, exceto a indicada no código 6',
  'Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
  'Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%',
  'Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/1967 , e as Leis nºs 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007',
  'Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
  'Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural',
  'Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural',
  'Nacional - Mercadoria ou bem com Conteúdo de Importação superior a 70% (setenta por cento)',
];

export const PRODUCT_PIS_COFINS_ENTRY = [
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '98',
  '99',
];

export const PRODUCT_PIS_COFINS_EXIT = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '49',
  '99',
];

export const PRODUCT_CSOSN = [
  '101',
  '102',
  '103',
  '201',
  '202',
  '203',
  '300',
  '400',
  '500',
  '900',
];

export const PRODUCT_CST = [
  '00',
  '10',
  '20',
  '30',
  '40',
  '41',
  '50',
  '51',
  '60',
  '70',
  '90',
];

export const ERROR_TAX_SETTINGS = [
  {
    item: 'Alíquota PIS',
    error: 'Campo não preenchido',
    toFix: 'Preencha a aliquota de PIS conforme o seu regime',
  },
  {
    item: 'Alíquota COFINS',
    error: 'Campo não preenchido',
    toFix: 'Preencha a aliquota de COFINS conforme o seu regime',
  },
  {
    item: 'CNPJ/CPF da Contabilidade',
    error: 'Campo não preenchido',
    toFix: 'Preencha com o CNPJ ou CPF de sua contabilidade/contador',
  },
  {
    item: 'Token IBPT',
    error: 'Campo não preenchido',
    toFix:
      'Preencha com o token do IBPT para exibir os valores de tributos ao cliente',
  },
  {
    item: 'Token IBPT',
    error: 'Token inválido',
    toFix: 'O token informado foi rejeitado pela IBPT. Valide o código',
  },
  {
    item: 'Certificado Digital',
    error: 'Certificado não importado',
    toFix: 'Importe o certificado digital para emitir a nota',
  },
  {
    item: 'Certificado Digital',
    error: 'Certificado vencido',
    toFix: 'Certificado vencido. Importe um novo certificado para emitir',
  },
  {
    item: 'Certificado Digital',
    error: 'Senha inválida',
    toFix:
      'A senha do certificado informada é inválida. Insira a senha correta',
  },
];

export const ERROR_CLIENTS = [
  // 1 erro de cliente
  // valido só para CLIENTE JURÍDICO - PJ
  {
    item: 'Indentificador de IE',
    error: 'Indentificador de IE não selecionado',
    toFix: 'Selecione um identificador de IE válido',
  },
  {
    item: 'Endereço de entrega',
    error: 'Nota com frete sem endereço de entrega preenchido',
    toFix:
      'Preencha o endereço de entrega para emissão da nota com frete ou remova o frete',
  },
  {
    item: 'Endereço não preenchido',
    error: 'Endereço do cliente não preenchido',
    toFix: 'Preencha o endereço do cliente para emissão da nota',
  },
];

export const ERROR_PRODUCTS = [
  // 11 erros de produtos
  {
    item: 'Código do Produto',
    error: 'Código de Barras (EAN) não preenchido',
    toFix: 'Preencha o código de barras para envio do nota',
  },
  {
    item: 'Código do Produto',
    error: 'Código de Barras (EAN - Tributável) não preenchido',
    toFix: 'Preencha o código de barras tributável para envio do nota',
  },
  {
    item: 'Código do Produto',
    error: 'Origem da Mercadoria não selecionada',
    toFix: 'Selecione uma origem da mercadoria no cadastro do produto.',
  },
  {
    item: 'Código do Produto',
    error: 'CSOSN não preenchido',
    toFix: 'Preencha a CSOSN do produto para envio da nota',
  },
  {
    item: 'Código do Produto',
    error: 'CST não preenchida',
    toFix: 'Preencha a CST do produto para envio da nota',
  },
  {
    item: 'Código do Produto',
    error: 'CFOP não preenchido',
    toFix: 'Preencha o CFOP do produto para envio da nota',
  },
  {
    item: 'Código do Produto',
    error: 'NCM não preenchido',
    toFix: 'Preencha NCM do produto para envio da nota',
  },
  {
    item: 'Código do Produto',
    error: 'ST PIS (Saída) não preenchido',
    toFix: 'Preencha ST PIS (Saída) do produto para envio da nota',
  },
  {
    item: 'Código do Produto',
    error: 'ST COFINS (Saída) não preenchido',
    toFix: 'Preencha ST COFINS (Saída) do produto para envio da nota',
  },
  {
    // Válido apenas quando o CFOP for 5.650, 5.655, 5.656
    item: 'Código do Produto',
    error: 'Código ANP não preenchido',
    toFix: 'Para o CFOP em questão deve preencher o código ANP para emissão',
  },
  {
    // Válido apenas quando o CFOP for 5.650, 5.655, 5.657
    item: 'Código do Produto',
    error: 'UF de Consumo do ANP não preenchido',
    toFix:
      'Para o CFOP em questão deve preencher o UF de Consumo do ANP para emissão',
  },
];

export const PURCHASE_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  CANCELED: 3,
};

export const INVOICE_STATUS_DESCRIPTION = {
  OPEN: 'Aberta',
  ISSUED: 'Emitida',
  DENIED: 'Denegada',
  CONTINGENCY: 'Contingência',
  OPEN_WITH_ERRORS: 'Aberta c/ Erro',
  CANCELED: 'Cancelada',
  DISABLED: 'Inutilizada',
  PROCESSING: 'Processando',
};

export const INVOICE_FINALITY = {
  NORMAL: 'Normal',
  DEVOLUTION: 'Devolução',
};

export const SERVICE_INVOICE_STATUS_DESCRIPTION = {
  OPEN: 'Aberta',
  OPEN_WITH_ERRORS: 'Aberta c/ Erro',
  PROCESSING: 'Processando',
  ISSUED: 'Emitida',
  CANCELED: 'Cancelada',
};

import React from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';

export function PreviewCampaignModal({ open, onClose }) {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const { watch } = useFormContext();
  const message = watch('message');
  const photo = watch('photo');
  const handleClose = () => {
    onClose();
  };

  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case 'bulleted-list':
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case 'heading-one':
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case 'heading-two':
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case 'list-item':
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case 'numbered-list':
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    if (leaf.strikethrough) {
      children = <s>{children}</s>;
    }

    return <span {...attributes}>{children}</span>;
  };

  return (
    <Modal show={open} onHide={handleClose} width={'250px'}>
      <Modal.Header>
        <Modal.Title>
          <strong>Visualizar Campanha</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {photo && (
          <img
            src={photo.preview}
            alt="preview image"
            style={{ width: '100%', objectFit: 'contain' }}
          />
        )}
        <Slate editor={editor} initialValue={message}>
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Slate>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 flex end gap-050">
          <button
            className="button button-red button-h35"
            onClick={handleClose}
          >
            Voltar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

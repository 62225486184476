import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { useAuth } from 'contexts/auth';
import { Table } from 'v2/components/Table';
import { listBanksConciliations } from 'v2/repositories/BankConciliationRepository';
import cashierBankRepository from '../../../../repositories/CashierBank';
import { format } from 'date-fns';
import './styles.css';
import Users from 'repositories/Users';
import { SelectMulti } from 'v2/components/Select/Multi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import BankConciliationModal from './Modal/BankConciliationModal';

export function BankConciliation() {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [type, setType] = useState('conciliation');
  const [banksOptions, setBanksOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState('');
  const [selectedBankOption, setSelectedBankOption] = useState([
    {
      value: '*',
      label: 'Todos',
    },
  ]);
  const [isBankConciliationModalOpen, setIsBankConciliationModalOpen] =
    useState(true);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const loadBanksConciliations = async () => {
    setLoading(true);

    try {
      const banksIds = selectedBankOption
        .map((item) => item.value)
        .filter((value) => value !== '*')
        .join(',');

      const response = await listBanksConciliations({
        companyId,
        initialDate,
        finalDate,
        type,
        bankId: banksIds,
        identifier: selectedUserOption,
        page: currentPage + 1,
        limit: pageLimit,
      });

      setRows(response.data);
      setTotalPages(Math.ceil(response.count[0].numberRows / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar as conciliações bancárias',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadBanks = async () => {
    setLoading(true);

    try {
      const response = await cashierBankRepository.getCashierBanksByCompany(
        companyId
      );

      const banks = response.filter(
        (bank) => bank.Bank !== null && bank.cashierBankTypeId !== 2
      );

      const serializedBanks = banks.map(
        (bank) => ({
          label: bank.description,
          value: bank.id,
        }),
        [banks]
      );

      serializedBanks.unshift({ value: '*', label: 'Todos' });

      setBanksOptions(serializedBanks);
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os bancos',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    setLoading(true);

    try {
      const response = await Users.getByCompanyId(companyId);

      const users = response.filter((user) => user.isActive !== 0);

      setUsersOptions(users);
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os usuários',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    loadBanks();
    loadUsers();
  }, []);

  useEffect(() => {
    if (!!validations()) {
      loadBanksConciliations();
    }
  }, [
    currentPage,
    selectedBankOption,
    type,
    initialDate,
    finalDate,
    selectedUserOption,
  ]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel>
      <BreadCrumb {...getBreadcrumbData()} />
      <div className="mt-3">
        <div className="bank-conciliation-row">
          <div>
            <button
              className="btn btn-sucesso button-h35 fontSize-12"
              onClick={() => setIsBankConciliationModalOpen(true)}
            >
              <span>+ Nova Conciliação</span>
            </button>
          </div>
          <div>
            <span className="typography__text-2">Bancos:</span>
            <SelectMulti
              value={selectedBankOption}
              options={banksOptions}
              onChange={(e) => {
                setSelectedBankOption(e);
              }}
              hasAllOption
              customAllLabel="Todos"
              customVariousLabel="Várias"
              height={'35px'}
              fontSize={'12px'}
              isSearch={true}
              borderColor={'#e3e3e3'}
            />
          </div>
          <fieldset>
            <span className="typography__text-2">Tipo de Data:</span>
            <select
              className="form-control foco-input fontSize-12"
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <option value="conciliation">Conciliação</option>
              <option value="archivePeriod">Período do Arquivo</option>
            </select>
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Data Inicial:</span>
            <input
              className="form-control foco-input fontSize-12"
              type="date"
              name="dataInicial"
              value={initialDate}
              max={finalDate}
              onChange={(e) => {
                setInitialDate(e.target.value);
              }}
            />
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Data Final:</span>
            <input
              className="form-control foco-input fontSize-12"
              type="date"
              name="dataFinal"
              value={finalDate}
              min={initialDate}
              max={format(new Date(), 'yyyy-MM-dd')}
              onChange={(e) => {
                setFinalDate(e.target.value);
              }}
            />
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Responsável:</span>
            <select
              className="form-control foco-input fontSize-12"
              onChange={(event) => setSelectedUserOption(event.target.value)}
              value={selectedUserOption}
            >
              <option value="">Todos</option>
              {usersOptions.map(({ id, name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </fieldset>
        </div>
        <div className="mt-2" style={{ width: '100%' }}>
          <Table
            columns={[
              {
                Header: 'Dt. Conciliação',
                accessor: 'createdAt',
                Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
              },
              {
                Header: 'Banco',
                accessor: 'bankInfo',
              },
              {
                Header: 'Dt. Inicial OFX',
                accessor: 'createdAt',
                Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
              },
              {
                Header: 'Dt. Final OFX',
                accessor: 'updatedAt',
                Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
              },
              {
                Header: 'Tot. Transações',
                accessor: 'totalTransactions',
              },
              {
                Header: 'Tot. Conciliações',
                accessor: 'totalConciliations',
              },
              {
                Header: 'Responsável',
                accessor: 'identifier',
              },
              {
                Header: 'Ações',
                accessor: 'id',
                width: 90,
                Cell: (props) => (
                  <FontAwesomeIcon
                    title="Visualizar"
                    cursor="pointer"
                    style={{ height: '1.5em', width: '1.5em', color: 'black' }}
                    icon={faEye}
                    // onClick={() => handleOpenModal('Visualizar', props.value)}
                  />
                ),
              },
            ]}
            data={rows}
            pageSize={pageLimit}
            page={currentPage}
            onPageChange={setCurrentPage}
            manual
            onPageSizeChange={setPageLimit}
            pages={totalPages}
            loading={loading}
          />
        </div>
      </div>

      {isBankConciliationModalOpen && (
        <BankConciliationModal
          show={isBankConciliationModalOpen}
          onCancel={() => setIsBankConciliationModalOpen(false)}
        />
      )}
    </DefaultContentContainer>
  );
}

import api from 'v2/services/api';

export const whatsappAuthenticate = async (session) => {
  const res = await api.post('/whatsapp/generate-token', {
    session,
  });
  return res.data;
};

export const whatsappSendMessage = async (session, phone, message) => {
  const res = await api.post('whatsapp/send-message', {
    session,
    phone,
    message,
  });
  return res.data;
};

import React from 'react';
import { StyledButton } from './Button.styles';

export function Button({
  children,
  color = 'primary',
  height,
  width,
  align = 'center',
  justify = 'center',
  type = 'button',
  loading,
  padding,
  fontSize,
  fontWeight,
  ...props
}) {
  return (
    <StyledButton
      width={width}
      height={height}
      color={color}
      align={align}
      justify={justify}
      type={type}
      disabled={props.disabled || loading}
      padding={padding}
      fontSize={fontSize}
      fontWeight={fontWeight}
      {...props}
    >
      {loading && (
        <>
          <span className="fa fa-spinner fa-pulse" />
          &nbsp;&nbsp;
        </>
      )}
      {children}
    </StyledButton>
  );
}

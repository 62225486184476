import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { format, isValid } from 'date-fns';
import PDFButton from 'client/components/PDFButton';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import ListRefusedItemsReportRepository from 'v2/repositories/ItemsRefusedRepository';
import customersRepository from 'repositories/Customers';
import SelectCustomers from 'v2/client/components/SelectCustomers';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import SelectSales from 'v2/client/components/SelectSalesRefusedItems';

export default function ListRefusedItemsMain() {
  const { companyId } = useAuth();

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeOfDate, setTypeOfDate] = useState('itemRefuse');
  const [searchBy, setSearchBy] = useState('customer');
  const [loading, setLoading] = useState(false);
  const [isReportReady, setIsReportReady] = useState(false);
  const [saleStatus, setSaleStatus] = useState('all');
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (initialDate && finalDate) {
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [initialDate, finalDate]);

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate)) ||
      !typeOfDate
    ) {
      toastr.warning(
        'Data inválida.',
        'Insira um período válido e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setIsReportReady(false);
    try {
      const data = await ListRefusedItemsReportRepository.getItemsRefusedList({
        companyId,
        initialDate,
        finalDate,
        saleStatus,
        typeOfDate,
        searchBy,
        customerId,
      });

      if (data?.report.length) {
        handlePrint();
      } else {
        return toastr.warning('Sem informações', 'Nenhum dado a ser exibido.');
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a busca. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsReportReady(true);
    }
  };

  const loadCustomers = async () => {
    try {
      const customers = await customersRepository.getAllActiveByCompany(
        companyId
      );

      const formattedCustomers = customers.map((c) => ({
        value: c.id,
        label: `${cpfOrCnpjMask(c.Cpf_Cnpj)} - ${
          c.Company_Name || c.Trading_Name
        }`,
      }));

      // setFormattedOptionsCustomer(formattedCustomers);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar os clientes ',
        'Por favor, tente novamente.'
      );
    }
  };

  useEffect(() => {
    if (searchBy === 'customer') {
      loadCustomers();
    }
  }, [searchBy]);

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/list-refused-items?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&typeOfDate=${typeOfDate}&saleStatus=${saleStatus}&searchBy=${searchBy}`,
      '_blank'
    );
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Pesquisar Por:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
            >
              <option value="customer">Cliente</option>
              <option value="sales">Venda/OS</option>
            </select>
          </InputContainer>
        </div>
        <div
          style={{ justifyContent: 'flex-end' }}
          className="sales-passing__filters-row-item"
        >
          {searchBy === 'customer' ? (
            <SelectCustomers
              onChange={(item) => {
                setCustomer(item);
                setCustomerId(item.value.customer_id);
              }}
              value={customer}
              showDefaultLabel={false}
              showVehicles={false}
              placeholder="Pesquisa por CPF/CNPJ ou Nome, Razão Social ou Nome Fantasia"
              status="ATIVO"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '55rem',
                  height: '35px',
                  minHeight: '35px',
                  marginTop: '1.5px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
              }}
            />
          ) : (
            <SelectSales
              placeholder="Pesquisa por Nome do Cliente, Placa/Obj. Manut ou Número da Venda/OS"
              initialDate={initialDate}
              finalDate={finalDate}
              saleStatus={saleStatus}
              onChange={(item) => {
                console.log(item.value);
                setCustomer(item);
                setCustomerId(item.value.customer_id);
              }}
              value={customer}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '55rem',
                  height: '35px',
                  minHeight: '35px',
                  marginTop: '1.5px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
              }}
            />
          )}
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Data:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={typeOfDate}
              onChange={(e) => setTypeOfDate(e.target.value)}
            >
              <option value="itemRefuse">Recusa Do Item</option>
              <option value="created">Criação</option>
            </select>
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
      </div>

      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Status de Venda:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={saleStatus}
              onChange={(e) => setSaleStatus(e.target.value)}
            >
              <option value="all">Todos</option>
              <option value="open">Aberto</option>
              <option value="finalized">Finalizado</option>
              <option value="canceled">Cancelado</option>
            </select>
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <PDFButton
            isLoading={loading}
            disabled={!isReportReady}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
